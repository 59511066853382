import { ErrorBoundary, ErrorBoundaryProps } from '@sentry/react';
import dayjs from 'dayjs';
import { useState } from 'react';
import { toast } from 'sonner';

import { useSequences } from '@/pages/sequences/context/SequenceContext';
import {
  bulkActionAddContactToSequence,
  CampaignSourceTuple,
  SequenceSourceTuple,
} from '@/shared/api/sequences';
import {
  convertTimeString,
  getHours,
} from '@/shared/components/timepicker/utils/getHours';
import { ScheduleOptions } from '@/shared/types/campaigns';
import { Location } from '@/shared/types/locations';
import {
  Sequence,
  SequenceBulkActionFilter,
  SequenceStatus,
} from '@/shared/types/sequences';
import {
  Box,
  Button,
  Dialog,
  DialogClose,
  DialogFooter,
  DialogOverlay,
  DialogPortal,
  DialogTrigger,
  Flex,
  HStack,
  Label,
  VStack,
} from '@/shared/ui';

import {
  BulkActionDialogDescription,
  BulkActionDialogTitle,
} from '../../filters/bulkAction';
import { BulkActionDialogContent } from '../../filters/bulkAction/Dialog';
import {
  RadioGroupOptions,
  ScheduleOptionsRadioGroup,
} from '../../forms/scheduleOptionsRadioGroup/ScheduleOptionsRadioGroup';
import { SingleSelect } from '../../SingleSelect';

type Props = {
  defaultLocation?: Location;
  locations: Location[];
  sequence?: Sequence;
  showTimeZonePicker: boolean;
  /* the boolean value controlling whether or not the dialog is open */
  isOpen: boolean;
  /* the function to control whether or not the dialog is open */
  setIsOpen: (isOpen: boolean) => void;
  /*
   * Additionally us to clarify which type of filter this is. If we want this filter to apply to a campaign_contact_selection
   * we should specify the source campaign id. Likewise, if we want the filter to correspond to step_contact_selection, then
   * we should provide the sequence source tuple.
   * */
  filterSource?: CampaignSourceTuple | SequenceSourceTuple;
  filter: SequenceBulkActionFilter;
  /*
   * The total number of contacts that will be messaged. This could be determined by
   * either the bulk actions sending us this information.
   */
  totalContacts: number;
  /*
    Once the user submits the message we append the input they gave in the location and schedule options
    A selectedOption of null means that it is an immediate message
  */
};

type AddToSequenceFormProps = {
  defaultLocation?: Location;
  locations: Location[];
  sequence?: Sequence;
  showTimeZonePicker: boolean;
  filterSource?: CampaignSourceTuple | SequenceSourceTuple;
  filter: SequenceBulkActionFilter;
  totalContacts: number;
  setIsOpen: (isOpen: boolean) => void;
};
export const AddToSequenceDialog = ({
  isOpen,
  setIsOpen,
  sequence,
  defaultLocation,
  filter,
  filterSource,
  locations,
  totalContacts,
  showTimeZonePicker,
}: Props): JSX.Element => {
  const resetModalState = (): void => {
    setIsOpen(false);
  };

  return (
    <Dialog open={isOpen} modal={false}>
      <DialogTrigger asChild onClick={resetModalState}></DialogTrigger>
      <DialogPortal>
        <DialogOverlay as="div">
          <BulkActionDialogContent
            onPointerDownOutside={resetModalState}
            onEscapeKeyDown={resetModalState}
          >
            <FormErrorBoundary setIsOpen={setIsOpen}>
              <BulkActionDialogTitle>Add to Sequence</BulkActionDialogTitle>
              <BulkActionDialogDescription css={{ marginBottom: '16px' }}>
                Select the sequence you want to add these contacts to.
              </BulkActionDialogDescription>
              <AddToSequenceForm
                sequence={sequence}
                defaultLocation={defaultLocation}
                filter={filter}
                filterSource={filterSource}
                locations={locations}
                totalContacts={totalContacts}
                showTimeZonePicker={showTimeZonePicker}
                setIsOpen={setIsOpen}
              />
            </FormErrorBoundary>
          </BulkActionDialogContent>
        </DialogOverlay>
      </DialogPortal>
    </Dialog>
  );
};

const AddToSequenceForm = ({
  sequence,
  defaultLocation,
  filter,
  filterSource,
  locations,
  totalContacts,
  setIsOpen,
}: AddToSequenceFormProps) => {
  const [sequenceType, setSequenceType] = useState<RadioGroupOptions>('default');
  const [selectedSequenceId, setSelectedSequenceId] = useState<string>('');
  const [selectedLocation, setSelectedLocation] = useState<Location | null>(null);
  const sequenceContext = useSequences();
  const { sequencesState } = sequenceContext;
  const { allSequences } = sequencesState;

  const [scheduleTime, setScheduleTime] = useState<ScheduleOptions>(
    initializeScheduleTime(defaultLocation, new Date())
  );

  const handleChangeLocation = (selectedLocation: Location | null) => {
    setSelectedLocation(selectedLocation);
  };

  function handleConfirmAddToSequence(
    sequenceId: string,
    location: Location,
    filter: SequenceBulkActionFilter,
    scheduleOptions: ScheduleOptions | null,
    filterSource?: CampaignSourceTuple | SequenceSourceTuple
  ) {
    toast.info('Adding contacts to sequence...');
    bulkActionAddContactToSequence(
      sequenceId,
      location.id,
      filter,
      scheduleOptions,
      filterSource
    )
      // eslint-disable-next-line
      .then((_data) => {
        toast.success('Adding contacts to sequence in progress');
      })
      // eslint-disable-next-line
      .catch((_e) => toast.error('Failed to add contacts to sequence'));
    setIsOpen(false);
  }

  return (
    <form
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      onSubmit={(_e) => {
        // send null if the sequence type is not scheduled
        const scheduleOptions = sequenceType == 'default' ? null : scheduleTime;
        if (
          sequenceType == 'scheduled' &&
          scheduleOptions != null &&
          isScheduledTimeInPast(scheduleOptions, new Date())
        ) {
          toast.error('Cannot schedule campaigns in the past.');
          return;
        }
        if (selectedLocation == null) {
          toast.error('Did not properly select a location');
          return;
        }
        handleConfirmAddToSequence(
          sequence?.id ?? selectedSequenceId,
          selectedLocation as Location,
          filter,
          scheduleOptions,
          filterSource
        );
      }}
      data-testid="quick-add-contacts-to-sequence-form"
    >
      <VStack gap={2}>
        {!sequence && (
          <>
            <Label css={{ marginBottom: '0px' }}>Select Sequence</Label>
            <SingleSelect
              selectItem={selectedSequenceId}
              setSelectItem={setSelectedSequenceId}
              options={getSequenceOptions(allSequences as Sequence[])}
              defaultPlaceholder={
                getSequenceById(allSequences, selectedSequenceId)?.title ||
                'Select Sequence'
              }
              closeOnClick={true}
              isDropdown={true}
            />
          </>
        )}
        <Label css={{ marginBottom: '0px' }}>From</Label>
        <SingleSelect
          selectItem={selectedLocation?.id ?? ''}
          setSelectItem={(locationId: string) => {
            const location = locations.find((location) => location.id === locationId);
            if (location) {
              handleChangeLocation(location);
            } else {
              handleChangeLocation(null);
            }
          }}
          options={locations.map((location) => ({
            type: `${location.name ?? location.address} (${location.phone})`,
            value: location?.id,
          }))}
          defaultPlaceholder={getLocationName(selectedLocation) ?? 'Select channel'}
          closeOnClick={true}
          isDropdown={true}
        />
        <Label css={{ marginBottom: '0px' }}>Schedule:</Label>
        <ScheduleOptionsRadioGroup
          initialState={sequenceType}
          labels={['Add Immediately', 'Schedule for Later']}
          onRadioClick={(sequenceType: string) => {
            setSequenceType(sequenceType as RadioGroupOptions);
          }}
          scheduleOptions={scheduleTime}
          onDateTimeUpdated={(dateTime: ScheduleOptions) => {
            setScheduleTime(dateTime);
          }}
        />
      </VStack>
      <DialogFooter justify="between" css={{ mb: 8, mt: 8 }}>
        <HStack gap={1} css={{ width: '100%', justifyContent: 'space-between' }}>
          <Label css={{ mb: 0 }}>{`Total Contacts: ${totalContacts}`}</Label>
          <HStack>
            <DialogClose asChild>
              <Button variant="gray" css={{ mr: '$1' }} onClick={() => setIsOpen(false)}>
                Cancel
              </Button>
            </DialogClose>
            <DialogClose asChild>
              <Button
                type="submit"
                disabled={
                  !isValidSubmission(
                    sequence?.id ?? selectedSequenceId,
                    selectedLocation,
                    locations
                  )
                }
              >
                {sequenceType == 'scheduled' ? 'Schedule' : 'Confirm'}
              </Button>
            </DialogClose>
          </HStack>
        </HStack>
      </DialogFooter>
    </form>
  );
};

function initializeScheduleTime(location: Location | undefined, currentDate: Date) {
  const timezone = location?.timezone || Intl.DateTimeFormat().resolvedOptions().timeZone;
  const startDate = `${
    currentDate.getMonth() + 1
  }/${currentDate.getDate()}/${currentDate.getFullYear()}`;
  const hourIntervals = getHours(startDate, timezone);
  const dateTime = dayjs();
  const newDateTime = dateTime.add(15, 'minute');
  let time = {
    hour: newDateTime.hour(),
    minute: newDateTime.minute(),
  };
  if (hourIntervals.length > 0) {
    const firstTimeInFuture = getHours(startDate, timezone)[0];
    time = convertTimeString(firstTimeInFuture);
  }
  return {
    year: currentDate.getFullYear().toString(),
    // date is zero indexed and schedule options is not
    // so we need to modify the date before passing it as a scheduleOption
    month: (currentDate.getMonth() + 1).toString(),
    day: currentDate.getDate().toString(),
    hour: time.hour.toString(),
    minute: time.minute.toString(),
    timezone: timezone,
  };
}
function validLocation(currentLocation: Location, locations: Location[]) {
  return locations.some((location) => location.id == currentLocation.id);
}

function isScheduledTimeInPast(scheduleTime: ScheduleOptions, now: Date): boolean {
  const { year, month, day, hour, minute } = scheduleTime;
  const dateToCheck = new Date(
    parseInt(year),
    // date is zero indexed and schedule options is not
    // so we need to modify the date before passing it as a scheduleOption
    parseInt(month) - 1,
    parseInt(day),
    parseInt(hour),
    parseInt(minute)
  );

  return dayjs(dateToCheck).isBefore(dayjs(now));
}

const getSequenceById = (sequences: Sequence[] | null[], id: string) => {
  // make sure the sequence is not a null array or undefined
  if (sequences && sequences.length > 0) {
    const sequence = (sequences as Sequence[]).find((sequence) => sequence.id === id);
    return sequence;
  } else {
    return null;
  }
};

function isValidSubmission(
  selectedSequenceId: string,
  selectedLocation: Location | null,
  locations: Location[]
): boolean {
  return (
    selectedLocation != null &&
    selectedSequenceId != null &&
    selectedSequenceId != '' &&
    validLocation(selectedLocation, locations)
  );
}

function getSequenceOptions(sequences: Sequence[]): { type: string; value: string }[] {
  return sequences
    .filter((sequence: Sequence) => sequence.status === SequenceStatus.ACTIVE)
    .map((sequence: Sequence) => ({
      type: sequence?.title || '',
      value: sequence?.id || '',
    }));
}
function getLocationName(currentLocation?: Location | null): string | null | undefined {
  if (!currentLocation) return null;
  return currentLocation.name;
}

const FormErrorBoundary: React.FC<FormErrorBoundaryProps> = ({ children, setIsOpen }) => {
  if (!children) return null;

  return (
    <ErrorBoundary
      fallback={<FormErrorBoundariesFallback setIsOpen={setIsOpen} />}
      showDialog={false}
    >
      {children}
    </ErrorBoundary>
  );
};

const FormErrorBoundariesFallback = ({
  setIsOpen,
}: {
  setIsOpen: (isOpen: boolean) => void;
}) => (
  <Flex
    direction="column"
    align="center"
    justify="center"
    css={{
      width: '100%',
      height: '100%',
    }}
  >
    <Box
      css={{
        fontWeight: 'bold',
      }}
    >
      Oops something went wrong when trying to add contacts to a sequence.
    </Box>
    <Box>Please try again; if the issue persists contact support.</Box>
    <DialogFooter justify="end" css={{ mb: 8, mt: 8, width: '100%' }}>
      <DialogClose asChild>
        <Button variant="gray" css={{ mr: '$1' }} onClick={() => setIsOpen(false)}>
          Cancel
        </Button>
      </DialogClose>
    </DialogFooter>
  </Flex>
);

interface FormErrorBoundaryProps extends ErrorBoundaryProps {
  setIsOpen: (isOpen: boolean) => void;
}
