import React from 'react';

import { useLocations } from '@/pages/settings/organization/locations/context/LocationContext';
import { MultiSelect } from '@/shared/components/MultiSelect';
import { Location } from '@/shared/types/locations';
import { Box } from '@/shared/ui';

type SelectPermissionLocationProps = {
  /** set the location the campaign is scheduled */
  setPermissionLocations: (location: string[]) => void;
};

export const SelectPermissionLocations = (
  props: SelectPermissionLocationProps
): JSX.Element => {
  const { setPermissionLocations } = props;

  const locations = useLocations();
  const { locationsState } = locations;

  return (
    <Box>
      {locationsState.locations.length > 0 && (
        <MultiSelect
          defaultPlaceholder="Channels"
          defaultSelectedItems={[]}
          isDropdown={true}
          options={locationsState.locations.map((location: Location) => ({
            type: location?.name || '',
            value: location.id,
          }))}
          parentSelectedItems={(data: { locations: string[] }) =>
            setPermissionLocations(data.locations)
          }
          setParentSelectedItems={(data: { locations: string[] }) =>
            setPermissionLocations(data.locations)
          }
          isCampaigns={true}
        />
      )}
    </Box>
  );
};
