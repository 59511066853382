import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { useEffect, useState } from 'react';
import { HiMinus, HiPaperAirplane, HiPlus, HiSave } from 'react-icons/hi';
import { useHistory } from 'react-router-dom';
import { useMedia } from 'react-use';

import { useAuth } from '@/pages/auth/context/AuthProvider';
import {
  AudienceSelection,
  AudienceState,
  reduceAudienceToParams,
  reduceExcludeAudienceToParams,
} from '@/pages/campaigns/audience';
import { CampaignAutomations } from '@/pages/campaigns/automations';
import { useCampaignsContext } from '@/pages/campaigns/context/CampaignsContext';
import { Description, getIntervalValue } from '@/pages/campaigns/create';
import { Accordion, CampaignAccordion } from '@/pages/campaigns/create/CampaignAccordion';
import { CampaignBasicInfo } from '@/pages/campaigns/create/CampaignBasicInfo';
import { CampaignConfirmation } from '@/pages/campaigns/create/CampaignConfirmation';
import { CampaignMessageEditor } from '@/pages/campaigns/create/CampaignMessageEditor';
import { CampaignPreview } from '@/pages/campaigns/create/CampaignPreview';
import { CampaignSchedule } from '@/pages/campaigns/create/CampaignSchedule';
import { CampaignToolbar } from '@/pages/campaigns/create/CampaignToolbar';
import { CampaignPanel } from '@/pages/campaigns/create/panel';
import { pre_selected_audience_initial_state } from '@/pages/campaigns/quick';
import { useLocations } from '@/pages/settings/organization/locations/context/LocationContext';
import { useUserPreferences } from '@/pages/settings/user/preferences/context/PreferencesContext';
import { getAutomationTemplate } from '@/shared/api/automations/templates';
import { getAudienceContacts } from '@/shared/api/campaigns';
import { ToolTipIconButton } from '@/shared/components/attachments/previewer';
import { fillPipeFields } from '@/shared/components/editor/Pipes';
import { Attachments } from '@/shared/components/editor/v2/constants';
import { SidebarNavigationContainer } from '@/shared/components/navigation/SideNavigationContainer';
import { PageLayout } from '@/shared/layouts/PageLayout';
import { Audience } from '@/shared/types/audience';
import { AutomationTemplate } from '@/shared/types/automations';
import {
  AccordionValue,
  AudiencePreviewType,
  BatchDelayTimeUnit,
  CampaignSettings,
  CampaignStatus,
  PanelType,
  ScheduleOptions,
} from '@/shared/types/campaigns';
import {
  Box,
  Button,
  ControlGroup,
  Drawer,
  DrawerContent,
  DrawerPortal,
  Fieldset,
  Flex,
  HStack,
  IconButton,
  Input,
  Label,
  Text,
  VStack,
} from '@/shared/ui';
import { Switch, SwitchThumb } from '@/shared/ui/Switch';
import { getDefaultLocation } from '@/shared/utils/defaultLocation';

const CreateSMSCampaign = (): JSX.Element => {
  const history = useHistory();
  // campaign state
  const [currentEditor, setCurrentEditor] = useState();
  const {
    campaignsState: { current },
    setCurrentCampaign,
    setShowConfetti,
    createOneCampaign,
    updateOneCampaign,
  } = useCampaignsContext();

  // all as-is templates under this campaign
  const [existingAllAutomationTemplates, setExistingAllAutomationTemplates] = useState<
    Array<AutomationTemplate>
  >([]);

  // fetch the existing as-is templates under this campaign
  const fetchTemplates = async () => {
    const existingAssociatedAutomationTemplates =
      current?.associated_automation_templates || [];
    // extract the automation_template_id from each existingAssociatedAutomationTemplates
    const existingAllAutomationTemplateIds = existingAssociatedAutomationTemplates.map(
      (template: {
        automation_template_id: string;
        campaign_automation_template_id: string;
      }) => template.automation_template_id
    );
    const existingAllAutomationTemplatesPromises: Array<Promise<AutomationTemplate>> =
      existingAllAutomationTemplateIds.map((id: string) => getAutomationTemplate(id));
    try {
      const templates = await Promise.all(existingAllAutomationTemplatesPromises);
      setExistingAllAutomationTemplates(templates);
    } catch (error) {
      console.error('Error fetching automation templates:', error);
    }
  };

  useEffect(() => {
    fetchTemplates();
  }, [current]);

  // set accordion state
  const [accordion, setAccordion] = useState<AccordionValue>(
    AccordionValue.SELECT_LOCATION
  );

  const { getLocationById, locationsState } = useLocations();
  const { preferences } = useUserPreferences();

  // campaign title
  const [campaignTitle, setCampaignTitle] = useState(current?.title || '');

  // location to set the campaign from
  const [location, setLocation] = useState<string>('');
  const locationObject = getLocationById(location);

  useEffect(() => {
    setLocation(
      getDefaultLocation(
        current?.audience?.location,
        locationsState.locations,
        preferences?.inbox?.preferred_location_id
      )
    );
  }, [preferences?.inbox?.preferred_location_id]);

  // is this a big screen?
  const isLargeDesktop = useMedia('(min-width: 1350px)');
  const isSmallScreen = !useMedia('(min-width: 1130px)');
  const isMobile = !useMedia('(min-width: 968px)');

  // update accordion state when basic campaign details are set
  useEffect(() => {
    if (current && current?.title && current?.audience?.location) {
      setAccordion(AccordionValue.SELECT_AUDIENCE);
    }
  }, [current?.title]);

  // contacts to message audience
  const [includeAudience, setIncludeAudience] = useState<AudienceState>({
    contacts: [],
    uploads: [],
    groups: [],
    tags: [],
    manuallyAddedContacts: [],
    wholeCampaignTab: {},
  });

  // contacts to exclude audience
  const [excludeAudience, setExcludeAudience] = useState<AudienceState>({
    contacts: [],
    uploads: [],
    groups: [],
    tags: [],
    manuallyAddedContacts: [],
    wholeCampaignTab: {},
  });

  // should we exclude contacts with open conversation from the campaign?
  const [excludeContactsWithOpenConversation, setExcludeContactsWithOpenConversation] =
    useState<boolean>(
      current?.excluded_contacts?.exclude_contacts_with_open_conversations || false
    );

  // should we exclude contacts who got a campaign recently?
  const [excludeContactsWithOtherCampaigns, setExcludeContactsWithOtherCampaigns] =
    useState<boolean>(
      current &&
        current.excluded_contacts?.time_since_last_campaign &&
        current.excluded_contacts.time_since_last_campaign > 0
        ? true
        : false
    );

  // how many days if excludeContactsWithOtherCampaigns is true
  const [excludeCampaignSince, setExcludeCampaignSince] = useState<number>(
    current?.excluded_contacts?.time_since_last_campaign || 0
  );

  // preselected audience from saved campaign object
  const [includePreSelectAudience, setIncludePreSelectAudience] = useState<Audience>(
    current?.audience || pre_selected_audience_initial_state || {}
  );

  // preselected excluded audience from saved campaign object
  const [excludePreSelectAudience, setExcludePreSelectAudience] = useState(
    current?.excluded_contacts
      ? current?.excluded_contacts
      : pre_selected_audience_initial_state
  );

  useEffect(() => {
    // merge pre_selected_audience_initial_state with current.audience
    if (current?.audience) {
      setIncludePreSelectAudience({
        ...pre_selected_audience_initial_state,
        ...current.audience,
      });
    }
  }, []);

  useEffect(() => {
    // merge pre_selected_audience_initial_state with current.excluded_contacts
    if (current?.excluded_contacts) {
      setExcludePreSelectAudience({
        ...pre_selected_audience_initial_state,
        ...current.excluded_contacts,
      });
    }
  }, []);

  // exclude an individual contact from included audience panel
  const handleUpdateExcludePreSelectedAudience = (id: string) => {
    setExcludePreSelectAudience({
      tag_ids: [...(excludePreSelectAudience?.tag_ids || [])],
      dynamic_group_ids: [...(excludePreSelectAudience?.dynamic_group_ids || [])],
      upload_tag_ids: [...(excludePreSelectAudience?.upload_tag_ids || [])],
      contacts: [...[id], ...(excludePreSelectAudience?.contacts || [])],
      time_since_last_campaign: excludeCampaignSince,
      exclude_contacts_with_open_conversations: excludeContactsWithOpenConversation,
    });
  };

  // does the reduceAudienceToParams objects keys have a value
  // used to check if the audience is empty
  const hasIncludeAudience = () => {
    const audience = reduceAudienceToParams(
      includeAudience,
      includePreSelectAudience,
      location
    );
    return Object.keys(audience).some(
      (key) =>
        (key !== 'location' && audience[key as keyof typeof audience].length > 0) || false
    );
  };

  // the number of messages that will be sent
  const [messageCount, setMessageCount] = useState<number>(0);

  // the contacts in the audience
  const [audiencePreview, setAudiencePreview] = useState<AudiencePreviewType>({
    audience: [],
    excluded_contacts: [],
  });

  // are we loading the contacts in the audience?
  const [loadingAudience, setLoadingAudience] = useState<boolean>(false);

  // handle campaign side panel state
  const [showPanel, setShowPanel] = useState(false);
  const [panel, setPanel] = useState<PanelType>(PanelType.VIEW_AUDIENCE);

  // open or close the campaign side panel
  const handlePanelClick = (panelType: PanelType): void => {
    if (panelType === panel) {
      setShowPanel(!showPanel);
    } else {
      setPanel(panelType);
      setShowPanel(true);
    }
  };

  // if an upload is added to the audience, and its still pending processing
  // then we want to disable the audience calculation
  const [disableAudienceCalculation, setDisableAudienceCalculation] = useState(false);

  // get the list of contacts that will be included or excluded
  useEffect(() => {
    console.log('useEffect: getAudienceContacts');
    // if there is no location lets not calculate the audience
    if (location && !disableAudienceCalculation) {
      setLoadingAudience(true);

      // do this to invalidate the old contacts
      // while recalculating the new contacts
      setAudiencePreview({
        audience: [],
        excluded_contacts: [],
      });
      setMessageCount(0);

      const audience = reduceAudienceToParams(
        includeAudience,
        includePreSelectAudience,
        location
      );
      const exclude = reduceExcludeAudienceToParams(
        excludeAudience,
        excludePreSelectAudience,
        excludeCampaignSince,
        excludeContactsWithOpenConversation
      );

      // do this to invalidate the old contacts
      // while recalculating the new contacts
      setAudiencePreview({
        audience: [],
        excluded_contacts: [],
      });

      // this is probably not the best way to do this
      // but it works for now, we should probably add advanced more retry logic
      const getContacts = async () => {
        try {
          const res = await getAudienceContacts(audience, exclude);
          if (res.audience_count === 0) {
            retryGetContacts();
          } else {
            setAudiencePreview(res);
            setMessageCount(res.audience_count);
            setLoadingAudience(false);
            return res;
          }
        } catch (err) {
          console.error('campaign:getContacts', err);
          retryGetContacts();
        }
      };

      const retryGetContacts = async () => {
        try {
          const res = await getAudienceContacts(audience, exclude);
          setAudiencePreview(res);
          setMessageCount(res.audience_count);
          setLoadingAudience(false);
        } catch (err) {
          setLoadingAudience(false);
          console.error('campaign:getContacts', err);
        }
      };

      getContacts();
    }

    if (disableAudienceCalculation) {
      setLoadingAudience(false);
      setMessageCount(0);
    }
  }, [
    current?.id,
    includeAudience,
    excludeAudience,
    includePreSelectAudience,
    excludePreSelectAudience,
    location,
    excludeCampaignSince,
    excludeContactsWithOpenConversation,
  ]);

  // if the disableAudienceCalculation state changes to
  // true then set the message count to 0 so that we don't show
  // the old message count or an in correct message count
  useEffect(() => {
    if (disableAudienceCalculation) {
      setMessageCount(0);
    }
  }, [disableAudienceCalculation]);

  // message body state
  const [message, setMessage] = useState(current?.body || '');

  // message attachments state
  const [attachments, setAttachments] = useState<Attachments>({
    attachment_urls: current?.attachment_urls || [],
  });

  // schedule campaign state
  const [isScheduled, setIsScheduled] = useState(
    current?.schedule_options?.day ? true : false
  );

  // set the date from the schedule params if it exists
  // otherwise set the date to the current date
  const [date, setDate] = useState<string>(
    current?.schedule_options?.day &&
      current?.schedule_options?.month &&
      current?.schedule_options?.year
      ? `${current.schedule_options?.month}/${current?.schedule_options.day}/${current?.schedule_options.year}`
      : dayjs(new Date()).format('MM/DD/YYYY')
  );

  // set the time from the schedule params if it exists
  // if it doesn't exist, set the time to the current time
  const [time, setTime] = useState(
    current?.schedule_options?.hour && current?.schedule_options?.minute
      ? `${current?.schedule_options?.hour}:${current?.schedule_options?.minute}`
      : ''
  );

  // set the timezone from the schedule params if it exists
  // or use the default browser timezone
  const [time_zone, setTimezone] = useState(
    current?.schedule_options?.timezone
      ? current?.schedule_options?.timezone
      : Intl.DateTimeFormat().resolvedOptions().timeZone
  );

  // send campaign in batches?
  const [enableBatchSend, setEnableBatchSend] = useState(
    current?.delivery_options?.period && current?.delivery_options?.messages_per_period
      ? true
      : false
  );

  // number of messages to send per batch
  const [batchSize, setBatchSize] = useState<number | null>(
    current?.delivery_options?.messages_per_period || 100
  );

  // period time unit for batch sending
  const [batchDelayTimeUnit, setBatchDelayTimeUnit] = useState(
    BatchDelayTimeUnit.MINUTES
  );

  // number of minutes/hours/days between batches, default is minutes
  const [batchDelay, setBatchDelay] = useState<number | null>(
    current?.delivery_options?.period ? current?.delivery_options?.period / 60 : 5
  );

  // show the user the campaign settings
  const [enableCampaignSettings, setEnableCampaignSettings] = useState(false);
  // show the user the campaign link tracking settings
  const [enableLinkTrackingSettings, setEnableLinkTrackingSettings] = useState(false);

  // id any of the three current campaign settings are enabled, then show the user the campaign settings on mount
  useEffect(() => {
    if (
      current?.settings?.support_sms_quiet_hours?.enabled ||
      current?.settings?.skip_sending_on_weekend?.enabled ||
      current?.settings?.only_send_during_business_hours?.enabled
    ) {
      setEnableCampaignSettings(true);
    }
    if (
      current?.settings?.link_tracking?.disabled ||
      current?.settings?.link_tracking?.domain_id
    ) {
      setEnableLinkTrackingSettings(true);
    }
  }, []);

  // campaign settings options for sending and scheduling messages
  const [campaignSettings, setCampaignSettings] = useState<CampaignSettings>({
    support_sms_quiet_hours: {
      enabled: current?.settings?.support_sms_quiet_hours?.enabled || false,
      use_contact_timezone:
        current?.settings?.support_sms_quiet_hours?.use_contact_timezone || false,
    },
    skip_sending_on_weekend: {
      enabled: current?.settings?.skip_sending_on_weekend?.enabled || false,
      use_contact_timezone:
        current?.settings?.skip_sending_on_weekend?.use_contact_timezone || false,
    },
    only_send_during_business_hours: {
      enabled: current?.settings?.only_send_during_business_hours?.enabled || false,
      use_contact_timezone:
        current?.settings?.only_send_during_business_hours?.use_contact_timezone || false,
    },
    link_tracking: {
      disabled: current?.settings?.link_tracking?.disabled || false,
      domain_id: current?.settings?.link_tracking?.domain_id || null,
    },
  });

  // combine date, time and the timezone state and check it is a
  // validate time in the future use dayjs.tz timezone
  dayjs.extend(utc);
  dayjs.extend(timezone);
  const [isValidSchedule, setIsValidSchedule] = useState(false);

  // when the schedule state changes, check if the schedule is valid
  useEffect(() => {
    if (date && time && time_zone) {
      const validTime = dayjs.tz(`${date} ${time}`, time_zone).isValid();
      // is the time valid and in the future
      if (validTime) {
        setIsValidSchedule(true);
      } else {
        setIsValidSchedule(false);
      }
    }
  }, [date, time, time_zone]);

  // show the batch delay in correct time units
  useEffect(() => {
    if (current?.delivery_options?.period) {
      const delayInMinutes = current?.delivery_options?.period / 60;
      const hourInMinutes = getIntervalValue(BatchDelayTimeUnit.HOURS);
      const dayInMinutes = getIntervalValue(BatchDelayTimeUnit.DAYS);
      if (delayInMinutes >= hourInMinutes && delayInMinutes % hourInMinutes === 0) {
        setBatchDelay(delayInMinutes / hourInMinutes);
        setBatchDelayTimeUnit(BatchDelayTimeUnit.HOURS);
      }
      if (delayInMinutes >= dayInMinutes && delayInMinutes % dayInMinutes === 0) {
        setBatchDelay(delayInMinutes / dayInMinutes);
        setBatchDelayTimeUnit(BatchDelayTimeUnit.DAYS);
      }
    }
  }, [current?.delivery_options?.period]);

  // create schedule object
  const schedule_params: ScheduleOptions = {
    day: `${dayjs(date).date()}`,
    month: `${dayjs(date).month() + 1}`,
    year: `${dayjs(date).year()}`,
    hour: `${dayjs(`${date} ${time}`).hour()}`,
    minute: `${dayjs(`${date} ${time}`).format('mm')}`,
    timezone: time_zone,
  };

  const delivery_options = {
    // convert minutes to seconds
    period: batchDelay ? batchDelay * getIntervalValue(batchDelayTimeUnit) * 60 : 300,
    messages_per_period: batchSize,
  };

  const campaign_params = {
    title:
      campaignTitle ||
      `untitled-campaign-${dayjs(new Date()).format('YYYY-MM-DD')}T${dayjs(
        new Date()
      ).format('H:mm:ss')}`,
    status: CampaignStatus.DRAFT,
    body: message,
    attachment_urls: attachments.attachment_urls,
    audience: reduceAudienceToParams(includeAudience, includePreSelectAudience, location),
    excluded_contacts: reduceExcludeAudienceToParams(
      excludeAudience,
      excludePreSelectAudience,
      excludeCampaignSince,
      excludeContactsWithOpenConversation
    ),
    schedule_options: isScheduled ? schedule_params : null,
    delivery_options: enableBatchSend ? delivery_options : null,
    settings: campaignSettings,
  };

  // create a new campaign
  const createNewCampaign = async () => {
    const campaign = await createOneCampaign(campaign_params);
    setCurrentCampaign(campaign);
    return campaign;
  };

  // update the current campaign
  const updateCurrentCampaign = async () => {
    // update the campaign if not null
    if (current && current?.id) {
      updateOneCampaign(current.id, campaign_params, true);
    }
  };

  // save the current campaign, either create or update
  const handleSave = async (accordion: AccordionValue) => {
    if (current && current?.id) {
      await updateCurrentCampaign();
      setAccordion(accordion);
    } else {
      const campaign = await createNewCampaign();

      // if the campaign has and id then redirect to the campaign page
      if (campaign && campaign.id) {
        history.push(`/campaigns/${campaign.id}`);
      }
    }
  };

  // send or schedule the current campaign
  const handleSendCampaign = async () => {
    const send_campaign_params = {
      ...campaign_params,
      status: isScheduled ? CampaignStatus.SCHEDULED : CampaignStatus.IN_PROGRESS,
    };

    if (current && current.id) {
      const response = await updateOneCampaign(current.id, send_campaign_params, true);
      // if request does not fail show confetti and redirect
      if (response) {
        setShowConfetti();
        history.push(`/campaigns`);
      }
    }
  };

  const auth = useAuth();

  const [previewName, setPreviewName] = useState(auth?.tokens?.name || 'John Doe');

  const fillDemoMessage = () => {
    const organizationName = auth?.organizationName;

    // fill message pipe fields
    const filled_body = fillPipeFields(
      message,
      previewName || 'John Doe',
      locationObject?.name || '',
      locationObject?.address || '',
      locationObject?.google_place_id || '',
      organizationName || ''
    );

    return filled_body;
  };

  const hasTriggersOrTemplates =
    (current && current.triggers && current.triggers.length > 0) ||
    (current &&
      current.associated_automation_templates &&
      current.associated_automation_templates.length > 0)
      ? true
      : false;

  return (
    <Flex css={{ minHeight: '100%' }}>
      <PageLayout
        breadcrumbs={[
          { title: 'Campaigns', path: '/campaigns' },
          {
            title: `${current?.title || 'Create New Campaign'}`,
            path: `/campaigns/${current?.id || 'create'}`,
          },
        ]}
        actions={
          <HStack gap={2}>
            {messageCount > 0 && !loadingAudience && !disableAudienceCalculation && (
              <Text css={{ mr: 10, display: isSmallScreen ? 'none' : undefined }}>
                {`${messageCount || 0} Contacts`}
              </Text>
            )}
            {!isMobile ? (
              <Button
                variant="gray"
                onClick={() => handleSave(AccordionValue.DEFAULT_VALUE)}
                disabled={campaignTitle.length < 3}
              >
                Save as Draft
              </Button>
            ) : (
              <ToolTipIconButton
                variant="outline"
                size={2}
                icon={<HiSave />}
                description="Save as Draft"
                onClick={() => handleSave(AccordionValue.DEFAULT_VALUE)}
              />
            )}
            <CampaignPreview
              body={message}
              attachments={attachments.attachment_urls}
              location_id={location}
              campaignTitle={campaignTitle}
              previewName={previewName}
              setPreviewName={setPreviewName}
              fillDemoMessage={fillDemoMessage}
            />
            <CampaignConfirmation
              onConfirm={handleSendCampaign}
              numberOfContacts={disableAudienceCalculation ? 0 : messageCount}
              buttonCopy={isScheduled ? 'Schedule Campaign' : 'Send Campaign'}
              isScheduled={isScheduled}
            >
              {!isMobile ? (
                <Button
                  variant="send"
                  disabled={
                    !location ||
                    !hasIncludeAudience() ||
                    message.length < 20 ||
                    campaignTitle.length < 2 ||
                    (isScheduled && !isValidSchedule) ||
                    time_zone == ''
                  }
                >
                  {isScheduled ? 'Schedule Campaign' : 'Send Campaign'}
                </Button>
              ) : (
                <ToolTipIconButton
                  variant="send"
                  size={2}
                  disabled={
                    !location ||
                    !hasIncludeAudience() ||
                    message.length < 20 ||
                    campaignTitle.length < 2 ||
                    (isScheduled && !isValidSchedule) ||
                    time_zone == ''
                  }
                  icon={
                    <HiPaperAirplane
                      style={{
                        transform: 'rotate(90deg)',
                      }}
                    />
                  }
                  description={isScheduled ? 'Schedule Campaign' : 'Send Campaign'}
                  onClick={() => handleSave(AccordionValue.DEFAULT_VALUE)}
                />
              )}
            </CampaignConfirmation>
          </HStack>
        }
        width={`calc(100% - ${showPanel && isLargeDesktop ? '550px' : '55px'})`}
      >
        <Flex direction="column" css={{ pb: 200 }}>
          <Accordion
            type="single"
            defaultValue={AccordionValue.SELECT_LOCATION}
            value={accordion}
          >
            <VStack css={{ p: 20 }} gap="2">
              <CampaignBasicInfo
                accordion={accordion}
                setAccordion={setAccordion}
                campaignTitle={campaignTitle}
                setCampaignTitle={setCampaignTitle}
                location={location}
                setLocation={setLocation}
                handleSave={handleSave}
              />
              <CampaignAccordion
                index={2}
                title="Audience"
                description="Contacts who would receive this campaign"
                currentAccordionValue={accordion}
                itemValue={AccordionValue.SELECT_AUDIENCE}
                isValid={hasIncludeAudience()}
                isError={false}
                setItemValue={setAccordion}
              >
                <VStack gap="2">
                  <Fieldset data-testid="field-audience">
                    <Label>Select Audience</Label>
                    <Box css={{ p: 0, m: 0 }}>
                      <AudienceSelection
                        locationId={location}
                        selectedAudience={includeAudience}
                        setSelectedAudience={setIncludeAudience}
                        isInbox={false}
                        isContactsPage={false}
                        isGroupsPage={false}
                        isUploadsPage={false}
                        isCampaignsEditPage={false}
                        isCampaignsPage={false}
                        preSelectedAudience={includePreSelectAudience}
                        setPreSelectedAudience={setIncludePreSelectAudience}
                        clearStatesOnUnmount={false}
                        setDisableAudienceCalculation={setDisableAudienceCalculation}
                      />
                    </Box>
                  </Fieldset>
                  <Fieldset data-testid="field-exclude-audience">
                    <Label>Exclude</Label>
                    <Box>
                      <AudienceSelection
                        locationId={location}
                        selectedAudience={excludeAudience}
                        setSelectedAudience={setExcludeAudience}
                        isInbox={false}
                        isContactsPage={false}
                        isGroupsPage={false}
                        isUploadsPage={false}
                        isCampaignsEditPage={false}
                        isCampaignsPage={false}
                        preSelectedAudience={excludePreSelectAudience}
                        setPreSelectedAudience={setExcludePreSelectAudience}
                        clearStatesOnUnmount={false}
                        setDisableAudienceCalculation={setDisableAudienceCalculation}
                      />
                    </Box>
                  </Fieldset>
                  <Fieldset>
                    <Flex align="center" justify="between">
                      <Flex direction="column">
                        <Label>
                          Exclude Contacts with Open Conversations in any Location
                          (regardless of access)
                        </Label>
                        <Description>
                          Exclude contacts that have an open conversation with any phone
                          number in your organization regardless of your access.
                        </Description>
                      </Flex>
                      <Box css={{ mt: 5 }}>
                        <Switch
                          checked={excludeContactsWithOpenConversation}
                          onCheckedChange={() =>
                            setExcludeContactsWithOpenConversation(
                              !excludeContactsWithOpenConversation
                            )
                          }
                        >
                          <SwitchThumb />
                        </Switch>
                      </Box>
                    </Flex>
                  </Fieldset>
                  <Fieldset>
                    <Flex align="center" justify="between">
                      <Flex direction="column">
                        <Label>
                          Exclude Contacts who recently received another Campaign
                        </Label>
                        <Description>
                          Excludes contacts from this campaign that have recently received
                          another campaign.
                        </Description>
                      </Flex>
                      <Box css={{ mt: 5 }}>
                        <Switch
                          checked={excludeContactsWithOtherCampaigns}
                          onCheckedChange={() =>
                            setExcludeContactsWithOtherCampaigns(
                              !excludeContactsWithOtherCampaigns
                            )
                          }
                        >
                          <SwitchThumb />
                        </Switch>
                      </Box>
                    </Flex>
                    {excludeContactsWithOtherCampaigns && (
                      <Box
                        css={{
                          mt: 10,
                          p: 16,
                          backgroundColor: '#F6F6F6',
                          borderRadius: 4,
                        }}
                      >
                        <HStack>
                          <ControlGroup>
                            <IconButton
                              size={2}
                              css={{ backgroundColor: 'white' }}
                              onClick={() =>
                                excludeCampaignSince > 0
                                  ? setExcludeCampaignSince(excludeCampaignSince - 1)
                                  : setExcludeCampaignSince(excludeCampaignSince)
                              }
                            >
                              <HiMinus />
                            </IconButton>
                            <Input
                              type="number"
                              value={excludeCampaignSince}
                              onChange={(e) =>
                                setExcludeCampaignSince(Number(e.target.value))
                              }
                              css={{ textAlign: 'center', width: 50 }}
                            />
                            <IconButton
                              size={2}
                              css={{ backgroundColor: 'white' }}
                              onClick={() =>
                                setExcludeCampaignSince(excludeCampaignSince + 1)
                              }
                            >
                              <HiPlus />
                            </IconButton>
                          </ControlGroup>
                          <Box css={{ fontSize: 13 }}>days</Box>
                        </HStack>
                      </Box>
                    )}
                  </Fieldset>
                  <HStack>
                    <Button
                      onClick={() => handleSave(AccordionValue.CREATE_MESSAGE)}
                      disabled={!hasIncludeAudience() || false}
                    >
                      Save
                    </Button>
                    <Button
                      variant="gray"
                      ghost={true}
                      onClick={() => setAccordion(AccordionValue.DEFAULT_VALUE)}
                    >
                      Cancel
                    </Button>
                  </HStack>
                </VStack>
              </CampaignAccordion>
              <CampaignMessageEditor
                message={message}
                setMessage={setMessage}
                attachments={attachments}
                setAttachments={setAttachments}
                accordion={accordion}
                setAccordion={setAccordion}
                setCurrentEditor={setCurrentEditor}
                handleSave={handleSave}
                channel_type={getLocationById(location)?.type || 'phone'}
              />
              <CampaignSchedule
                accordion={accordion}
                showSchedulePicker={true}
                setAccordion={setAccordion}
                isScheduled={isScheduled}
                setIsScheduled={setIsScheduled}
                date={date}
                setDate={setDate}
                time={time}
                setTime={setTime}
                time_zone={time_zone}
                setTimezone={setTimezone}
                isValidSchedule={isValidSchedule}
                handleSave={handleSave}
                enableBatchSend={enableBatchSend}
                setEnableBatchSend={setEnableBatchSend}
                batchSize={batchSize}
                setBatchSize={setBatchSize}
                batchDelay={batchDelay}
                setBatchDelay={setBatchDelay}
                batchDelayTimeUnit={batchDelayTimeUnit}
                setBatchDelayTimeUnit={setBatchDelayTimeUnit}
                enableCampaignSettings={enableCampaignSettings}
                setEnableCampaignSettings={setEnableCampaignSettings}
                campaignSettings={campaignSettings}
                setCampaignSettings={setCampaignSettings}
                enableLinkTrackingSettings={enableLinkTrackingSettings}
                setEnableLinkTrackingSettings={setEnableLinkTrackingSettings}
              />
              <CampaignAccordion
                index={5}
                title="Automations"
                description="Automate campaign follow up tasks."
                currentAccordionValue={accordion}
                itemValue={AccordionValue.CREATE_AUTOMATION}
                setItemValue={setAccordion}
                isValid={hasTriggersOrTemplates}
              >
                <VStack gap="2">
                  <Fieldset>
                    <Flex direction="column">
                      <Label>Create Campaign Automation (optional)</Label>
                      <Description>
                        Campaign automations let you take actions when contacts respond to
                        your campaigns.
                      </Description>
                    </Flex>
                  </Fieldset>
                  <CampaignAutomations
                    current={current}
                    setCurrentCampaign={setCurrentCampaign}
                    updateCurrentCampaign={updateCurrentCampaign}
                    accordion={accordion}
                    setAccordion={setAccordion}
                    existingAllAutomationTemplates={existingAllAutomationTemplates}
                    setExistingAllAutomationTemplates={setExistingAllAutomationTemplates}
                  />
                </VStack>
              </CampaignAccordion>
            </VStack>
          </Accordion>
        </Flex>
      </PageLayout>
      <Flex css={{ height: '100%' }}>
        {showPanel && isLargeDesktop && (
          <SidebarNavigationContainer
            defaultWidth={360}
            minWidth={300}
            maxWidth={500}
            dragDirection="right"
            name="CREATE_CAMPAIGN"
            disableCollapse
          >
            <CampaignPanel
              setShowPanel={setShowPanel}
              panel={panel}
              loadingAudience={loadingAudience}
              audience={audiencePreview?.audience || []}
              audience_params={
                reduceAudienceToParams(
                  includeAudience,
                  includePreSelectAudience,
                  location
                ) || {}
              }
              excluded_audience={audiencePreview?.excluded_contacts || []}
              excluded_audience_params={
                reduceExcludeAudienceToParams(
                  excludeAudience,
                  excludePreSelectAudience,
                  excludeCampaignSince,
                  excludeContactsWithOpenConversation
                ) || {}
              }
              attachments={attachments.attachment_urls || []}
              preview={fillDemoMessage()}
              body={message}
              handleExclude={handleUpdateExcludePreSelectedAudience}
              editor={currentEditor}
            />
          </SidebarNavigationContainer>
        )}
        {!isLargeDesktop && (
          <Drawer open={showPanel}>
            <DrawerPortal>
              <DrawerContent
                side="right"
                css={{ width: 360, overflowY: 'scroll' }}
                onEscapeKeyDown={() => setShowPanel(false)}
                onPointerDownOutside={() => setShowPanel(false)}
              >
                <CampaignPanel
                  setShowPanel={setShowPanel}
                  panel={panel}
                  loadingAudience={loadingAudience}
                  audience={audiencePreview?.audience || []}
                  audience_params={
                    reduceAudienceToParams(
                      includeAudience,
                      includePreSelectAudience,
                      location
                    ) || {}
                  }
                  excluded_audience={audiencePreview?.excluded_contacts || []}
                  excluded_audience_params={
                    reduceExcludeAudienceToParams(
                      excludeAudience,
                      excludePreSelectAudience,
                      excludeCampaignSince,
                      excludeContactsWithOpenConversation
                    ) || {}
                  }
                  attachments={attachments.attachment_urls || []}
                  preview={fillDemoMessage()}
                  body={message}
                  handleExclude={handleUpdateExcludePreSelectedAudience}
                  editor={currentEditor}
                />
              </DrawerContent>
            </DrawerPortal>
          </Drawer>
        )}
        <CampaignToolbar handlePanelClick={handlePanelClick} open={showPanel} />
      </Flex>
    </Flex>
  );
};

export default CreateSMSCampaign;
