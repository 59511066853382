/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { HiInformationCircle, HiX } from 'react-icons/hi';
import { IdType, useRowSelect, useTable } from 'react-table';
import { Column as ReactTableColumn } from 'react-table';
import { useMedia } from 'react-use';
import { TableVirtuoso, VirtuosoHandle } from 'react-virtuoso';

import { TooltipButton } from '@/shared/components/TooltipButton';
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogOverlay,
  AlertDialogPortal,
  AlertDialogTitle,
  Box,
  Button,
  Flex,
  HStack,
  IconButton,
  Skeleton,
} from '@/shared/ui';
import { styled } from '@/stitches.config';

import { BulkActionButton, BulkActionDropdown } from './bulkAction/index';

export type BulkAction = {
  // Title of the selected bulk action confirmation dialog
  title: string;
  // A label that will show along side the bulk action panel
  label: string;
  // Communicates to the API which bulk action should be performed, eg. sequences.contacts.remove
  action: string;
  type?: 'default' | 'destructive';
  // Icon to be rendered in the bulk actions panel
  icon?: React.ReactNode | null;
  // Message to be rendered in the bulk action confirmation dialog (takes number of selected rows as an argument)
  getConfirmationMessage: (selected: number) => string;
  onAllSelectedSubmit: () => void;
  onManualSelectedSubmit: (selectedContactIds: string[]) => void;
};

type FiltersTableProps<T extends object> = {
  /** react-table columns */
  columns: ReactTableColumn<T>[];
  /** Row data returned from API */
  data: T[] | [];
  /** Total number of rows returned from API */
  totalCount: number;
  /** show total count? */
  showTotalCount?: boolean;
  /** Loading state */
  isLoading: boolean;
  /** Callback function when scrolled to bottom of table (eg. to fetch more data) */
  onEndReached: () => void;
  /** A configuration array of bulk actions to be rendered in the table */
  bulkActions?: BulkAction[];
  /** Element to render when there are no rows */
  emptyStateElement: JSX.Element;
  /** Ref for the table */
  tableRef?: React.RefObject<VirtuosoHandle>;
  /** Offset used in calculating the height of the table to ensure it takes up the available vertical space */
  heightOffset?: number;
};

export const Loading = () => (
  <Box css={{ overflow: 'hidden' }} data-testid="table-loading-placeholder">
    {Array.from({ length: 20 }, (key: React.Key) => (
      <Box key={key} css={{ padding: '15px 0', borderTop: '1px solid $gray3' }}>
        <Skeleton css={{ height: 19, margin: '0 30px' }} />
      </Box>
    ))}
    {/* Fixed table total count row (loading state) - commented out until discrepancies with tab total amounts are resolved */}
    <span
      style={{
        position: 'fixed',
        bottom: 0,
        width: '100%',
        padding: '0 30px',
        height: 50,
        background: 'white',
        boxShadow: 'inset 0 1px 0 #f1f1f1',
        fontSize: 13,
      }}
    >
      <Box>
        <Skeleton css={{ width: 60, height: 19 }} />
      </Box>
    </span>
  </Box>
);

/*
Takes in a message with a possibly bolded string through **
*/
const BoldMessageBox = ({ message }: { message: string }) => {
  const parts = message.split(/(\*\*.*?\*\*)/g); // Split on the marker, keeping the markers in the result
  return (
    <Box style={{ fontSize: '16px' }}>
      {parts.map((part, index) => {
        if (part.startsWith('**') && part.endsWith('**')) {
          // Remove the markers and return bold text
          return <strong key={index}>{part.slice(2, -2)}</strong>;
        } else {
          // Return normal text
          return <span key={index}>{part}</span>;
        }
      })}
    </Box>
  );
};

/**
 * The Simple Table component is a react-table which renders a table with a fixed header and virtualized rows.
 *
 * This table also has the ability to support bulk actions. You can do so by implementing the following.
 *  - Provide checkboxes that hook into react table props to the table (usually done so in the Header or Cell)
 *    - This is because we rely on react table to manage the state of selected rows. We can hook in the rows
 *          with the react table hooks by using `getToggleAllRowsSelectedProps` or `getToggleRowSelectedProps`
 *          given to us through the props of Header and Cell.
 *          `
 *    - We do provide a IndeterminateCheckbox in the shared components which can be used to indicate the case
 *          where some contacts are selected but not all.
 *
 * @param columns - The react-table columns.
 * @param data - The data rows which will be rendered.
 * @param totalCount - The total number of data rows.
 * @param isLoading - A boolean value used to render elements while new data is being fetched.
 * @param onEndReached - A callback function which is called when you scroll to the bottom of the table.
 * @param bulkActions - An array of actions with an icon, action, title & getConfirmationMessage.
 * @param emptyStateElement - An element to be rendered when the number of data rows is zero.
 * @param tableRef - A ref value for the table.
 * @param heightOffset - An offset used in calculating the height of the table to ensure it takes up the available vertical space.
 */

export const FilteredTable = <T extends object>({
  columns,
  data,
  totalCount,
  showTotalCount = false,
  isLoading,
  onEndReached,
  bulkActions,
  emptyStateElement,
  tableRef,
  heightOffset = 287,
}: FiltersTableProps<T>) => {
  const [selectedIds] = useState<Record<IdType<T>, boolean>>(
    {} as Record<IdType<T>, boolean>
  );
  const [isAllRecordsSelected, setIsAllRecordsSelected] = useState(false);
  const [isBulkActionsDialogOpen, setIsBulkActionsDialogOpen] = useState(false);
  const [selectedBulkAction, setSelectedBulkAction] = useState<BulkAction>({
    title: '',
    label: '',
    action: '',
    icon: null,
    getConfirmationMessage: () => '',
    // eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/no-empty-function
    onManualSelectedSubmit: (_selectedContacts) => {},
    // eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/no-empty-function
    onAllSelectedSubmit: () => {},
  });
  const isLarge = useMedia('(min-width: 1200px)');

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    // An array of row objects that are currently selected.
    selectedFlatRows,
    // Used to toggle the selected state of rows.
    toggleRowSelected,
  } = useTable(
    {
      columns,
      data,
      initialState: { selectedRowIds: selectedIds },
      autoResetSelectedRows: false,
    },
    useRowSelect
  );

  // Set the selected bulk action & open the confirmation dialog
  const handleSelectBulkAction = (selectedAction: BulkAction) => {
    setIsBulkActionsDialogOpen(true);
    setSelectedBulkAction(selectedAction);
  };

  // Toggle the selected state of all table rows
  const toggleAllRows = (isSelected: boolean) => {
    setIsAllRecordsSelected(isSelected); // TODO
    rows.forEach((row) => {
      toggleRowSelected(row.id, isSelected);
    });
  };

  // Toggle the state of isAllRecordsSelected & set the selected state of all table rows
  const toggleIsAllRecordsSelected = () => {
    setIsAllRecordsSelected((prev) => {
      toggleAllRows(!prev);
      return !prev;
    });
  };

  const handleConfirmBulkAction = () => {
    const selectedIds = selectedFlatRows.map((row: any) => row.original.id);
    if (isAllRecordsSelected && selectedBulkAction) {
      selectedBulkAction.onAllSelectedSubmit();
    } else if (selectedBulkAction && selectedIds.length > 0) {
      selectedBulkAction.onManualSelectedSubmit(selectedIds);
    }
    setIsBulkActionsDialogOpen(false);
    toggleAllRows(false);
    setIsAllRecordsSelected(false);
  };

  // Assign a value to the bulk actions confirmation message depending on whether all rows are selected
  const bulkActionMessage = isAllRecordsSelected
    ? selectedBulkAction.getConfirmationMessage(totalCount)
    : selectedBulkAction.getConfirmationMessage(selectedFlatRows.length);

  const heightDifference = {
    height: `calc(100vh - ${heightOffset}px)`,
  };

  // When the rows are updated (lazy loaded), select all rows if isAllRecordsSelected is true
  useEffect(() => {
    if (isAllRecordsSelected) {
      toggleAllRows(true);
    }
  }, [rows]);

  // If all rows are selected, set isAllRecordsSelected to true, otherwise set it to false
  useEffect(() => {
    // If a row is unselected after all rows have been selected, set isAllRecordsSelected to false
    if (rows.length - selectedFlatRows.length === 1 && isAllRecordsSelected) {
      setIsAllRecordsSelected(false);
    }

    // If a we unselect all rows with the select all checkbox, set isAllRecordsSelected to false
    if (selectedFlatRows.length === 0 && isAllRecordsSelected) {
      setIsAllRecordsSelected(false);
    }

    // If all possible rows are selected, set isAllRecordsSelected to true
    if (selectedFlatRows.length === totalCount && totalCount > 0) {
      setIsAllRecordsSelected(true);
    }
  }, [selectedFlatRows]);

  // Clear all row selections when the filters change
  useEffect(() => {
    setIsAllRecordsSelected(false);
    toggleAllRows(false);
  }, [isLoading]);

  return (
    <>
      <Table data-testid="filters-table">
        <TableVirtuoso
          ref={tableRef}
          data={data}
          initialItemCount={data.length} // This is important for testing purposes. If this is not set, the table will not render any rows in the testing environment.
          endReached={() => onEndReached()}
          style={{
            ...heightDifference,
            overflowY: isLoading || data.length === 0 ? 'hidden' : 'auto',
          }}
          components={{
            Table: ({ style, ...props }) => <table {...getTableProps()} {...props} />,
            TableBody: React.forwardRef(({ style, ...props }, ref) => (
              <>
                {isLoading ? (
                  <tbody {...getTableBodyProps()} {...props} ref={ref}>
                    <tr style={{ pointerEvents: 'none' }}>
                      <td colSpan={columns.length} style={{ padding: 0 }}>
                        <Loading />
                      </td>
                    </tr>
                  </tbody>
                ) : data.length === 0 ? (
                  <tbody {...getTableBodyProps()} {...props} ref={ref}>
                    <Flex
                      data-testid="empty-data-placeholder"
                      align="center"
                      justify="center"
                      css={{
                        position: 'absolute',
                        top: '50%',
                        right: '50%',
                        left: '50%',
                        whiteSpace: 'nowrap',
                      }}
                    >
                      {emptyStateElement}
                    </Flex>
                  </tbody>
                ) : (
                  <>
                    <tbody {...getTableBodyProps()} {...props} ref={ref} />
                    {/* Fixed table total count row - commented out until discrepancies with tab total amounts are resolved */}
                    {showTotalCount && (
                      <span
                        style={{
                          position: 'fixed',
                          bottom: 0,
                          width: '100%',
                          padding: '15px 30px',
                          height: 50,
                          background: 'white',
                          boxShadow: 'inset 0 1px 0 #f1f1f1',
                        }}
                      >
                        <>
                          <span style={{ fontWeight: 500, paddingRight: 2 }}>
                            {totalCount}
                          </span>
                          <span>{' count'}</span>
                        </>
                      </span>
                    )}
                  </>
                )}
              </>
            )),
            TableRow: (props) => {
              const index = props['data-index'];
              const row = rows[index];
              return row ? (
                <tr data-testid="filters-table-row" {...props} {...row.getRowProps()} />
              ) : null;
            },
            FillerRow: ({ height }) => {
              return (
                <tr>
                  <td
                    colSpan={columns.length}
                    style={{ height: height, padding: 0, border: 0 }}
                  />
                </tr>
              );
            },
          }}
          fixedHeaderContent={() => {
            return headerGroups.map((headerGroup, index) => (
              <tr {...headerGroup.getHeaderGroupProps()} key={`tr-${index}`}>
                {headerGroup.headers.map((column) => (
                  <th
                    {...column.getHeaderProps()}
                    key={`th-${column.id}`}
                    style={{
                      height: 40,
                      maxHeight: 40,
                      width: column.colWidth || 'auto',
                      minWidth: column.colWidth || 'auto',
                      maxWidth: column.colWidth || 'auto',
                      paddingTop: 4,
                      paddingBottom: 3,
                      fontSize: 13,
                      justifyContent: 'center',
                    }}
                  >
                    <HStack align="center" gap={2}>
                      {column.render('Header')}
                      {column.tooltipText && (
                        <span className="tooltipContainer" style={{ paddingLeft: 6 }}>
                          <HiInformationCircle size={16} color={'#4d4d4d'} />
                          <span>{column.tooltipText}</span>
                        </span>
                      )}
                    </HStack>
                  </th>
                ))}
              </tr>
            ));
          }}
          itemContent={(index) => {
            const row = rows[index];
            if (!row) {
              return null; // or return a placeholder or loading indicator
            }
            prepareRow(row);
            return row.cells.map((cell) => {
              return (
                <td
                  {...cell.getCellProps()}
                  key={`td-${cell.column.id}`}
                  style={{
                    height: 50,
                    maxHeight: 50,
                    width: cell.column.colWidth || 'auto',
                    maxWidth: cell.column.colWidth || 'auto',
                  }}
                >
                  {cell.render('Cell')}
                </td>
              );
            });
          }}
        />

        {/* Bulk Action Panel Container */}
        <Flex align="center" css={{ padding: '16px' }}>
          {selectedFlatRows.length > 0 && (
            <BulkActionsPanel
              css={{
                minWidth: isLarge ? '60%' : '570px',
              }}
              data-testid="bulk-actions-panel"
              justify="between"
              align="center"
            >
              {/* Selected Count and Select All Container */}
              <Flex
                css={{
                  mr: isLarge ? '40px' : '4px',
                  fontSize: '14px',
                  paddingLeft: '10px',
                  color: 'Black',
                  alignItems: 'center',
                }}
              >
                <Box css={{ mr: 15, whiteSpace: 'nowrap' }}>
                  <span
                    style={{ fontWeight: 'bold' }}
                    data-testid="bulk-action-panel-selected-contacts"
                  >
                    {isAllRecordsSelected ? totalCount : selectedFlatRows.length}
                  </span>{' '}
                  /{' '}
                  <span data-testid="bulk-action-panel-total-contacts">
                    {' '}
                    {totalCount}
                  </span>
                </Box>
                {!isAllRecordsSelected && (
                  <Box
                    css={{ cursor: 'pointer', textDecoration: 'underline' }}
                    onClick={toggleIsAllRecordsSelected}
                  >
                    Select All
                  </Box>
                )}
              </Flex>
              {/* Bulk Action */}
              <Flex align="center" justify="between" css={{ gap: '12px' }}>
                {bulkActions &&
                  bulkActions
                    .slice(0, 2)
                    .map((bulkAction: BulkAction) => (
                      <BulkActionButton
                        key={bulkAction.action}
                        bulkAction={bulkAction}
                        handleClick={() => handleSelectBulkAction(bulkAction)}
                      />
                    ))}
                <Flex align="center">
                  {bulkActions && (
                    <BulkActionDropdown
                      bulkActions={bulkActions.slice(2)}
                      handleClick={handleSelectBulkAction}
                    />
                  )}
                  <TooltipButton text="Close the action panel" side="top" sideOffset={20}>
                    <IconButton
                      data-testid="bulk-actions-panel-close-panel-button"
                      size="2"
                      css={{ marginLeft: '4px' }}
                      onClick={() => {
                        toggleAllRows(false);
                        toggleAllRows(false);
                      }}
                    >
                      <HiX size={16} />
                    </IconButton>
                  </TooltipButton>
                </Flex>
              </Flex>
            </BulkActionsPanel>
          )}
        </Flex>

        <AlertDialog open={isBulkActionsDialogOpen}>
          <AlertDialogPortal>
            <AlertDialogOverlay />
            <AlertDialogContent
              onEscapeKeyDown={() => setIsBulkActionsDialogOpen(false)}
              css={{ overflow: 'auto' }}
            >
              <AlertDialogTitle
                css={{
                  fontSize: '20px',
                }}
              >
                {selectedBulkAction?.title}
              </AlertDialogTitle>
              <Box>
                <BoldMessageBox message={bulkActionMessage} />
                <Flex justify="end" css={{ pt: 20, gap: '8px' }}>
                  <AlertDialogCancel asChild>
                    <Button
                      css={{ mr: 5 }}
                      variant="gray"
                      onClick={() => setIsBulkActionsDialogOpen(false)}
                    >
                      Cancel
                    </Button>
                  </AlertDialogCancel>
                  <AlertDialogAction asChild>
                    <Button onClick={() => handleConfirmBulkAction()}>Confirm</Button>
                  </AlertDialogAction>
                </Flex>
              </Box>
            </AlertDialogContent>
          </AlertDialogPortal>
        </AlertDialog>
      </Table>
    </>
  );
};

const BulkActionsPanel = styled(Flex, {
  backgroundColor: '#FFFFFF',
  position: 'absolute',
  left: '50%',
  transform: 'translateX(-50%)',
  padding: '16px 16px 16px 16px',
  color: 'white',
  bottom: '70px',
  zIndex: '999999',
  border: '1px solid rgba(0, 0, 59, 0.05)',
  borderRadius: '8px',
  boxShadow:
    '0px 12px 32px -16px  rgba(0, 0, 48, 0.11), 0px 12px 60px 0px  rgba(0, 0, 0, 0.05)',
});

const Table = styled('table', {
  boxSizing: 'border-box',
  fontSize: '13px',
  width: '100%',
  tableLayout: 'fixed',
  borderSpacing: 0,
  textAlign: 'left',
  backgroundColor: 'white',
  borderTop: '1px solid $gray3',

  '& table': {
    tableLayout: 'fixed',
    width: '100%',
  },

  '& thead': {
    position: 'sticky',
    top: 0,
    'tr > th:first-child': {
      paddingLeft: 30,
    },
  },

  '& tr': {
    height: 40,
    boxSizing: 'border-box',
    fontSize: '13px',
    position: 'relative',
    backgroundColor: 'white',
    '&:hover': {
      backgroundColor: '$slate2',

      '& td': {
        backgroundColor: '$slate2',

        '& div': {
          backgroundColor: '$slate2',
        },
      },
    },
  },

  '& td': {
    boxSizing: 'border-box',
    position: 'relative',
    margin: 0,
    py: 5,
    px: 15,
    borderBottom: '1px solid $gray3',
    borderRight: '1px solid $gray3',

    '& > .totalCount': {
      position: 'sticky',
      left: 0,
      paddingLeft: 30,
    },
  },

  '& tbody tr td:first-child': {
    paddingLeft: 30,
  },

  '.tooltipContainer span': {
    position: 'absolute',
    top: 10,
    left: 80,
    visibility: 'hidden',
    padding: '5px 10px',
    backgroundColor: '$gray12',
    color: '$slate1',
    fontWeight: 400,
    borderRadius: 6,
    wordWrap: 'break-word',
    maxWidth: '140px',
  },
  '.tooltipContainer:hover span': {
    visibility: 'visible',
  },

  '& th': {
    position: 'relative',
    height: 44,
    fontWeight: 500,
    boxSizing: 'border-box',
    margin: 0,
    py: 5,
    paddingLeft: 15,
    zIndex: 1,
    borderBottom: '1px solid $gray3',
    borderRight: '1px solid $gray3',
    backgroundColor: '#F8F9FA',
  },
});
