/* eslint-disable react-hooks/exhaustive-deps */
import moment from 'moment';
import React, { useState } from 'react';
import { Virtuoso } from 'react-virtuoso';

import { useLocations } from '@/pages/settings/organization/locations/context/LocationContext';
import { Datepicker } from '@/shared/components/datepicker/Datepicker';
import { MultiSelect } from '@/shared/components/MultiSelect';
import { DraftMessage as DraftMessageType } from '@/shared/types';
import { Box, Flex, Text, VStack } from '@/shared/ui';
import { styled } from '@/stitches.config';

import { ConversationHeaderContainer } from '../conversation/header';
import { useDraftMessages } from './context/DraftMessagesContext';
import { DraftMessage, LoadingDraftMessage } from './DraftMessage';

export const DraftMessagesList = () => {
  const draft = useDraftMessages();
  const { getPartOfDraftMessages } = draft;
  const { draftMessages, loading } = draft.draftMessagesState;

  const {
    locationsState: { locations },
  } = useLocations();

  // map locations to multi select
  const [selectedLocations, setSelectedLocations] = useState<{ locations: string[] }>({
    locations: locations.map((location) => location.id),
  });

  // set the location on mount
  React.useEffect(() => {
    setSelectedLocations({ locations: locations.map((location) => location.id) });
  }, [locations]);

  // set the initial start date of the datepicker to the last 30 days
  // set the initial end date of the datepicker to the current date
  const [dates, setDates] = useState({
    startDate: moment().add(-60, 'day'),
    endDate: moment().add(1, 'day'),
  });

  const getDraftMessages = (
    locations: Array<string>,
    startingDate: moment.Moment | string,
    endingDate: moment.Moment | string
  ) => {
    const formattedStartDate = moment(startingDate).format('YYYY-MM-DD');
    const formattedEndDate = moment(endingDate).format('YYYY-MM-DD');

    if (formattedStartDate !== 'Invalid date' && formattedEndDate !== 'Invalid date') {
      getPartOfDraftMessages({
        start_date: formattedStartDate,
        end_date: formattedEndDate,
        location_ids: locations,
      });
    }
  };

  const handleSetDate = (date: { startDate: moment.Moment; endDate: moment.Moment }) => {
    setDates(date);
    getDraftMessages(selectedLocations.locations, date.startDate, date.endDate);
  };

  const handleSetSelectedLocations = ({ locations }: { locations: string[] }) => {
    getDraftMessages(locations, dates.startDate, dates.endDate);
    setSelectedLocations({ locations: locations });
  };

  // sort the draft messages by updated_at date in descending order
  draftMessages.sort((a, b) => {
    return moment(b.updated_at).diff(moment(a.updated_at));
  });

  return (
    <DraftMessagesContainer direction="column">
      <VStack css={{ width: '100%', mb: 20, pr: 20 }} gap="2">
        <Flex justify="between">
          {locations.length > 0 && (
            <Box css={{ width: '25%' }}>
              <MultiSelect
                defaultPlaceholder="Channels"
                defaultSelectedItems={selectedLocations.locations}
                isDropdown={true}
                options={locations.map((location) => ({
                  type: location?.name || '',
                  value: location.id,
                }))}
                setParentSelectedItems={handleSetSelectedLocations}
                isCampaigns={false}
              />
            </Box>
          )}
          <Box>
            <Datepicker setParentDates={handleSetDate} />
          </Box>
        </Flex>
      </VStack>
      {draftMessages.length === 0 && !loading && (
        <EmptyStateContainer>
          <DraftMessagesEmptyState />
        </EmptyStateContainer>
      )}
      {loading && (
        <VStack>
          {Array.from({ length: 5 }, (_, k) => (
            <LoadingDraftMessage key={k} />
          ))}
        </VStack>
      )}
      {draftMessages.length > 0 && (
        <Virtuoso
          data={draftMessages}
          atBottomStateChange={(atBottom) => {
            if (atBottom && draftMessages.length >= 50) {
              getDraftMessages(
                selectedLocations?.locations || [],
                dates.startDate,
                dates.endDate
              );
            }
          }}
          itemContent={(i: number, message: DraftMessageType) => (
            <DraftMessage key={`draft_message_${i}`} draftMessage={message} />
          )}
        />
      )}
    </DraftMessagesContainer>
  );
};

export const DraftMessages = () => {
  return (
    <Flex direction="column" css={{ position: 'relative', flexGrow: 1 }}>
      <ConversationHeaderContainer align="center">
        <Text css={{ fontWeight: 600 }}>Draft Messages</Text>
      </ConversationHeaderContainer>
      <DraftMessagesList />
    </Flex>
  );
};

export const DraftMessagesEmptyState = () => {
  return (
    <DraftMessagesContainer align="center" justify="center" direction="column">
      <Text css={{ fontWeight: 600 }}>Draft Messages</Text>
      <Text css={{ mt: 10 }}>
        Your draft messages will appear here when your create one.
      </Text>
    </DraftMessagesContainer>
  );
};

const DraftMessagesContainer = styled(Flex, {
  pl: 20,
  py: 20,
  width: '100%',
  minWidth: '100%',
  height: '100%',
});

const EmptyStateContainer = styled(Flex, {
  p: 20,
  height: '100%',
  width: '100%',
});
