/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/display-name */
import dayjs from 'dayjs';
import { debounce, DebouncedFunc } from 'lodash';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useMemo } from 'react';

import { handleFilterChange, handleSortChange } from '@/pages/data/utils/filterActions';
import { ConfirmationDialog } from '@/shared/components/ConfirmationDialog';
import { CombinedFilters } from '@/shared/components/filterBuilder/CombinedFilters';
import {
  default_created_by_object,
  default_message_template_object,
  default_updated_by_object,
} from '@/shared/components/filterBuilder/objects/message_template';
import { useResizeObserver } from '@/shared/hooks/useResizeObserver';
import { SearchFilters } from '@/shared/types/contacts';
import { FilterType, Sort } from '@/shared/types/filter';
import { Template } from '@/shared/types/templates';
import { Box, Flex } from '@/shared/ui';
import { TooltipAvatar } from '@/shared/ui/TooltipAvatar';
import { Table, TableColumn } from '@/shared/v2/components/table/Table';
import { TableActionMenu } from '@/shared/v2/components/table/TableActionMenu';
import { styled } from '@/stitches.config';

import {
  ConfirmationDialogDescription,
  CustomDropdownMenuItem,
  CustomDropdownMenuItemWarning,
} from '../users/UsersTable';
import { AddTemplate } from './AddTemplate';
import {
  initialTemplateState,
  ITEMS_COUNT,
  useTemplates,
} from './context/TemplatesContext';
import { sortConfig } from './filterConfig';
import { UpdateTemplate } from './UpdateTemplate';

function TemplatesTable(): JSX.Element {
  const [quickSearchValue, setQuickSearchValue] = useState('');
  const [activeFilters, setActiveFilters] = useState<FilterType[]>([]);

  const templatesContext = useTemplates();
  const { templatesState, updateFilterParams, deleteTemplate } = templatesContext;
  const { templates, loading, filterParams } = templatesState;

  const containerRef = useRef() as React.MutableRefObject<HTMLInputElement>;
  const defaultTemplatePreviewWidth = 300;
  const offsetWidth = 900;
  const [editorWidth, setEditorWidth] = useState<number>(defaultTemplatePreviewWidth);

  useResizeObserver((resizeObserverData: ResizeObserverEntry[]) => {
    const width = resizeObserverData[0].contentRect.width - offsetWidth;
    setEditorWidth(
      width < defaultTemplatePreviewWidth ? defaultTemplatePreviewWidth : width
    );
  }, containerRef);

  useEffect(() => {
    updateFilterParams({
      ...initialTemplateState.filterParams,
    });
  }, []);

  const columns: Array<TableColumn<Template>> = useMemo(
    () => [
      {
        Header: 'Templates',
        colWidth: '100%',
        accessor: 'name',
        Cell: (props: { row: { original: Template } }) => (
          <Flex direction="column">
            <TemplateTitle>{props.row.original.title}</TemplateTitle>
            <TemplatePreview css={{ maxWidth: editorWidth }}>
              {props.row.original.message || 'This template contains attachments only.'}
            </TemplatePreview>
          </Flex>
        ),
      },
      {
        Header: 'Created by',
        accessor: 'created_b',
        Cell: (props: { row: { original: Template } }) => (
          <>
            {!!props.row.original.created_by && (
              <TooltipAvatar user={props.row.original.created_by} />
            )}
          </>
        ),
      },
      {
        Header: 'Updated by',
        accessor: 'updated_by',
        Cell: (props: { row: { original: Template } }) => (
          <>
            {!!props.row.original.updated_by && (
              <TooltipAvatar user={props.row.original.updated_by} />
            )}
          </>
        ),
      },
      {
        Header: 'Updated At',
        id: 'updated_at',
        accessor: 'updated_at',
        colWidth: '100%',
        Cell: (props: { row: { original: Template } }) => (
          <Flex align="center" css={{ minWidth: 125 }}>
            <Box>{dayjs(props.row.original.updated_at).format('MMM D YYYY')}</Box>
          </Flex>
        ),
      },
      {
        Header: '',
        accessor: 'id',
        Cell: (props: { row: { original: Template } }) => (
          <>
            <TableActionMenu>
              <>
                <UpdateTemplate template={props.row.original}>
                  <CustomDropdownMenuItem
                    data-testid="edit-template-option"
                    onClick={(e) => e.preventDefault()}
                  >
                    Edit Template
                  </CustomDropdownMenuItem>
                </UpdateTemplate>
                <ConfirmationDialog
                  width="432px"
                  title="Delete Template?"
                  description={
                    <ConfirmationDialogDescription
                      value={props.row.original?.title ?? ''}
                      description="will be deleted."
                    />
                  }
                  onConfirm={() => deleteTemplate(props.row.original)}
                  confirmButtonTitle="Confirm"
                  cancelButtonTitle="Cancel"
                  confirmButtonVariant="redBackground"
                  cancelButtonVariant="grayBackground"
                >
                  <CustomDropdownMenuItemWarning
                    data-testid="delete-template-option"
                    onClick={(e) => e.preventDefault()}
                  >
                    Delete Template
                  </CustomDropdownMenuItemWarning>
                </ConfirmationDialog>
              </>
            </TableActionMenu>
          </>
        ),
      },
    ],
    [loading, editorWidth]
  );

  const handleQuickSearch = (
    debouncedUpdate: DebouncedFunc<(props: SearchFilters) => void>,
    filters: SearchFilters,
    value: string
  ) => {
    const formattedValue = value.trim();
    const quickFilters = formattedValue
      ? [
          {
            column: 'title',
            comparison: 'ilike',
            value: `%${formattedValue}%`,
            resource: 'message_template',
            or: [
              {
                column: 'message',
                comparison: 'ilike',
                value: `%${formattedValue}%`,
                resource: 'message_template',
              },
            ],
          },
        ]
      : [];
    debouncedUpdate({
      ...filters,
      searchFilter: quickFilters,
      offset: 0,
    });
  };

  const debouncedUpdate = useCallback(
    debounce((props: SearchFilters) => {
      updateFilterParams(props);
    }, 1000),
    []
  );

  return (
    <Flex direction="column" css={{ flex: '1 1 auto' }}>
      <Box css={{ paddingBottom: 24 }}>
        <CombinedFilters
          quickSearchPlaceholder="Search Templates"
          quickSearchValue={quickSearchValue}
          setQuickSearchValue={(value: string) => {
            setQuickSearchValue(value);
            handleQuickSearch(debouncedUpdate, filterParams, value);
          }}
          defaultObjects={[
            default_message_template_object,
            default_created_by_object,
            default_updated_by_object,
          ]}
          customObjects={[]}
          activeFilters={activeFilters}
          setFilters={(value: Array<FilterType>) => {
            setActiveFilters(value);
            handleFilterChange(debouncedUpdate, filterParams, value);
          }}
          sortConfig={sortConfig}
          activeSort={filterParams.sort}
          onSortUpdate={(value: Array<Sort>) =>
            handleSortChange(debouncedUpdate, filterParams, value)
          }
        />
      </Box>
      <div ref={containerRef}>
        <Table
          data={templates}
          columns={columns}
          caption="Templates Table"
          totalCount={templatesState.totalCount}
          emptyTitle="No Templates Match Search"
          setOffset={(offset) => {
            updateFilterParams({
              ...filterParams,
              offset,
            });
          }}
          isLoading={loading}
          pageSize={ITEMS_COUNT}
          empty={
            <Box>
              <Box
                css={{
                  width: 250,
                  fontSize: 16,
                  marginTop: 8,
                  marginBottom: 16,
                  fontWeight: 400,
                }}
              >
                <Box css={{ padding: '6.5px 0' }}>No templates were found.</Box>
                <Box>
                  <AddTemplate />
                </Box>
              </Box>
            </Box>
          }
        />
      </div>
    </Flex>
  );
}

const TemplatePreview = styled(Box, {
  color: '#718196',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  maxWidth: 300,
  textOverflow: 'ellipsis',
});

const TemplateTitle = styled(Box, {
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  maxWidth: 200,
  textOverflow: 'ellipsis',
});

export default TemplatesTable;
