import { SearchFilters } from '../contacts';
import { User } from '../users';

export type Location<P extends ProviderTypes = ProviderTypes> = {
  /* location uuid */
  id: string;
  /* location name */
  name?: string;
  /* location address */
  address?: string;
  /* webchat uuid */
  widget_setting_id?: string | null;
  /* location phone number */
  phone?: string;
  /* location state e.g. enabled, disabled */
  state?: LocationStates;
  /* google review link */
  review_link?: string;
  /* location timezone */
  timezone?: string | null;
  /* location opening hours object */
  opening_hours?: Array<OpeningHour> | null;
  /* location google place id */
  google_place_id?: string | null;
  /* location google location id */
  google_location_id?: string | null;
  /* location google account id */
  google_account_id?: string | null;
  /* custom open automated response */
  automatic_response_open?: string | null;
  /* custom closed automated response */
  automatic_response_closed?: string | null;
  /* send automated response when? */
  send_automatic_response_when?: SendWhenTypes;
  /* api provider for location sending */
  provider?: P | null;
  /* agent id */
  agent_id?: string | null;
  /* api provider custom auth params */
  provider_auth?:
    | TelnyxAuthParams
    | TwilioAuthParams
    | VonageAuthParams
    | BandwidthAuthParams
    | MailgunAuthParams
    | SinchAuthParams
    | null;
  /* location types */
  type?: LocationTypes;
  /* location settings */
  settings?: LocationSettings;
  /* location delivery options for a campaign */
  delivery_options?: DeliveryOptions | null;
  created_by?: User;
  updated_by?: User;
  created_at?: string;
  updated_at?: string;
};

export type DeliveryOptions = {
  /* max messages per period */
  messages_per_period: number;
  /* period in seconds */
  period: number;
};

export type OpeningHour = {
  /* opening hour uuid */
  id: string;
  /* opening time */
  opens_at: string;
  /* closing time */
  closes_at: string;
  /* day of week */
  weekday: string;
  /* opening hour state */
  state: string;
};

export enum ProviderTypes {
  TELNYX = 'telnyx',
  TWILIO = 'twilio',
  VONAGE = 'vonage',
  BANDWIDTH = 'bandwidth',
  MAILGUN = 'mailgun',
  SINCH = 'sinch',
}

export enum LocationStates {
  ENABLED = 'enabled',
  DISABLED = 'disabled',
  ARCHIVED = 'archived',
}

export enum SendWhenTypes {
  ALWAYS = 'always',
  OPEN = 'open',
  CLOSED = 'closed',
  NEVER = 'never',
}

export enum WeekdayTypes {
  MONDAY = 'Monday',
  TUESDAY = 'Tuesday',
  WEDNESDAY = 'Wednesday',
  THURSDAY = 'Thursday',
  FRIDAY = 'Friday',
  SATURDAY = 'Saturday',
  SUNDAY = 'Sunday',
}

export enum LocationTypes {
  PHONE = 'phone',
  EMAIL = 'email',
  WHATSAPP = 'whatsapp',
}

export type ProviderAuthParams =
  | TelnyxAuthParams
  | TwilioAuthParams
  | VonageAuthParams
  | BandwidthAuthParams
  | MailgunAuthParams
  | SinchAuthParams;

export type SinchAuthParams = {
  /* sinch app id */
  app_id: string;
  /* sinch project id */
  project_id: string;
  /* sinch region */
  region: string;
  /* sinch username */
  username: string;
  /* sinch password */
  password: string;
  /* sinch webhook secret */
  webhook_secret: string;
};

export type TelnyxAuthParams = {
  /* telnyx api key */
  api_key: string;
  /* telnyx api secret */
  public_key: string;
};

export type TwilioAuthParams = {
  /* twilio account sid */
  account_sid: string;
  /* twilio auth token */
  auth_token: string;
};

export type VonageAuthParams = {
  /* vonage api key */
  application_id: string;
  /* vonage api secret */
  private_key: string;
};

export type BandwidthAuthParams = {
  /* bandwidth api key */
  username: string;
  /* bandwidth api secret */
  user_password: string;
  /* bandwidth account id */
  account_id: string;
  /* bandwidth application id */
  application_id: string;
  /* bandwidth sub account number */
  sub_account_number: string;
  /* bandwidth location number */
  location_number: string;
  /* bandwidth callback id */
  callback_id: string;
  /* bandwidth callback password */
  callback_password: string;
};

export type MailgunAuthParams = {
  /* mailgun base domain */
  base_domain: string;
  /* mailgun api key */
  api_key: string;
  /* mailgun webhook signing key */
  webhook_signing_key: string;
};

export type LocationSettings = {
  /* after how long to close a conversation */
  auto_close_conversation_after?: string | null;
  /* how to configure link tracking for messages from this location */
  link_tracking?: LocationLinkTrackingSettings;
};

export type LocationLinkTrackingSettings = {
  /* whether to enable link tracking */
  disabled: boolean;
  /* what domain to send from */
  default_domain_id: string | null;
};

export enum LocationActionTypes {
  GET_LOCATIONS = 'GET_LOCATIONS',
  ADD_LOCATION = 'ADD_LOCATION',
  ARCHIVE_LOCATION = 'ARCHIVE_LOCATION',
  SET_CURRENT = 'SET_CURRENT',
  CLEAR_CURRENT = 'CLEAR_CURRENT',
  UPDATE_LOCATION = 'UPDATE_LOCATION',
  CLEAR_LOCATIONS = 'CLEAR_LOCATIONS',
  GET_ALL_LOCATIONS = 'GET_ALL_LOCATIONS',
  DISABLE_LOCATION = 'DISABLE_LOCATION',
  ENABLE_LOCATION = 'ENABLE_LOCATION',
  UPDATE_FILTER_PARAMS = 'UPDATE_FILTER_PARAMS',
  SEARCH_LOCATIONS = 'SEARCH_LOCATIONS',
}

export type LocationActions =
  | { type: LocationActionTypes.GET_LOCATIONS; payload: Array<Location> }
  | { type: LocationActionTypes.ADD_LOCATION; payload: Partial<Location> }
  | { type: LocationActionTypes.ARCHIVE_LOCATION; payload: Location }
  | { type: LocationActionTypes.SET_CURRENT; payload: Location | null }
  | { type: LocationActionTypes.CLEAR_CURRENT }
  | { type: LocationActionTypes.UPDATE_LOCATION; payload: Location }
  | { type: LocationActionTypes.CLEAR_LOCATIONS }
  | { type: LocationActionTypes.GET_ALL_LOCATIONS; payload: Array<Location> }
  | { type: LocationActionTypes.DISABLE_LOCATION; payload: Location }
  | { type: LocationActionTypes.ENABLE_LOCATION; payload: Location }
  | { type: LocationActionTypes.UPDATE_FILTER_PARAMS; payload: SearchFilters }
  | {
      type: LocationActionTypes.SEARCH_LOCATIONS;
      payload: { data: Array<Location>; total: number };
    };
