import { debounce } from 'lodash';
import { useEffect, useState } from 'react';

import { prepareFilters } from '@/pages/data/utils/prepareFilters';
import { getGroupsV2 } from '@/shared/api/contacts/groups';
import { getUploadsV2 } from '@/shared/api/contacts/uploads';
import { searchContacts } from '@/shared/api/contacts/v2';
import { getTagsV2 } from '@/shared/api/tags';
import { Contact } from '@/shared/types';
import { SearchFilters } from '@/shared/types/contacts';
import { Group } from '@/shared/types/contacts/groups';
import { Tag } from '@/shared/types/tags';
import { Flex } from '@/shared/ui';
import TabbedCombobox from '@/shared/v2/components/tabbedCombobox/TabbedCombobox';

type Tab = 'contacts' | 'tags' | 'uploads' | 'segments';
type AudienceItem = {
  data: Contact | Tag | Group;
  type: 'contact' | 'tag' | 'segment';
};
type AudienceQuickFilterProps = {
  onAudienceItemsChanged: (items: AudienceItem[]) => void;
};

const AudienceQuickFilter = ({
  onAudienceItemsChanged,
}: AudienceQuickFilterProps): JSX.Element => {
  const [isLoading, setLoading] = useState(false);
  const [selectedItems, setSelectedValues] = useState<{ id: string; value: string }[]>(
    []
  );

  const [contacts, setContacts] = useState<Contact[]>([]);
  const [totalContacts, setTotalContacts] = useState<number>(0);
  const [contactsOffset, setContactsOffset] = useState(0);

  const [tags, setTags] = useState<Tag[]>([]);
  const [totalTags, setTotalTags] = useState<number>(0);
  const [tagsOffset, setTagsOffset] = useState(0);

  const [uploadLists, setUploadLists] = useState<Tag[]>([]);
  const [totalUploadLists, setTotalUploadLists] = useState<number>(0);
  const [uploadListsOffset, setUploadListsOffset] = useState(0);

  const [segments, setSegments] = useState<Group[]>([]);
  const [totalSegments, setTotalSegments] = useState<number>(0);
  const [segmentsOffset, setSegmentsOffset] = useState(0);

  useEffect(() => {
    const initialSearchFilter = {
      filter: [],
      searchFilter: [],
      sort: [],
      limit: 200,
      offset: 0,
    };

    const fetchInitialData = async () => {
      const { data: contactsData, total: totalContacts } =
        await getContacts(initialSearchFilter);
      const { data: tagsData, total: totalTags } = await getTags(initialSearchFilter);
      const { data: uploadListData, total: totalUploadLists } =
        await getUploadLists(initialSearchFilter);
      const { data: segmentsData, total: totalSegments } =
        await getSegments(initialSearchFilter);
      const filteredContacts = contactsData.filter(
        (contact) => contact.name != '' && contact.name != null
      );
      setContacts(filteredContacts);
      setTotalContacts(totalContacts);

      setTags(tagsData);
      setTotalTags(totalTags);

      setUploadLists(uploadListData);
      setTotalUploadLists(totalUploadLists);

      setSegments(segmentsData);
      setTotalSegments(totalSegments);
    };
    fetchInitialData();
  }, []);

  // make data requests for the new data
  const onSearch = async (searchInput: string) => {
    const baseSearchFilter = { filter: [], sort: [], limit: 200, offset: 0 };
    const { data: contactsData } = await getContacts({
      ...baseSearchFilter,
      searchFilter: [
        {
          column: 'name',
          comparison: 'ilike',
          resource: 'contact',
          value: `%${searchInput}%`,
        },
      ],
    });
    const { data: tagsData } = await getTags({
      ...baseSearchFilter,
      searchFilter: [
        {
          column: 'name',
          comparison: 'ilike',
          resource: 'tag',
          value: `%${searchInput}%`,
        },
      ],
    });
    const { data: uploadListData } = await getUploadLists({
      ...baseSearchFilter,
      searchFilter: [
        ...defaultUploadListFilter,
        {
          column: 'name',
          comparison: 'ilike',
          resource: 'list',
          value: `%${searchInput}%`,
        },
      ],
    });
    const { data: segmentsData } = await getSegments({
      ...baseSearchFilter,
      searchFilter: [
        {
          column: 'name',
          comparison: 'ilike',
          resource: 'segment',
          value: `%${searchInput}%`,
        },
      ],
    });
    setContacts(contactsData);
    setTags(tagsData);
    setUploadLists(uploadListData);
    setSegments(segmentsData);
  };

  const fetchData = async (tab: Tab, searchInput: string) => {
    const baseSearchFilter = {
      filter: [],
      searchFilter: [
        {
          column: 'name',
          comparison: 'ilike',
          resource: convertTabToResource(tab),
          value: `%${searchInput}%`,
        },
      ],
      sort: [],
      limit: 200,
    };
    if (isLoading) {
      return;
    }
    switch (tab) {
      case 'contacts': {
        const { data: contactsData, total: totalContacts } = await getContacts({
          ...baseSearchFilter,
          offset: contactsOffset + 100,
        });
        updateStateAfterScrollLoad(tab, contactsData, totalContacts);
        break;
      }
      case 'tags': {
        const { data: tagsData, total: totalTags } = await getTags({
          ...baseSearchFilter,
          offset: tagsOffset + 100,
        });
        updateStateAfterScrollLoad(tab, tagsData, totalTags);
        break;
      }
      case 'uploads': {
        const { data: uploadListsData, total: totalUploadLists } = await getUploadLists({
          ...baseSearchFilter,
          offset: uploadListsOffset + 100,
        });
        updateStateAfterScrollLoad(tab, uploadListsData, totalUploadLists);
        break;
      }
      case 'segments': {
        const { data: segmentsData, total: totalSegments } = await getSegments({
          ...baseSearchFilter,
          offset: segmentsOffset + 100,
        });
        updateStateAfterScrollLoad(tab, segmentsData, totalSegments);
        break;
      }
    }
  };

  const updateStateAfterScrollLoad = (
    tab: Tab,
    data: Contact[] | Tag[] | Group[],
    totalCount: number
  ) => {
    switch (tab) {
      case 'contacts':
        setLoading(true);
        setTotalContacts(totalCount);
        setContacts((prev) => [...prev, ...(data as Contact[])]);
        setContactsOffset((prev) => prev + 100);
        setLoading(false);
        break;
      case 'tags':
        setLoading(true);
        setTotalTags(totalCount);
        setTags((prev) => [...prev, ...(data as Tag[])]);
        setTagsOffset((prev) => prev + 100);
        setLoading(false);
        break;
      case 'uploads':
        setLoading(true);
        setTotalUploadLists(totalCount);
        setUploadLists((prev) => [...prev, ...(data as Tag[])]);
        setUploadListsOffset((prev) => prev + 100);
        setLoading(false);
        break;
      case 'segments':
        setLoading(true);
        setTotalSegments(totalCount);
        setSegments((prev) => [...prev, ...(data as Group[])]);
        setSegmentsOffset((prev) => prev + 100);
        setLoading(false);
        break;
    }
  };

  return (
    <Flex
      css={{
        width: '1050px',
      }}
    >
      <TabbedCombobox
        defaultTab="contacts"
        onEndReached={(tab, searchInput) => {
          fetchData(tab as Tab, searchInput);
        }}
        selectedItems={selectedItems}
        setSelectedItems={(selectedItems) => {
          const selectedContacts = findItems(contacts, selectedItems);
          const selectedTags = findItems(tags, selectedItems);
          const selectedSegments = findItems(segments, selectedItems);
          onAudienceItemsChanged([
            ...selectedContacts.map(
              (item) => ({ data: item, type: 'contact' }) as AudienceItem
            ),
            ...selectedTags.map((item) => ({ data: item, type: 'tag' }) as AudienceItem),
            ...selectedSegments.map(
              (item) => ({ data: item, type: 'segment' }) as AudienceItem
            ),
          ]);
          setSelectedValues(selectedItems);
        }}
        onSearch={debounce(onSearch, 1000)}
      >
        <Flex
          direction="column"
          css={{
            border: '1px solid #0134DB72 ',
            borderRadius: '3px',
          }}
        >
          <TabbedCombobox.ResultsList>
            {selectedItems.map((value) => (
              <TabbedCombobox.ResultItem key={value.id} value={value.value} />
            ))}
          </TabbedCombobox.ResultsList>
          <TabbedCombobox.Input placeholder="Search for audience" />
        </Flex>
        <TabbedCombobox.Popover>
          <TabbedCombobox.Tabs>
            <TabbedCombobox.TabsList aria-label="Search for audience">
              <TabbedCombobox.TabsTrigger value="contacts">
                Contacts
              </TabbedCombobox.TabsTrigger>
              <TabbedCombobox.TabsTrigger value="uploads">
                Uploads
              </TabbedCombobox.TabsTrigger>
              <TabbedCombobox.TabsTrigger value="tags">Tags</TabbedCombobox.TabsTrigger>
              <TabbedCombobox.TabsTrigger value="segments">
                Segments
              </TabbedCombobox.TabsTrigger>
            </TabbedCombobox.TabsList>
            <TabbedCombobox.TabsContent
              value="contacts"
              totalItems={totalContacts}
              items={contacts}
            />
            <TabbedCombobox.TabsContent
              value="tags"
              items={tags}
              totalItems={totalTags}
            />
            <TabbedCombobox.TabsContent
              value="uploads"
              items={uploadLists}
              totalItems={totalUploadLists}
            />
            <TabbedCombobox.TabsContent
              value="segments"
              items={segments}
              totalItems={totalSegments}
            />
          </TabbedCombobox.Tabs>
        </TabbedCombobox.Popover>
      </TabbedCombobox>
    </Flex>
  );
};

async function getContacts(params: SearchFilters) {
  const filters = prepareFilters(params);
  return await searchContacts(
    filters.filter,
    filters.sort,
    filters.limit,
    filters.offset
  );
}

async function getTags(params: SearchFilters) {
  const filters = prepareFilters(params);
  return await getTagsV2(filters);
}

async function getUploadLists(params: SearchFilters) {
  const filters = prepareFilters(params);
  return await getUploadsV2(filters);
}

async function getSegments(params: SearchFilters) {
  const filters = prepareFilters(params);
  return await getGroupsV2(filters, undefined);
}

const defaultUploadListFilter = [
  { column: 'type', comparison: '==', resource: 'list', value: 'upload' },
  { column: 'state', comparison: '!=', resource: 'list', value: 'deleted' },
];

function findItems(
  items: (Contact | Tag | Group)[],
  selectedItems: { id: string; value: string }[]
): (Contact | Tag | Group)[] {
  const selectedIds = selectedItems.map((item) => item.id);
  return items.filter((item) => selectedIds.includes(item.id));
}

function convertTabToResource(tab: Tab) {
  switch (tab) {
    case 'contacts':
      return 'contact';
    case 'uploads':
      return 'list';
    case 'tags':
      return 'tag';
    case 'segments':
      return 'segment';
  }
}
export default AudienceQuickFilter;
