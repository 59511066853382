/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import React, { useEffect, useRef, useState } from 'react';
import { DatePicker } from 'react-dayjs-picker';
import { HiChevronDown, HiPencil, HiX } from 'react-icons/hi';

import { convertTime24to12 } from '@/campaigns/context/utils';
import { useLocations } from '@/pages/settings/organization/locations/context/LocationContext';
import { MessageEditorV2 } from '@/shared/components/editor/v2';
import { TimePicker } from '@/shared/components/timepicker/TimePicker';
import { TimezonePicker } from '@/shared/components/timezonepicker/TimezonePicker';
import { Location } from '@/shared/types/locations';
import {
  Box,
  Button,
  Dialog,
  DialogClose,
  DialogCloseIcon,
  DialogContent,
  DialogFooter,
  DialogOverlay,
  DialogPortal,
  DialogTitle,
  DialogTrigger,
  Fieldset,
  IconButton,
  Label,
  Tooltip,
  TooltipArrow,
  TooltipContent,
  TooltipTrigger,
  VStack,
} from '@/shared/ui';

import { useConversation } from '../context/ConversationContext';
import { useScheduledMessages } from './context/ScheduledMessagesContext';

type UpdateScheduledMessageProps = {
  id: number;
  location_id: string;
  schedule: string;
  message: {
    body: string | null;
    attachment_urls: Array<string> | null;
  };
  timezone: string;
  buttonVariant: 'outline' | 'ghost';
  conversationId?: string;
  isInbox?: boolean;
  isOpen: boolean;
  onOpen: () => void;
  onClose: () => void;
};

dayjs.extend(utc);
dayjs.extend(timezone);

export const UpdateScheduledMessageDialog = ({
  isOpen,
  onOpen,
  onClose,
  ...props
}: UpdateScheduledMessageProps) => {
  const { locationsState } = useLocations();
  const { locations } = locationsState;

  const scheduled = useScheduledMessages();
  const { editOneScheduledMessage } = scheduled;

  const conversationContext = useConversation();
  const { updateOneScheduledMessage } = conversationContext;

  // message text
  const [value, setValue] = useState('');

  // message attachments
  const [attachments, setAttachments] = useState<{
    attachment_urls: Array<string>;
  }>({
    attachment_urls: props.message.attachment_urls || [],
  });

  const [attachmentLoading, setAttachmentLoading] = useState<boolean>(false);

  const [date, setDate] = useState<string>(dayjs(props.schedule).format('MM/DD/YYYY'));
  const [location, setLocation] = useState<Location | null | object>({});
  const [timezone, setTimezone] = useState(props.timezone);
  const [time, setTime] = useState(
    dayjs(props.schedule)
      .tz(timezone || props.timezone)
      .format('hh:mm A')
  );

  const [scheduleParams, setScheduleParams] = useState({
    day: '',
    month: '',
    year: '',
    hour: '',
    minute: '',
    timezone: '',
  });

  useEffect(() => {
    const scheduled_message_location = locations.find(
      (location: Location) => location.id === props.location_id
    );
    if (scheduled_message_location) {
      setLocation(scheduled_message_location);
    }
  }, [locations, props.location_id]);

  useEffect(() => {
    setValue(props.message.body || '');

    setAttachments({
      attachment_urls: props.message.attachment_urls || [],
    });

    setDate(dayjs(props.schedule).format('MM/DD/YYYY'));
    setTime(
      dayjs(props.schedule)
        .tz(timezone || props.timezone)
        .format('hh:mm A')
    );
  }, [props.message, props.schedule]);

  useEffect(() => {
    if (time) {
      const day = date?.split('/')[1] || '';
      const month = date?.split('/')[0] || '';
      const year = date?.split('/')[2] || '';
      let hour = time?.split(':')[0] || '';
      const minute = time?.split(':')[1]?.split(' ')[0] || '';
      const ampm = time?.split(' ')[1] || '';

      if (ampm === 'PM') {
        if (hour === '12') {
          hour = '12';
        } else {
          hour = (Number(hour) + 12).toString();
        }
      } else {
        if (hour === '12') {
          hour = '00';
        }
      }

      setScheduleParams({
        day,
        month,
        year,
        hour,
        minute,
        timezone: timezone,
      });
    }
  }, [time, date, location, timezone]);

  const [isDatePickerOpen, setDatePickerOpen] = useState(false);

  const dateSelect = (e: any) => {
    const date = dayjs(e.$d).format('MM/DD/YYYY');
    setDate(date);
  };

  const updateScheduledMessage = () => {
    if (props.isInbox) {
      updateOneScheduledMessage(props.id, {
        message: {
          body: value,
          attachment_urls: attachments.attachment_urls,
        },
        scheduleParams,
      });
    } else {
      editOneScheduledMessage(
        props.id,
        {
          message: {
            body: value,
            attachment_urls: attachments.attachment_urls,
          },
          scheduleParams,
        },
        props.conversationId || ''
      );
    }
    handleClose();
  };

  const handleClose = () => {
    setValue(props.message.body || '');
    setAttachments({
      attachment_urls: props.message.attachment_urls || [],
    });
    setTime(
      dayjs(props.schedule.substring(0, 19))
        .tz(timezone || props.timezone)
        .format('hh:mm A')
    );
    setDate(dayjs(props.schedule).format('MM/DD/YYYY'));
    onClose();
  };

  const textareaRef = useRef<HTMLTextAreaElement>(null);

  return (
    <Dialog open={isOpen} modal={false}>
      <DialogTrigger asChild>
        <Tooltip>
          <TooltipTrigger asChild>
            <IconButton
              size={props.buttonVariant === 'ghost' ? 1 : 2}
              onClick={onOpen}
              variant={props.buttonVariant}
            >
              <HiPencil />
            </IconButton>
          </TooltipTrigger>
          <TooltipContent>
            {'Edit Scheduled Message'}
            <TooltipArrow />
          </TooltipContent>
        </Tooltip>
      </DialogTrigger>
      <DialogPortal>
        <DialogOverlay as="div" css={{ pointerEvents: 'auto', zIndex: 99999 }}>
          <DialogContent css={{ pb: 10 }}>
            <VStack gap={1}>
              <DialogTitle css={{ fontSize: 20, fontWeight: 700, marginBottom: 16 }}>
                Edit Scheduled Message
              </DialogTitle>
              <Fieldset>
                <Label htmlFor="date">Edit Timezone</Label>
                <Box>
                  <TimezonePicker timezone={timezone} setTimezone={setTimezone} />
                </Box>
              </Fieldset>
              <Fieldset>
                <Label htmlFor="date">Edit Date</Label>
                <Box css={{ position: 'relative' }}>
                  <HiChevronDown
                    style={{
                      position: 'absolute',
                      top: '50%',
                      transform: 'translateY(-50%)',
                      right: '10px',
                      pointerEvents: 'none',
                      fontSize: '16px',
                    }}
                  />
                  <DatePicker
                    isOpen={isDatePickerOpen}
                    setIsOpen={setDatePickerOpen}
                    zIndex={999999999999999}
                    value={dayjs(date).format('ddd MMM D')}
                    markToday={true}
                    format="MMM-dd-yyyy"
                    disableBeforeToday={true}
                    inputStyle={{
                      padding: '2px 10px',
                      width: '450px',
                      height: '35px',
                      border: '1px solid #D7DBDF',
                      borderRadius: '4px',
                      fontSize: '13px',
                      cursor: 'pointer',
                      textAlign: 'left',
                      pointerEvents: 'all',
                      backgroundColor: 'transparent',
                      color: '#000000',
                      outlineColor: '#3E54CF',
                    }}
                    colors={{
                      highlighted: 'hsl(0 0% 90.9%)',
                      disabled: 'hsl(0 0% 78.0%',
                      default: '',
                      active: '',
                    }}
                    style={{
                      pointerEvents: 'auto',
                    }}
                    onSelect={dateSelect}
                    popoverPositions={['bottom']}
                    closeOnSelect={true}
                  />
                </Box>
              </Fieldset>
              <Fieldset>
                <Label htmlFor="time">Edit Time</Label>
                <TimePicker
                  setTime={setTime}
                  time={
                    convertTime24to12(
                      `${scheduleParams?.hour}:${scheduleParams?.minute}`
                    ) || time
                  }
                  currentDate={date}
                  timezone={
                    props.timezone || Intl.DateTimeFormat().resolvedOptions().timeZone
                  }
                  variant="large"
                />
              </Fieldset>
              <Fieldset>
                <Label>Edit Message</Label>
                <MessageEditorV2
                  message={value}
                  setMessage={setValue}
                  attachments={attachments}
                  setAttachments={setAttachments}
                  enableAttachments={true}
                  attachmentLoading={attachmentLoading}
                  setAttachmentLoading={setAttachmentLoading}
                  isForm={true}
                  textareaRef={textareaRef}
                  showAddTemplate={true}
                  showAddSignature={true}
                  showAddAttachment={true}
                  showAddVariable={true}
                  showAddEmoji={true}
                  showCharacterCount={true}
                  showAddTranslate={true}
                  sendMessageFromTranslationDialog={false}
                />
              </Fieldset>
            </VStack>
            <DialogFooter justify="end">
              <Button variant="gray" onClick={handleClose} css={{ mr: 5 }}>
                Cancel
              </Button>
              <Button onClick={updateScheduledMessage}>Schedule Message</Button>
            </DialogFooter>
            <DialogClose>
              <DialogCloseIcon onClick={handleClose} size="2">
                <HiX size="15px" style={{ color: 'white' }} />
              </DialogCloseIcon>
            </DialogClose>
          </DialogContent>
        </DialogOverlay>
      </DialogPortal>
    </Dialog>
  );
};
