import { Formik } from 'formik';
import React, { useState } from 'react';
import { HiX } from 'react-icons/hi';
import * as Yup from 'yup';

import { useAuth } from '@/auth/context/AuthProvider';
import { formatContactName } from '@/pages/contacts/utils';
import { useLocations } from '@/pages/settings/organization/locations/context/LocationContext';
import {
  FormFieldWrapper,
  helpers,
  MultiSelectCombobox,
  PhoneNumberInput,
  TextInput,
} from '@/shared/components/forms';
import { useDisclosure } from '@/shared/hooks/useDisclosure';
import { Contact } from '@/shared/types/contacts';
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogClose,
  DialogCloseIcon,
  DialogContent,
  DialogFooter,
  DialogOverlay,
  DialogPortal,
  DialogTitle,
  DialogTrigger,
  Flex,
  Text,
  VStack,
} from '@/shared/ui';
import { isValidPhoneNumber, toE164 } from '@/shared/utils/validations/validations';

type CreateContactProps = {
  /**
   * The dialog button
   */
  children?: React.ReactNode;
  /**
   * The function to create a contact
   */
  handleCreateContact: (params: Partial<Contact>) => void;
};

export function CreateContact(props: CreateContactProps): JSX.Element {
  const { children, handleCreateContact } = props;

  const auth = useAuth();

  const { isOpen, onOpen, onClose } = useDisclosure();

  const location = useLocations();
  const { locations } = location.locationsState;

  const [checked, setChecked] = useState(true);

  return (
    <Dialog open={isOpen}>
      <DialogTrigger asChild>
        <Box onClick={() => onOpen()}>{children}</Box>
      </DialogTrigger>
      <DialogPortal>
        <DialogOverlay css={{ zIndex: 999999 }} />
        <DialogContent css={{ zIndex: 999999 }}>
          <DialogTitle css={{ fontSize: 20, fontWeight: 700, marginBottom: 16 }}>
            Create Contact
          </DialogTitle>
          <Formik
            initialValues={{
              name: '',
              phone: '',
              email: '',
              locations: locations.map((location) => location.id),
            }}
            validationSchema={Yup.object({
              name: Yup.string(),
              phone: Yup.string()
                .test({
                  name: 'isValidPhoneNumber',
                  message: 'Please enter a valid number',
                  test: (value) => {
                    if (value) {
                      return isValidPhoneNumber(value);
                    } else {
                      return true;
                    }
                  },
                })
                .test('phone', 'Required', function (value) {
                  const { email } = this.parent;
                  return value || email
                    ? true
                    : this.createError({ message: 'Required' });
                }),
              email: Yup.string()
                .email('Invalid email')
                .test('email', 'Required', function (value) {
                  const { phone } = this.parent;
                  return value || phone
                    ? true
                    : this.createError({ message: 'Required' });
                }),
              locations: Yup.array().test(
                'len',
                'Required',
                (arr) => Array.isArray(arr) && arr.length > 0
              ),
            })}
            onSubmit={async (values) => {
              const params = {
                name: formatContactName(values.name),
                phone: toE164(values.phone),
                email: values.email,
                organization_id: auth.organizationId,
                locations:
                  locations.length > 1
                    ? values.locations
                    : checked
                      ? [locations[0].id]
                      : [],
              };
              try {
                handleCreateContact(params);
                onClose();
              } catch (e) {
                console.log(e);
              }
            }}
          >
            {(formik) => (
              <form data-testid="create-contact-form" onSubmit={formik.handleSubmit}>
                <VStack gap={1}>
                  <FormFieldWrapper
                    label="Full Name"
                    tooltip="Contact's Full Name"
                    name="name"
                  >
                    <TextInput placeholder="Jane Doe" />
                  </FormFieldWrapper>
                  <FormFieldWrapper
                    label="Mobile Phone Number"
                    tooltip="Contact's Mobile Phone Number"
                    name="phone"
                  >
                    <PhoneNumberInput placeholder="(000) 000-0000" />
                  </FormFieldWrapper>
                  <FormFieldWrapper
                    label="Email Address"
                    tooltip="Contact's Email Address"
                    name="email"
                  >
                    <TextInput placeholder="jane@example.com" />
                  </FormFieldWrapper>
                  {locations.length > 1 ? (
                    <FormFieldWrapper
                      label="Opt-in"
                      tooltip="Can receive automated messages?"
                      name="locations"
                    >
                      <MultiSelectCombobox
                        placeholder={helpers.displaySelectedItems}
                        selectAll={true}
                        isDropdown={true}
                        options={locations.map((location) => ({
                          type: location.name as string,
                          value: location.id as string,
                        }))}
                      />
                    </FormFieldWrapper>
                  ) : (
                    <FormFieldWrapper
                      label="Opt-in"
                      tooltip="Can receive automated messages?"
                      name="location"
                    >
                      <Flex align="center">
                        <Checkbox
                          checked={checked}
                          onCheckedChange={(e: boolean) => setChecked(e)}
                          css={{ mr: 5 }}
                        />
                        <Text>Opt-in</Text>
                      </Flex>
                    </FormFieldWrapper>
                  )}
                  <DialogFooter justify="end" css={{ mt: 0, pt: 10 }}>
                    <DialogClose asChild>
                      <Button
                        aria-label="Close"
                        variant="gray"
                        css={{ mr: 5 }}
                        type="button"
                        onClick={onClose}
                      >
                        Cancel
                      </Button>
                    </DialogClose>
                    <DialogClose asChild type="submit">
                      <Button aria-label="Close">Save Contact</Button>
                    </DialogClose>
                  </DialogFooter>
                </VStack>
              </form>
            )}
          </Formik>
          <DialogCloseIcon onClick={onClose} size="2">
            <HiX size="15px" style={{ color: 'white' }} />
          </DialogCloseIcon>
        </DialogContent>
      </DialogPortal>
    </Dialog>
  );
}
