import { SearchFilters } from '@/shared/types/contacts';
import { Domain } from '@/shared/types/domains';

import { DomainsActionType } from './types';

type DomainsAction =
  | {
      type: DomainsActionType.GET_DOMAINS;
      payload: { data: Array<Domain>; total: number };
    }
  | { type: DomainsActionType.GET_ACTIVE_DOMAINS; payload: Array<Domain> }
  | { type: DomainsActionType.GET_DOMAIN; payload: Domain }
  | { type: DomainsActionType.ADD_DOMAIN; payload: Domain }
  | { type: DomainsActionType.BUY_DOMAIN; payload: Domain }
  | { type: DomainsActionType.SET_CURRENT; payload: Domain }
  | { type: DomainsActionType.GET_DOMAIN_AVAILABILITY; payload: any }
  | { type: DomainsActionType.DELETE_DOMAIN; payload: string }
  | { type: DomainsActionType.SET_LOADING; payload: boolean }
  | { type: DomainsActionType.SET_LOADING_DOMAIN; payload: boolean }
  | { type: DomainsActionType.SET_ERROR; payload: any }
  | { type: DomainsActionType.UPDATE_FILTER_PARAMS; payload: SearchFilters };

export const DomainsReducer = (state: any, action: DomainsAction) => {
  switch (action.type) {
    case DomainsActionType.GET_DOMAINS:
      return {
        ...state,
        domains: action.payload.data,
        loading: false,
        totalCount: action.payload.total,
      };
    case DomainsActionType.GET_ACTIVE_DOMAINS:
      return {
        ...state,
        activeDomains: action.payload,
      };
    case DomainsActionType.GET_DOMAIN:
      return {
        ...state,
        current: action.payload,
      };
    case DomainsActionType.DELETE_DOMAIN:
      return {
        ...state,
        domains: state.domains.filter((domain: Domain) => {
          return domain.id !== action.payload;
        }),
        totalCount: state.totalCount - 1,
      };
    case DomainsActionType.ADD_DOMAIN:
      return {
        ...state,
        domains: [action.payload, ...state.domains],
      };
    case DomainsActionType.BUY_DOMAIN:
      return {
        ...state,
        domains: [action.payload, ...state.domains],
      };
    case DomainsActionType.SET_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    case DomainsActionType.SET_LOADING_DOMAIN:
      return {
        ...state,
        loadingDomain: action.payload,
      };
    case DomainsActionType.SET_CURRENT:
      return {
        ...state,
        current: action.payload,
      };
    case DomainsActionType.UPDATE_FILTER_PARAMS:
      return {
        ...state,
        filterParams: action.payload,
        loading: true,
      };
    default:
      return state;
  }
};
