import React, { useMemo } from 'react';

import { User } from '@/shared/types/users';
import { Avatar, Box } from '@/shared/ui';
import { initials } from '@/shared/utils/initials/initials';
import { styled } from '@/stitches.config';

import { useUsers } from '../users/context/UserContext';

type TeamMemberPreviewProps = {
  /** id of user to get user's data to show avatar ot initials */
  user_id: number;
};

export const TeamMemberPreview = ({ user_id }: TeamMemberPreviewProps) => {
  const {
    userState: { users },
  } = useUsers();

  const user = useMemo(() => {
    return users.filter((user: User) => user.id === user_id)[0];
  }, [user_id, users]);

  return (
    <StyledTeamMember>
      <StylesAvatar
        css={{ border: '1.5px solid $slate1' }}
        variant="purple"
        size="3"
        src={user?.attachment?.url}
        fallback={initials(user?.name || user?.email)}
      />
    </StyledTeamMember>
  );
};

const StyledTeamMember = styled(Box, {
  ml: '-3px',
});

const StylesAvatar = styled(Avatar, {
  border: '1.5px solid $slate1',
});
