/* eslint-disable react-hooks/exhaustive-deps */
import React, { Dispatch } from 'react';
import { createContext, useContext, useEffect, useReducer, useState } from 'react';
import { toast } from 'sonner';

import { useAuth } from '@/auth/context/AuthProvider';
import { prepareFilters } from '@/pages/data/utils/prepareFilters';
import * as LocationsAPI from '@/shared/api/locations';
import { SearchFilters } from '@/shared/types/contacts';
import {
  Location,
  LocationActions,
  LocationActionTypes,
  LocationStates,
} from '@/shared/types/locations';

import { LocationReducer } from './LocationReducer';

export type LocationsState = {
  /** Locations in state */
  locations: Array<Location>;
  /** Filtered Locations in state */
  filteredLocations: Array<Location>;
  /** All Locations */
  allLocations: Array<Location>;
  /** Loading state */
  loading: boolean;
  /** Current Location state*/
  current: Location | null;
  /** Filtered Locations state */
  filtered: Array<Location> | null;
  /** Error state */
  error: Error | null;
  totalCount: number;
  filterParams: SearchFilters;
};

export const PAGE_SIZE = 10;

export const initialLocationsState: LocationsState = {
  locations: [],
  filteredLocations: [],
  allLocations: [],
  current: null,
  filtered: null,
  error: null,
  loading: true,
  totalCount: 0,
  filterParams: {
    offset: 0,
    limit: PAGE_SIZE,
    sort: [
      {
        label: 'Updated At',
        column: 'updated_at',
        order: 'desc',
        resource: 'location',
        id: null,
      },
    ],
    filter: [],
    searchFilter: [],
    defaultFilters: [
      {
        column: 'state',
        comparison: '==',
        resource: 'location',
        value: 'enabled',
      },
    ],
    defaultFilter: {
      column: 'state',
      comparison: '!=',
      resource: 'location',
      value: 'archived',
    },
  },
};

export const LocationContext = createContext<{
  locationsState: LocationsState;
  getLocation: (id: string) => void;
  getUserLocations: (params: { user_id?: number }) => Promise<Location[] | undefined>;
  getAllOrganizationLocations: () => Promise<void>;
  addLocation: (location: Partial<Location>) => Promise<void>;
  updateLocation: (location: Location) => Promise<void>;
  disableLocation: (location: Location) => Promise<void>;
  setCurrent: (location: Location | null) => void;
  clearCurrent: () => void;
  isOpen: boolean;
  onOpen: () => void;
  onClose: () => void;
  // pipe fields for google
  name?: string;
  address?: string;
  google_place_id?: string;
  getLocationById: (id: string) => Location;
  searchLocations: (params: SearchFilters) => void;
  updateFilterParams: (params: SearchFilters) => void;
  enableLocation: (location: Location) => Promise<void>;
  archiveLocation: (location: Location) => Promise<void>;
}>({
  locationsState: initialLocationsState,
  getLocation: () => Promise.resolve(),
  addLocation: () => Promise.resolve(),
  setCurrent: () => Promise.resolve(),
  clearCurrent: () => Promise.resolve(),
  updateLocation: () => Promise.resolve(),
  disableLocation: () => Promise.resolve(),
  getUserLocations: () => Promise.resolve(undefined),
  getAllOrganizationLocations: () => Promise.resolve(),
  isOpen: false,
  onOpen: () => Promise.resolve(),
  onClose: () => Promise.resolve(),
  getLocationById: () => ({}) as Location,
  updateFilterParams: () => Promise.resolve(),
  searchLocations: () => Promise.resolve(),
  enableLocation: () => Promise.resolve(),
  archiveLocation: () => Promise.resolve(),
});

export const useLocations = () => useContext(LocationContext);

const LocationState = ({ children }: { children: React.ReactNode }) => {
  const [locationsState, dispatch]: [LocationsState, Dispatch<LocationActions>] =
    useReducer(LocationReducer, initialLocationsState);

  const auth = useAuth();

  // Fetch locations on mount
  useEffect(() => {
    if (auth.isAuthenticated && auth?.tokens && auth?.tokens?.user_id) {
      if (auth?.tokens?.user_id?.toString()) {
        getUserLocations({ user_id: auth?.tokens?.user_id });
      }
      getAllOrganizationLocations();
    }
  }, [
    auth.organizationId,
    auth.isAuthenticated,
    auth.onboardingStatus,
    auth?.tokens?.user_id,
  ]);

  useEffect(() => {
    if (auth.isAuthenticated && auth?.tokens && auth?.tokens?.user_id) {
      searchLocations(locationsState.filterParams);
    }
  }, [locationsState.filterParams]);

  const getLocation = async (id: string) => {
    try {
      const location = await LocationsAPI.getLocation(id);

      return location;
    } catch (err) {
      console.error(err);

      return Promise.reject(err);
    }
  };

  const getLocationById = (id: string) => {
    const location = locationsState.locations.find(
      (location: Location) => location.id === id
    );

    return location as Location;
  };

  const getUserLocations = async (params: {
    user_id?: number;
  }): Promise<Location[] | undefined> => {
    // convert params to query string and remove empty params
    const stringParams = { user_id: params.user_id ? params.user_id.toString() : '' };
    // concat all params into a query string
    const queryParams = new URLSearchParams(stringParams).toString();

    // if we don't have a user_id, don't make the request
    // because all of the locations will be returned
    if (queryParams.length === 0) {
      return;
    }

    try {
      const data = await LocationsAPI.fetchLocations(queryParams);

      dispatch({
        type: LocationActionTypes.GET_LOCATIONS,
        payload: data,
      });

      return data;
    } catch (err) {
      console.error(err);

      return Promise.reject(err);
    }
  };

  const getAllOrganizationLocations = async () => {
    try {
      const data = await LocationsAPI.fetchLocations();

      dispatch({
        type: LocationActionTypes.GET_ALL_LOCATIONS,
        payload: data,
      });

      return data;
    } catch (err) {
      console.error(err);

      return Promise.reject(err);
    }
  };

  const addLocation = async (location: Partial<Location>) => {
    try {
      const data = await LocationsAPI.createLocation(location);

      dispatch({
        type: LocationActionTypes.ADD_LOCATION,
        payload: data,
      });
      searchLocations(locationsState.filterParams);
      toast.success('Location added');
    } catch (err) {
      toast.error('Failed to add location');
      console.error(err);

      return Promise.reject(err);
    }
  };

  const updateLocation = async (location: Location) => {
    try {
      const data = await LocationsAPI.updateLocation(location);

      dispatch({
        type: LocationActionTypes.UPDATE_LOCATION,
        payload: data,
      });

      toast.success('Location updated');
    } catch (err) {
      toast.error('Location update failed');

      console.error(err);

      return Promise.reject(err);
    }
  };

  const disableLocation = async (location: Location) => {
    try {
      const data = await LocationsAPI.disableLocation(location);

      dispatch({
        type: LocationActionTypes.DISABLE_LOCATION,
        payload: data,
      });
      searchLocations(locationsState.filterParams);
      toast.success('Disabled location');
    } catch (err) {
      toast.error('Failed to disable location');

      console.error(err);

      return Promise.reject(err);
    }
  };

  const enableLocation = async (location: Location) => {
    try {
      const data = await LocationsAPI.updateLocation({
        ...location,
        state: LocationStates.ENABLED,
      });

      dispatch({
        type: LocationActionTypes.ENABLE_LOCATION,
        payload: data,
      });
      searchLocations(locationsState.filterParams);
      toast.success('Enabled location');
    } catch (err) {
      toast.error('Failed to enable location');

      console.error(err);

      return Promise.reject(err);
    }
  };

  const archiveLocation = async (location: Location) => {
    try {
      const data = await LocationsAPI.updateLocation({
        ...location,
        state: LocationStates.ARCHIVED,
      });

      dispatch({
        type: LocationActionTypes.ARCHIVE_LOCATION,
        payload: data,
      });
      searchLocations(locationsState.filterParams);
      toast.success('Archived location');
    } catch (err) {
      toast.error('Failed to archive location');

      console.error(err);

      return Promise.reject(err);
    }
  };

  const setCurrent = (location: Location | null) => {
    dispatch({ type: LocationActionTypes.SET_CURRENT, payload: location });
  };

  const clearCurrent = () => {
    dispatch({ type: LocationActionTypes.CLEAR_CURRENT });
  };

  const [isOpen, setIsOpen] = useState(false);

  const onOpen = () => {
    setIsOpen(true);
  };

  const onClose = () => {
    setIsOpen(false);
  };

  const searchLocations = async (params: SearchFilters) => {
    try {
      const data = await LocationsAPI.getLocationsV2(prepareFilters(params));

      dispatch({
        type: LocationActionTypes.SEARCH_LOCATIONS,
        payload: data,
      });
    } catch (err) {
      console.error(err);
    }
  };

  const updateFilterParams = async (params: SearchFilters) => {
    try {
      dispatch({
        type: LocationActionTypes.UPDATE_FILTER_PARAMS,
        payload: params,
      });
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <LocationContext.Provider
      value={{
        locationsState,
        getLocation,
        addLocation,
        setCurrent,
        clearCurrent,
        updateLocation,
        disableLocation,
        getUserLocations,
        getAllOrganizationLocations,
        isOpen,
        onOpen,
        onClose,
        getLocationById,
        searchLocations,
        updateFilterParams,
        enableLocation,
        archiveLocation,
      }}
    >
      {children}
    </LocationContext.Provider>
  );
};

export default LocationState;
