/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { HiCloudUpload, HiX } from 'react-icons/hi';

import { useUploads } from '@/pages/contacts/uploads/context/UploadContext';
import { useLocations } from '@/pages/settings/organization/locations/context/LocationContext';
import { MultiSelect } from '@/shared/components/MultiSelect';
import { useDisclosure } from '@/shared/hooks';
import {
  Box,
  Button,
  Dialog,
  DialogClose,
  DialogCloseIcon,
  DialogContent,
  DialogHeader,
  DialogOverlay,
  DialogPortal,
  DialogTrigger,
  Flex,
  Input,
  Label,
  VStack,
} from '@/shared/ui';

import { BulkOptInContacts } from './BulkOptInContacts';

type BulkOptInDialogProps = {
  open?: boolean;
  setOpen?: (value: boolean) => void;
  children?: React.ReactNode;
};

export const BulkOptInDialog = (props: BulkOptInDialogProps) => {
  const { children } = props;
  const { uploadName, setUploadName, setUploadLocations } = useUploads();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const locationsContext = useLocations();
  const { locationsState } = locationsContext;

  const locations = locationsState.allLocations || [];

  const [uploadNameLength, setUploadNameLength] = useState(0);
  const [selectedLocations, setSelectedLocations] = useState({
    locations: locations.map((location) => location.id) || [],
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setUploadName(e.target.value);
    setUploadNameLength(e.target.value.length);
  };

  const closeDialog = (setLocationsToDefault: boolean) => {
    onClose();
    props.setOpen ? props.setOpen(false) : null;
    setLocationsToDefault
      ? setSelectedLocations({
          locations: locations.map((location) => location.id) || [],
        })
      : null;
  };

  // if props.open is true open the dialog
  useEffect(() => {
    if (props.open !== undefined) {
      if (props.open) {
        onOpen();
      }
    }
  }, [props.open]);

  useEffect(() => {
    setUploadLocations(selectedLocations.locations);
  }, [selectedLocations.locations]);

  return (
    <>
      <Dialog open={isOpen} modal={false}>
        <DialogTrigger asChild>
          <Box onClick={() => onOpen()}>{children}</Box>
        </DialogTrigger>
        <DialogPortal>
          <DialogOverlay as="div">
            <DialogContent
              onEscapeKeyDown={() => closeDialog(true)}
              onPointerDownOutside={() => closeDialog(true)}
            >
              <DialogHeader title="Bulk Opt-In Contacts" />
              <VStack gap={6}>
                <VStack gap={2}>
                  <Box>
                    <Label htmlFor="name">
                      <Flex>
                        Name Upload{' '}
                        <Box css={{ color: 'red', ml: 5 }}>
                          {uploadNameLength > 28
                            ? '(max 30 characters)'
                            : '(name must be unique)'}
                        </Box>
                      </Flex>
                    </Label>
                    <Input
                      id="name"
                      name="name"
                      type="text"
                      value={uploadName}
                      maxLength={29}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        handleChange(e)
                      }
                    />
                  </Box>
                  <Box>
                    <Label htmlFor="locations">
                      <Flex>
                        Opt in to{' '}
                        <Box css={{ color: 'red', ml: 5 }}>
                          {selectedLocations.locations.length < 1
                            ? '(at least one location is required)'
                            : ''}
                        </Box>
                      </Flex>
                    </Label>
                    {locations.length > 0 && (
                      <MultiSelect
                        defaultPlaceholder="Channels"
                        defaultSelectedItems={selectedLocations.locations}
                        isDropdown={true}
                        options={locations.map((location: any) => ({
                          type: location?.name || '',
                          value: location.id,
                        }))}
                        parentSelectedItems={selectedLocations}
                        setParentSelectedItems={setSelectedLocations}
                        isCampaigns={true}
                      />
                    )}
                  </Box>
                </VStack>
                <BulkOptInContacts>
                  <Button
                    variant="gray"
                    size={4}
                    as="div"
                    onClick={() => closeDialog(false)}
                    css={{ width: '100%' }}
                  >
                    <HiCloudUpload size={16} /> Click to Upload a File
                  </Button>
                </BulkOptInContacts>
              </VStack>
              <DialogClose asChild>
                <DialogCloseIcon onClick={() => closeDialog(true)} size="2">
                  <HiX size="15px" style={{ color: 'white' }} />
                </DialogCloseIcon>
              </DialogClose>
            </DialogContent>
          </DialogOverlay>
        </DialogPortal>
      </Dialog>
    </>
  );
};
