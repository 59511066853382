import { Formik } from 'formik';
import { useState } from 'react';
import { HiX } from 'react-icons/hi';
import { useHistory } from 'react-router-dom';
import * as Yup from 'yup';

import { useAuth } from '@/pages/auth/context/AuthProvider';
import { useLocations } from '@/pages/settings/organization/locations/context/LocationContext';
import { useUserPreferences } from '@/pages/settings/user/preferences/context/PreferencesContext';
import { DomainSelect } from '@/shared/components/domainSelect/DomainSelect';
import {
  CheckboxInput,
  FormFieldWrapper,
  helpers,
  MultiSelectCombobox,
  TextInput,
} from '@/shared/components/forms';
import { FormSelect } from '@/shared/components/forms/Select';
import { useDisclosure } from '@/shared/hooks';
import { Location } from '@/shared/types/locations';
import {
  OnReAdd,
  OnResponse,
  Sequence,
  SequenceAccessLevel,
  SequenceStatus,
  SequenceType,
} from '@/shared/types/sequences';
import {
  Box,
  Button,
  Dialog,
  DialogCloseIcon,
  DialogContent,
  DialogFooter,
  DialogOverlay,
  DialogPortal,
  DialogTitle,
  DialogTrigger,
  Flex,
  HStack,
  Label,
} from '@/shared/ui';

import { useSequences } from '../context/SequenceContext';

type SequenceEditorProps = {
  sequence?: Sequence;
  isTemplateEditor?: boolean;
  children: React.ReactNode;
};

export const SequenceEditor = (props: SequenceEditorProps) => {
  const { sequence, children, isTemplateEditor } = props;
  const { isOpen, onOpen, onClose } = useDisclosure();

  const { preferences } = useUserPreferences();

  // close the dialog and reset the step
  const onDialogClose = () => {
    onClose();
  };

  const history = useHistory();

  const authContext = useAuth();
  const { isAdmin } = authContext;

  const sequenceContext = useSequences();
  const {
    createSequence,
    updateSequence,
    deleteSequence,
    createSequenceTemplate,
    deleteSequenceTemplate,
  } = sequenceContext;

  const locationContext = useLocations();
  const { locationsState } = locationContext;
  const { locations, allLocations } = locationsState;

  const [step, setStep] = useState(1);

  // delete sequence
  const handleDeleteSequence = () => {
    if (sequence && sequence.id) {
      // set the status 'deleted' and merge in the current sequence
      deleteSequence({
        ...sequence,
        status: SequenceStatus.DELETED,
      });
      // then navigate to the sequences page
      history.push('/sequences');
    }
    onClose();
  };

  // delete sequence template
  const handleDeleteSequenceTemplate = () => {
    if (sequence && sequence.id) {
      // set the status 'deleted' and merge in the current sequence
      deleteSequenceTemplate({
        ...sequence,
        status: SequenceStatus.DELETED,
      });
      // then navigate to the templates page
      history.push('/sequences/templates');
    }
    onClose();
  };

  const handleSubmit = async (values: Sequence) => {
    if (sequence && sequence.id) {
      // inside update Sequence editor
      updateSequence(values);
      setStep(1);
      onClose();
    } else {
      if (!isTemplateEditor) {
        // inside Create "Sequence" editor
        const sequence = await createSequence(values);
        if (sequence && sequence?.id && !isTemplateEditor) {
          history.push(`/sequences/${sequence?.id}/steps`);
        }
        setStep(1);
        onClose();
      } else {
        // inside Create "Template" editor
        const sequence = await createSequenceTemplate(values);
        if (sequence && sequence?.id) {
          history.push(`/sequences/templates/${sequence?.id}/steps`);
        }
        onClose();
        setStep(1);
      }
    }
  };

  const getLocations = () => {
    if (sequence?.locations) {
      return sequence?.locations?.map(
        (location) => (location as unknown as Location)?.id
      );
    } else if (locations.length === 1) {
      return locations?.map((location) => (location as unknown as Location)?.id);
    } else if (preferences?.inbox?.preferred_location_id) {
      return [preferences.inbox.preferred_location_id as string];
    } else {
      return [];
    }
  };

  return (
    <Dialog open={isOpen} modal={true}>
      <DialogTrigger onClick={onOpen}>{children}</DialogTrigger>
      <DialogPortal>
        <DialogOverlay as="div" />
        <DialogContent
          onEscapeKeyDown={onDialogClose}
          onPointerDownOutside={onDialogClose}
        >
          <DialogTitle css={{ fontSize: 20, fontWeight: 700, marginBottom: 16 }}>
            Sequence
          </DialogTitle>
          <Box css={{ overflow: 'auto', maxHeight: 'calc(100vh - 105px)' }}>
            <Formik
              initialValues={
                {
                  id: sequence?.id || null,
                  title: sequence?.title || '',
                  description: sequence?.description || '',
                  type: sequence?.type || SequenceType.SEQUENTIAL,
                  access_level:
                    sequence?.access_level || SequenceAccessLevel.ORGANIZATION,
                  status: isTemplateEditor
                    ? SequenceStatus.TEMPLATE
                    : SequenceStatus.ACTIVE,
                  locations: getLocations(),
                  settings: {
                    on_re_add: sequence?.settings?.on_re_add || OnReAdd.EXCLUDE,
                    on_response: sequence?.settings?.on_response || OnResponse.REMOVE,
                    max_add_per_time: sequence?.settings?.max_add_per_time
                      ? sequence?.settings?.max_add_per_time
                      : 1,
                    max_add_time: sequence?.settings?.max_add_time || SECOND_IN_A_DAY,
                    max_contact_sequence_runs:
                      sequence?.settings?.max_contact_sequence_runs || 5,
                    max_time: sequence?.settings?.max_time || null,
                    support_sms_quiet_hours: sequence?.settings
                      ?.support_sms_quiet_hours || {
                      enabled: true,
                      use_contact_timezone: true,
                    },
                    skip_sending_on_weekend: sequence?.settings
                      ?.skip_sending_on_weekend || {
                      enabled: true,
                      use_contact_timezone: true,
                    },
                    only_send_during_business_hours: sequence?.settings
                      ?.only_send_during_business_hours || {
                      enabled: true,
                      use_contact_timezone: true,
                    },
                    link_tracking: sequence?.settings?.link_tracking || {
                      disabled: false,
                      domain_id: null,
                    },
                  },
                } as unknown as Sequence
              }
              validationSchema={Yup.object({
                title: Yup.string().required('Required'),
                description: Yup.string(),
                access_level: Yup.string().required('Required'),
                status: Yup.string(),
                locations: Yup.array().when('access_level', {
                  is: (val: string) => val === SequenceAccessLevel.LOCATION,
                  then: (schema) =>
                    schema.min(1, 'At least 1 location is required').required('Required'),
                }),
                settings: Yup.object({
                  on_re_add: Yup.string().required('Required'),
                  on_response: Yup.string().required('Required'),
                  max_add_time: Yup.number().required('Required'),
                  max_add_per_time: Yup.number().required('Required'),
                  max_contact_sequence_runs: Yup.number().required('Required'),
                  support_sms_quiet_hours: Yup.object({
                    enabled: Yup.boolean().required('Required'),
                    use_contact_timezone: Yup.boolean().required('Required'),
                  }),
                  skip_sending_on_weekend: Yup.object({
                    enabled: Yup.boolean().required('Required'),
                    use_contact_timezone: Yup.boolean().required('Required'),
                  }),
                  only_send_during_business_hours: Yup.object({
                    enabled: Yup.boolean().required('Required'),
                    use_contact_timezone: Yup.boolean().required('Required'),
                  }),
                  link_tracking: Yup.object({
                    disabled: Yup.boolean().required('Required'),
                    domain_id: Yup.string().nullable(),
                  }),
                }),
              })}
              onSubmit={handleSubmit}
            >
              {(formik) => (
                <form onSubmit={formik.handleSubmit} data-testid="sequence-form">
                  {step === 1 && (
                    <Box>
                      <FormFieldWrapper
                        name="title"
                        tooltip="A Sequence title is required."
                        label="Title"
                      >
                        <TextInput name="title" placeholder="Sequence Title" />
                      </FormFieldWrapper>
                      <FormFieldWrapper
                        name="description"
                        tooltip="A Sequence description is optional."
                        label="Description"
                      >
                        <TextInput
                          name="description"
                          placeholder="Sequence Description"
                        />
                      </FormFieldWrapper>
                      <FormFieldWrapper
                        name="type"
                        tooltip="A Sequence type is required."
                        label="Sequence Type"
                      >
                        <FormSelect
                          placeholder="Sequence Types"
                          options={sequence_type_options}
                        />
                      </FormFieldWrapper>
                      <FormFieldWrapper
                        name="access_level"
                        tooltip="A Sequence access level is required."
                        label="Access Level"
                      >
                        <FormSelect
                          placeholder="Access Levels"
                          options={sequence_access_options}
                        />
                      </FormFieldWrapper>
                      {formik.values.access_level === SequenceAccessLevel.LOCATION && (
                        <FormFieldWrapper
                          name="locations"
                          label="Channels"
                          tooltip="Select at least one channel."
                        >
                          <MultiSelectCombobox
                            placeholder={helpers.displaySelectedItems}
                            selectAll={true}
                            isDropdown={true}
                            options={
                              isAdmin
                                ? allLocations.map((location: Location) => ({
                                    type: location?.name || '',
                                    value: location.id,
                                  }))
                                : locations.map((location: Location) => ({
                                    type: location?.name || '',
                                    value: location.id,
                                  }))
                            }
                          />
                        </FormFieldWrapper>
                      )}
                    </Box>
                  )}
                  {step === 2 && (
                    <Box>
                      <FormFieldWrapper
                        name="settings.on_response"
                        tooltip="What should happen when a contact responds to a sequence message?"
                        label="What happens when a contact responds a message?"
                      >
                        <FormSelect
                          placeholder="On Response"
                          options={sequence_response_handling}
                        />
                      </FormFieldWrapper>
                      <FormFieldWrapper
                        name="settings.on_re_add"
                        tooltip="What should happen when a contact is added to the exact same sequence again?"
                        label="What happens when a contact is added again?"
                      >
                        <FormSelect
                          placeholder="On Re-Add"
                          options={sequence_contact_handling}
                        />
                      </FormFieldWrapper>
                      <FormFieldWrapper
                        name="settings.max_contact_sequence_runs"
                        tooltip="How many times can a contact run through this sequence?"
                        label="Total number of times a contact can be in this Sequence?"
                      >
                        <TextInput
                          name="settings.max_contact_sequence_runs"
                          placeholder="Max Contact Sequence Runs"
                        />
                      </FormFieldWrapper>
                      <FormFieldWrapper
                        name="settings.max_add_per_time"
                        tooltip="How many many times can the same contact be added to this sequence in a day?"
                        label="Max number of times a contact can be added in a day?"
                      >
                        <TextInput
                          name="settings.max_add_per_time"
                          placeholder="Max Add Per Time"
                        />
                      </FormFieldWrapper>
                      <FormFieldWrapper
                        name="settings.support_sms_quiet_hours.enabled"
                        tooltip="Should this sequence support SMS quiet hours?"
                        label="Support SMS Quiet Hours?"
                      >
                        <CheckboxInput
                          name="settings.support_sms_quiet_hours.enabled"
                          ariaLabel="Support SMS Quiet Hours?"
                        />
                      </FormFieldWrapper>
                      <FormFieldWrapper
                        name="settings.skip_sending_on_weekend.enabled"
                        tooltip="Should this sequence skip sending on weekends?"
                        label="Skip Sending on Weekends?"
                      >
                        <CheckboxInput
                          name="settings.skip_sending_on_weekend.enabled"
                          ariaLabel="Skip Sending on Weekends?"
                        />
                      </FormFieldWrapper>
                      <FormFieldWrapper
                        name="settings.only_send_during_business_hours.enabled"
                        tooltip="Should this sequence only send during business hours?"
                        label="Only Send During Business Hours?"
                      >
                        <CheckboxInput
                          name="settings.only_send_during_business_hours.enabled"
                          ariaLabel="Only Send During Business Hours?"
                        />
                      </FormFieldWrapper>
                      <FormFieldWrapper
                        name="settings.link_tracking.disabled"
                        tooltip="You can disable link tracking for this sequence only."
                        label="Link Tracking"
                      >
                        <Flex>
                          <Label css={{ marginRight: 8, fontWeight: '400' }}>
                            Disable
                          </Label>
                          <CheckboxInput
                            name="settings.link_tracking.disabled"
                            ariaLabel="Disable"
                          />
                        </Flex>
                      </FormFieldWrapper>
                      <FormFieldWrapper
                        name="settings.link_tracking.domain_id"
                        tooltip="You can select a default domain for this sequence only."
                        label="Default Domain"
                      >
                        <DomainSelect
                          value={formik.values.settings?.link_tracking?.domain_id || ''}
                          onChange={(id: string) => {
                            formik.setFieldValue(
                              'settings.link_tracking.domain_id',
                              id || null
                            );
                          }}
                          hideLabel
                        />
                      </FormFieldWrapper>
                    </Box>
                  )}
                  {step === 1 && (
                    <DialogFooter justify="between">
                      <Box>
                        {sequence && sequence.id && (
                          <Button
                            variant="red"
                            onClick={
                              isTemplateEditor
                                ? handleDeleteSequenceTemplate
                                : handleDeleteSequence
                            }
                            type="button"
                          >
                            Delete {isTemplateEditor ? 'Template' : 'Sequence'}
                          </Button>
                        )}
                      </Box>
                      <HStack>
                        <Button variant="gray" onClick={onClose} type="button">
                          Cancel
                        </Button>
                        {/* "Next Step" button will only be activated when there's a non-empty title*/}
                        <Button
                          onClick={() => setStep(2)}
                          type="button"
                          disabled={
                            formik.values.title &&
                            formik.values.title.length > 0 &&
                            (formik.values.access_level !==
                              SequenceAccessLevel.LOCATION ||
                              (formik.values.access_level ===
                                SequenceAccessLevel.LOCATION &&
                                formik.values.locations &&
                                formik.values.locations.length >= 2))
                              ? false
                              : true
                          }
                        >
                          Next Step
                        </Button>
                      </HStack>
                    </DialogFooter>
                  )}
                  {step === 2 && (
                    <DialogFooter justify="between">
                      <Box>
                        {sequence && sequence.id && (
                          <Button
                            variant="red"
                            onClick={
                              isTemplateEditor
                                ? handleDeleteSequenceTemplate
                                : handleDeleteSequence
                            }
                            type="button"
                          >
                            Delete {isTemplateEditor ? 'Template' : 'Sequence'}
                          </Button>
                        )}
                      </Box>
                      <HStack>
                        <Button variant="gray" onClick={() => setStep(1)} type="button">
                          Back
                        </Button>
                        <Button type="submit">
                          Save {isTemplateEditor ? 'Template' : 'Sequence'}
                        </Button>
                      </HStack>
                    </DialogFooter>
                  )}
                </form>
              )}
            </Formik>
          </Box>
          <DialogCloseIcon onClick={onClose} size="2">
            <HiX size="15px" style={{ color: '#FFFFFF' }} />
          </DialogCloseIcon>
        </DialogContent>
      </DialogPortal>
    </Dialog>
  );
};

export const sequence_access_options = [
  {
    label: 'Private to me',
    value: SequenceAccessLevel.USER,
  },
  {
    label: 'Organization',
    value: SequenceAccessLevel.ORGANIZATION,
  },
  {
    label: 'Channels',
    value: SequenceAccessLevel.LOCATION,
  },
];

export const sequence_type_options = [
  {
    label: 'Sequential',
    value: SequenceType.SEQUENTIAL,
  },
  {
    label: 'Branched',
    value: SequenceType.BRANCHED,
  },
];

export const sequence_response_handling = [
  { value: OnResponse.CONTINUE, label: 'Continue processing sequence' },
  { value: OnResponse.REMOVE, label: 'Remove the contact from the sequence' },
];

export const sequence_contact_handling = [
  { value: OnReAdd.INCLUDE, label: 'Re-add contact to sequence' },
  { value: OnReAdd.EXCLUDE, label: 'Exclude contact from sequence' },
];

export const time_periods = [
  { value: 'minutes', label: 'Minute(s)' },
  { value: 'hours', label: 'Hour(s)' },
  { value: 'days', label: 'Day(s)' },
  { value: 'weeks', label: 'Week(s)' },
  { value: 'months', label: 'Month(s)' },
];

const SECOND_IN_A_DAY = 86400;
