import { Formik } from 'formik';
import React, { useCallback, useState } from 'react';
import { HiExclamation, HiShieldCheck, HiX, HiXCircle } from 'react-icons/hi';
import { useHistory } from 'react-router-dom';
import { toast } from 'sonner';
import * as Yup from 'yup';

import { FormFieldWrapper, TextInput } from '@/shared/components/forms';
import { Spinner } from '@/shared/components/Icons';
import { useDisclosure } from '@/shared/hooks';
import { Availability } from '@/shared/types/domains';
import {
  Box,
  Button,
  Dialog,
  DialogClose,
  DialogCloseIcon,
  DialogContent,
  DialogFooter,
  DialogOverlay,
  DialogPortal,
  DialogTitle,
  DialogTrigger,
  Flex,
  HStack,
  VStack,
} from '@/shared/ui';
import { styled } from '@/stitches.config';

import { useDomainsContext } from '../context/DomainsContext';

export const BuyDomain = () => {
  const { buyDomain, getDomainAvailability } = useDomainsContext();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const history = useHistory();

  const [checking, setChecking] = useState<boolean>(false);
  const [availability, setAvailability] = useState<Availability | null>(null);
  const [checkingName, setCheckingName] = useState<string>('');

  // handle buy domain
  const handleSubmit = useCallback(
    async (
      values: {
        name: string;
      },
      actions: any
    ) => {
      try {
        if (availability?.available) {
          const params = {
            name: values.name,
            price: availability?.price,
          };
          const data = await buyDomain(params);
          data && history.push(`/campaigns/domains/${data?.id}`);
        }
        actions.resetForm({
          values: {
            name: '',
          },
        });
        onClose();
      } catch (e) {
        toast.error(e?.response?.data?.errors?.[0]?.description as string, {
          duration: 5000,
        });
      }
    },
    [availability]
  );

  const handleCheck = useCallback(
    (name: string) => async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      e.preventDefault();
      setChecking(true);
      setCheckingName(name);
      try {
        const data = await getDomainAvailability(name);
        data && setAvailability(data);
      } catch (error) {
        setAvailability({
          available: false,
          error: error?.response?.data?.errors?.[0]?.description,
        });
      } finally {
        setChecking(false);
      }
    },
    []
  );

  const handleClose = useCallback(() => {
    onClose();
    setAvailability(null);
    setCheckingName('');
  }, []);

  return (
    <Dialog open={isOpen} onOpenChange={() => !isOpen}>
      <DialogTrigger asChild>
        <Button onClick={onOpen} data-intercom-target="buy-domain-button" variant="gray">
          Buy Domain
        </Button>
      </DialogTrigger>
      <DialogPortal>
        <DialogOverlay as="div">
          <DialogContent onEscapeKeyDown={handleClose} onPointerDownOutside={handleClose}>
            <DialogTitle css={{ fontSize: 20, fontWeight: 700 }}>Buy Domain</DialogTitle>
            <Formik
              initialValues={{
                name: '',
              }}
              validationSchema={Yup.object({
                name: Yup.string()
                  .test(
                    'len',
                    'Must be at least 3 characters',
                    (val) => val !== undefined && val.length > 2
                  )
                  .matches(
                    /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
                    'Enter correct domain name'
                  )
                  .required('Please enter domain name'),
              })}
              onSubmit={handleSubmit}
            >
              {(formik) => (
                <form onSubmit={formik.handleSubmit} data-testid="buy-domain-form">
                  <Flex align="center">
                    <FormFieldWrapper label="Domain" name="name">
                      <TextInput placeholder="e.g. domain.com" />
                    </FormFieldWrapper>
                    <Button
                      variant="gray"
                      css={{ ml: '$2', mt: 7 }}
                      disabled={!formik.isValid}
                      onClick={handleCheck(formik.values.name)}
                    >
                      Check
                    </Button>
                  </Flex>
                  {checking ? (
                    <CheckingContainer align="center">
                      <HStack>
                        <Spinner color="gray" size={2} />
                        <Label css={{ mr: 8 }}>Checking</Label>
                      </HStack>
                    </CheckingContainer>
                  ) : (
                    <>
                      {availability && (
                        <>
                          <CheckingContainer align="center">
                            {availability?.available ? (
                              <>
                                <HStack>
                                  {Number(availability?.price) > 20 ? (
                                    <HiExclamation color="#C69832" size={42} />
                                  ) : (
                                    <HiShieldCheck color="#28D07F" size={40} />
                                  )}
                                  <VStack gap={1} css={{ mr: '5%' }}>
                                    <Label>Domain is available</Label>
                                    <Value>{checkingName}</Value>
                                  </VStack>
                                </HStack>
                                <VStack gap={1} css={{ mr: '5%' }}>
                                  <Label>Price</Label>
                                  <Value>{`$${Math.round(
                                    availability?.price || 0
                                  )}`}</Value>
                                </VStack>
                                <VStack gap={1} css={{ mr: '5%' }}>
                                  <Label>Period</Label>
                                  <Value>{`${availability?.period_in_years} year${
                                    Number(availability?.period_in_years || 0) > 1
                                      ? 's'
                                      : ''
                                  }`}</Value>
                                </VStack>
                              </>
                            ) : (
                              <HStack>
                                <HiXCircle color="red" size={32} />
                                <VStack gap={1}>
                                  <Label>
                                    {availability?.error || 'Domain already registered'}
                                  </Label>
                                  <Value>{checkingName}</Value>
                                </VStack>
                              </HStack>
                            )}
                          </CheckingContainer>
                          {Number(availability?.price) > 20 && (
                            <Label css={{ mt: 5 }}>
                              To buy domains that cost more than $20 please contact Whippy
                              support
                            </Label>
                          )}
                        </>
                      )}
                    </>
                  )}
                  <Box css={{ pt: 15 }} />
                  <DialogFooter justify="end" css={{ mt: 15 }}>
                    <DialogClose asChild>
                      <Button variant="gray" css={{ mr: '$1' }} onClick={handleClose}>
                        Cancel
                      </Button>
                    </DialogClose>
                    <DialogClose asChild>
                      <Button
                        type="submit"
                        disabled={
                          !formik.isValid ||
                          !availability?.available ||
                          Number(availability?.price) > 20 ||
                          formik.values.name !== checkingName ||
                          checking ||
                          formik.isSubmitting ||
                          !formik.values.name
                        }
                      >
                        Buy Domain
                      </Button>
                    </DialogClose>
                  </DialogFooter>
                </form>
              )}
            </Formik>
            <DialogClose asChild>
              <DialogCloseIcon onClick={handleClose} size="2">
                <HiX style={{ color: 'white' }} />
              </DialogCloseIcon>
            </DialogClose>
          </DialogContent>
        </DialogOverlay>
      </DialogPortal>
    </Dialog>
  );
};

const CheckingContainer = styled(HStack, {
  p: 20,
  backgroundColor: '#F6F6F6',
  borderRadius: 4,
  minHeight: 83,
  justifyContent: 'space-between',
});

const Label = styled(Box, {
  fontSize: 12,
  fontWeight: '500',
  ml: 3,
  color: '#687076',
  textWrap: 'nowrap',
});

const Value = styled(Box, {
  fontSize: 20,
  lineHeight: 1,
  fontWeight: '700',
  ml: 3,
  color: '#13171E',
});
