/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useState } from 'react';

import { useLLMAgent } from '@/pages/agents/context/AgentsContext';
import { SingleSelect } from '@/shared/components/SingleSelect';
import { Agent } from '@/shared/types/ai/agents';
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
  Divider,
  Fieldset,
  Flex,
  Label,
  Text,
  VStack,
} from '@/shared/ui';

import { useLocations } from '../context/LocationContext';

export const AgentInboundCall = () => {
  const {
    locationsState: { current },
    updateLocation,
  } = useLocations();

  const agentsContext = useLLMAgent();
  const agents = agentsContext.state.agents;

  useEffect(() => {
    agentsContext.fetchAgents([], [], 100, 0);
  }, []);

  // set agent id and update location from current location
  useEffect(() => {
    if (current?.agent_id) {
      setAgentId(current.agent_id);
    }
  }, []);

  const [agentId, setAgentId] = useState('');

  const handleChangeAgentIdAndUpdateLocation = useCallback(
    async (newAgentId: string) => {
      setAgentId(newAgentId);
      if (current?.id) {
        await updateLocation({
          ...current,
          id: current.id,
          agent_id: newAgentId,
        });
      }
    },
    [current, updateLocation]
  );

  const getAgentName = useCallback(
    (agentId: string) => {
      ``;
      const agent = agents.data.find((agent: Agent) => agent.id === agentId);
      return agent?.current_version.name || '';
    },
    [agents]
  );

  return (
    <Accordion
      css={{ background: 'white' }}
      type="single"
      collapsible
      data-testid="agent-inbound-call"
    >
      <AccordionItem value="basic-information" variant="neumorphic">
        <Flex justify="between" align="center">
          <VStack gap={2} css={{ lineHeight: 1.5 }}>
            <Text css={{ fontWeight: 600 }}>Inbound Agent Call</Text>
            <Text>
              You can select an agent to handle inbound calls for this location.
            </Text>
          </VStack>
          <AccordionTrigger />
        </Flex>
        <AccordionContent variant="neumorphic">
          <Divider css={{ mt: -20, mb: 20 }} />
          {!agentsContext.state.loading && (
            <Fieldset>
              <Label>Select Agent</Label>
              <SingleSelect
                defaultPlaceholder={getAgentName(agentId) || 'Select an agent'}
                selectItem={agentId}
                setSelectItem={handleChangeAgentIdAndUpdateLocation}
                options={agents.data.map((agent: Agent) => ({
                  type: agent.current_version.name || '',
                  value: agent.id || '',
                }))}
                isDropdown
              />
            </Fieldset>
          )}
        </AccordionContent>
      </AccordionItem>
    </Accordion>
  );
};
