/* eslint-disable react-hooks/exhaustive-deps */

import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { CSVLink } from 'react-csv';
import { HiDownload, HiExternalLink, HiShare } from 'react-icons/hi';
import { useCopyToClipboard } from 'react-use';
import { Virtuoso } from 'react-virtuoso';
import { toast } from 'sonner';

import { useLocations } from '@/pages/settings/organization/locations/context/LocationContext';
import { useUsers } from '@/pages/settings/organization/users/context/UserContext';
import { MediaPreviewContainer } from '@/shared/components/attachments/previewer';
import { Datepicker } from '@/shared/components/datepicker/Datepicker';
import { MultiSelect } from '@/shared/components/MultiSelect';
import { User } from '@/shared/types/users';
import {
  Box,
  Flex,
  HStack,
  IconButton,
  Skeleton,
  Text,
  Tooltip,
  TooltipContent,
  TooltipTrigger,
  VStack,
} from '@/shared/ui';
import { formatPhoneNumber } from '@/shared/utils/validations/validations';
import { styled } from '@/stitches.config';

import { ConversationHeaderContainer } from '../conversation/header';
import {
  AttachmentFileName,
  renderAttachmentType,
} from '../conversation/panels/AttachmentsPanel';
import AttachmentsState, {
  Attachment,
  useAttachments,
} from './context/AttachmentsContext';

export const ConversationAttachments = () => {
  return (
    <AttachmentsState>
      <Flex direction="column" css={{ position: 'relative', flexGrow: 1 }}>
        <ConversationHeaderContainer align="center">
          <Text css={{ fontWeight: 600 }}>Attachments</Text>
        </ConversationHeaderContainer>
        <AttachmentsList />
      </Flex>
    </AttachmentsState>
  );
};

const AttachmentsList = () => {
  const attachment = useAttachments();
  const { getAllAttachments, downloadAttachments } = attachment;
  const { attachments: attachmentsList, loading, error } = attachment.attachmentsState;

  const {
    locationsState: { locations },
  } = useLocations();

  // map locations to multi select
  const [selectedLocations, setSelectedLocations] = useState<{ locations: string[] }>({
    locations: locations.map((location) => location.id),
  });

  // set the location on mount
  useEffect(() => {
    setSelectedLocations({ locations: locations.map((location) => location.id) });
  }, [locations]);

  // set the initial start date of the datepicker to the last 30 days
  // set the initial end date of the datepicker to the current date
  const [dates, setDates] = useState({
    startDate: moment().add(-30, 'day'),
    endDate: moment(),
  });

  // loading more attachments when scrolling to the bottom of the list
  const [offset, setOffset] = useState<number>(0);

  const getAttachments = (
    offset: number,
    locations: string[],
    startingDate: moment.Moment | string,
    endingDate: moment.Moment | string
  ) => {
    getAllAttachments({
      start_date: moment(startingDate).format('YYYY-MM-DD'),
      end_date:
        endingDate === 'Invalid date'
          ? moment().format('YYYY-MM-DD') // if endDate is invalid, set it to today's date
          : moment(endingDate).format('YYYY-MM-DD'),
      location_ids: locations,
      limit: 50,
      offset: offset || 0,
    });
  };

  // every time the user changes a filter we reset the offset to 0
  // so that we can fetch the first page of attachments
  const handleSetDate = (date: { startDate: moment.Moment; endDate: moment.Moment }) => {
    setOffset(0);
    setDates(date);
    getAttachments(0, selectedLocations.locations, date.startDate, date.endDate);
  };

  const handleSetSelectedLocations = ({ locations }: { locations: string[] }) => {
    setOffset(0);
    getAttachments(0, locations, dates.startDate, dates.endDate);
    setSelectedLocations({ locations: locations });
  };

  // order attachments by inserted_at, newest first
  const attachmentsByDate = attachmentsList.sort((a: Attachment, b: Attachment) => {
    return a?.inserted_at < b?.inserted_at ? 1 : -1;
  });

  const [downloadedAttachments, setDownloadedAttachments] = useState<
    {
      name: string;
      phone: string;
      email: string;
      attachment_url: string;
      content_type: string;
      conversation: string;
      inserted_at: string;
    }[]
  >([]);

  const [loadingAttachments, setLoadingAttachments] = useState(false);
  const ref = useRef<{
    link: HTMLAnchorElement | null;
  }>();

  const handleDownloadAttachments = async (
    locations: string[],
    startingDate: moment.Moment | string,
    endingDate: moment.Moment | string
  ) => {
    setLoadingAttachments(true);

    const data = await downloadAttachments({
      start_date: moment(startingDate).format('YYYY-MM-DD'),
      end_date: moment(endingDate).format('YYYY-MM-DD'),
      location_ids: locations,
    });

    // remove attachments that end with .smil
    const filteredData = data.filter((attachment: Attachment) => {
      return !attachment.url.endsWith('.smil');
    });

    const csvData = filteredData.map((attachment: Attachment) => {
      return {
        name: attachment?.contact?.name || '',
        phone: formatPhoneNumber(attachment?.contact?.phone || '') || '',
        email: attachment?.contact?.email || '',
        attachment_url: attachment.url || '',
        content_type: attachment.content_type || '',
        conversation: `https://app.whippy.ai/inbox/all/open/${attachment?.conversation_id}`,
        inserted_at: attachment.inserted_at || '',
      };
    });

    setDownloadedAttachments(csvData);
    setLoadingAttachments(false);
    ref.current && ref.current.link ? ref.current.link.click() : null;
  };

  // filter attachments by date to remove .smil files
  const filteredAttachments = attachmentsByDate.filter((attachment: Attachment) => {
    return !attachment.url.endsWith('.smil');
  });

  return (
    <>
      <Flex css={{ px: 20, pt: 20, width: '100%', minWidth: '100%' }} direction="column">
        <VStack css={{ width: '100%', mb: 20 }} gap="2">
          <Flex justify="between">
            {locations.length > 0 && (
              <Box css={{ width: '25%' }}>
                <MultiSelect
                  defaultPlaceholder="Channels"
                  defaultSelectedItems={selectedLocations.locations}
                  isDropdown={true}
                  options={locations.map((location) => ({
                    type: location?.name || '',
                    value: location.id,
                  }))}
                  setParentSelectedItems={handleSetSelectedLocations}
                  isCampaigns={false}
                />
              </Box>
            )}
            <HStack>
              {attachmentsByDate.length > 0 && (
                <Box>
                  <IconButton
                    size="2"
                    variant="outline"
                    css={{
                      cursor: loadingAttachments ? 'wait' : 'pointer',
                    }}
                    onClick={() =>
                      handleDownloadAttachments(
                        selectedLocations.locations,
                        dates.startDate,
                        dates.endDate
                      )
                    }
                  >
                    <HiDownload />
                  </IconButton>
                  <CSVLink
                    data={downloadedAttachments}
                    ref={ref as any}
                    filename="attachments_export.csv"
                  />
                </Box>
              )}
              <Datepicker setParentDates={handleSetDate} />
            </HStack>
          </Flex>
        </VStack>
      </Flex>
      {/** Empty State */}
      {attachmentsList.length === 0 && !loading && !error && (
        <EmptyStateContainer>
          <AttachmentsContainer align="center" justify="center" direction="column">
            <Text css={{ fontWeight: 600 }}>Attachments</Text>
            <Text css={{ mt: 10 }}>
              Your attachments will appear here when you send or receive one.
            </Text>
          </AttachmentsContainer>
        </EmptyStateContainer>
      )}
      {/** Error State */}
      {!loading && error && (
        <EmptyStateContainer>
          <AttachmentsContainer align="center" justify="center" direction="column">
            <Text css={{ fontWeight: 600 }}>Error Loading Attachments</Text>
            <Text css={{ mt: 10 }}>
              Refresh the page and try again. If the problem persists, please contact
              support.
            </Text>
          </AttachmentsContainer>
        </EmptyStateContainer>
      )}
      {/** Loading State */}
      {attachmentsList.length === 0 && loading && !error && (
        <Flex css={{ mx: 20, my: 10 }} direction="column">
          <VStack gap={3}>
            {Array.from({ length: 10 }, (_: any, k: React.Key | null | undefined) => (
              <AttachmentCardContainer align="center" key={k}>
                <Skeleton css={{ height: 40, width: 40, borderRadius: 4 }} />
                <Flex>
                  <VStack></VStack>
                </Flex>
              </AttachmentCardContainer>
            ))}
          </VStack>
        </Flex>
      )}
      {/** Attachments List */}
      {filteredAttachments.length > 0 && !loading && (
        <Virtuoso
          data={filteredAttachments}
          atBottomStateChange={(atBottom) => {
            if (atBottom) {
              setOffset(offset + attachmentsByDate?.length);
              getAttachments(
                offset + attachmentsByDate?.length || 0,
                selectedLocations?.locations || [],
                dates.startDate,
                dates.endDate
              );
            }
          }}
          itemContent={(i: number, attachment: Attachment) => (
            <AttachmentCard key={i} attachment={attachment} />
          )}
        />
      )}
    </>
  );
};

export const AttachmentCard = (props: { attachment: Attachment }) => {
  const { attachment } = props;
  const { url, conversation_id } = attachment;

  // get file name
  const file_name = /[^/]*$/.exec(url);

  // manage the clipboard sate, to copy the url to the clipboard
  const [, copyToClipboard] = useCopyToClipboard();

  const handleCopyClick = () => {
    copyToClipboard(url);
    toast.success('Copied to clipboard');
  };

  // user context
  const users = useUsers();
  const { userState } = users;
  const { users: usersList } = userState;

  // get the user from state
  const getUser = (id: number) => {
    return usersList.find((user: User) => user.id === id);
  };

  const getName = () => {
    const user = attachment?.user_id ? getUser(attachment?.user_id) : null;
    // if the user is set, return the name or email
    // if the contact is set return the name or the phone number
    if (user) {
      return user.name || user.email;
    } else if (attachment.contact) {
      return (
        attachment.contact.name || formatPhoneNumber(attachment?.contact?.phone || '')
      );
    } else {
      return '';
    }
  };

  return (
    <Box css={{ mx: 20, my: 10 }}>
      <MediaPreviewContainer url={url}>
        <AttachmentCardContainer align="center" justify="between">
          <HStack gap={1}>
            <Flex>{renderAttachmentType(url)}</Flex>
            <Flex direction="column" align="start" css={{ ml: 10 }}>
              <AttachmentFileName css={{ maxWidth: 500 }}>
                {file_name ? file_name[0] : ''}
              </AttachmentFileName>
              <HStack>
                <Text size={1}>Shared by {getName()}</Text>
                <Text size={1}>{moment(attachment.inserted_at).fromNow()}</Text>
                <Text size={1}>on {moment(attachment.inserted_at).format('ll')}</Text>
              </HStack>
            </Flex>
          </HStack>
          <HStack>
            <Tooltip>
              <TooltipTrigger asChild>
                <a href={url} download target="_blank" rel="noreferrer">
                  <IconButton size={2}>
                    <HiDownload size={18} />
                  </IconButton>
                </a>
              </TooltipTrigger>
              <TooltipContent>Download Attachment</TooltipContent>
            </Tooltip>
            <Tooltip>
              <TooltipTrigger asChild>
                <IconButton size={2} onClick={() => handleCopyClick()}>
                  <HiShare />
                </IconButton>
              </TooltipTrigger>
              <TooltipContent>Copy Link</TooltipContent>
            </Tooltip>
            <Tooltip>
              <TooltipTrigger asChild>
                {/* eslint-disable-next-line react/no-unknown-property */}
                <a
                  href={`/inbox/all/open/${conversation_id}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  <IconButton size={2}>
                    <HiExternalLink size={18} />
                  </IconButton>
                </a>
              </TooltipTrigger>
              <TooltipContent>Open Conversation</TooltipContent>
            </Tooltip>
          </HStack>
        </AttachmentCardContainer>
      </MediaPreviewContainer>
    </Box>
  );
};

const AttachmentsContainer = styled(Flex, {
  width: '100%',
  p: 20,
});

const AttachmentCardContainer = styled(Flex, {
  fontSize: 13,
  py: 5,
  px: 10,
  width: '100% !important',
  minWidth: '100% !important',
  height: 60,
  borderRadius: 4,
  borderWidth: 1.5,
  borderColor: '$gray3',
  '&:hover': {
    backgroundColor: '$slate2',
  },
  cursor: 'pointer',
});

const EmptyStateContainer = styled(Flex, {
  p: 20,
  height: '100%',
  width: '100%',
});
