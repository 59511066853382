import {
  Combobox,
  ComboboxItem,
  ComboboxItemCheck,
  ComboboxPopover,
  ComboboxProvider,
  useComboboxContext,
} from '@ariakit/react';
import { createContext, useContext, useState } from 'react';
import { HiX } from 'react-icons/hi';
import { Virtuoso } from 'react-virtuoso';

import { Contact } from '@/shared/types';
import { Group } from '@/shared/types/contacts/groups';
import { Tag } from '@/shared/types/tags';
import { Flex, Tabs, TabsContent, TabsList, TabsTrigger } from '@/shared/ui';

type ContextProps = {
  onSearch: (searchInput: string) => void;
  onTabChange?: (tab: string) => void;
  defaultTab?: string;
  onEndReached: (tab: string, searchInput: string) => void;
  setSelectedItems: (values: { id: string; value: string }[]) => void;
  selectedItems?: { id: string; value: string }[];
  currentTab?: string;
  setCurrentTab?: (tab: string) => void;
};

interface TabbedComboboxComponent
  extends React.FC<React.PropsWithChildren<ContextProps>> {
  Tabs: React.FC<React.PropsWithChildren>;
  TabsList: React.FC<React.PropsWithChildren>;
  Popover: React.FC<React.PropsWithChildren>;
  Input: React.FC<React.InputHTMLAttributes<HTMLInputElement>>;
  ResultsList: React.FC<React.PropsWithChildren>;
  ResultItem: React.FC<{ value: string }>;
  TabsTrigger: React.FC<React.PropsWithChildren<{ value: string }>>;
  TabsContent: React.FC<
    React.PropsWithChildren<{
      value: string;
      items: (Tag | Contact | Group)[];
      totalItems: number;
    }>
  >;
}

const TabbedComboboxContext = createContext<ContextProps | undefined>(undefined);
const useTabbedComboboxContext = () => {
  const context = useContext(TabbedComboboxContext);
  if (context == undefined) {
    throw new Error('TabbedCombobox.* must be a child of TabbedCombobox component');
  }
  return context;
};

const TabbedCombobox: TabbedComboboxComponent = ({
  onTabChange,
  defaultTab,
  onSearch,
  setSelectedItems,
  selectedItems,
  onEndReached,
  children,
}) => {
  const [currentTab, setCurrentTab] = useState(defaultTab);
  return (
    <TabbedComboboxContext.Provider
      value={{
        onTabChange,
        defaultTab,
        onSearch,
        setSelectedItems,
        onEndReached,
        setCurrentTab,
        currentTab,
      }}
    >
      <Flex direction="column">
        <ComboboxProvider
          setSelectedValue={(selectedItems) => {
            const items = convertValueStringToObject(selectedItems as string[]);
            setSelectedItems(items);
          }}
          selectedValue={selectedItems?.map((item) => item.value + '---' + item.id) ?? []}
        >
          {children}
        </ComboboxProvider>
      </Flex>
    </TabbedComboboxContext.Provider>
  );
};

const TabbedComboboxTabs: React.FC<React.PropsWithChildren> = ({ children }) => {
  const context = useTabbedComboboxContext();
  return (
    <Tabs
      defaultValue={context.defaultTab}
      onValueChange={(tab) => {
        if (context.setCurrentTab) {
          context.setCurrentTab(tab);
        }
        if (context.onTabChange) {
          context.onTabChange(tab);
        }
      }}
    >
      {children}
    </Tabs>
  );
};

const TabbedComboboxTabsList: React.FC<React.PropsWithChildren> = ({ children }) => {
  return <TabsList>{children}</TabsList>;
};

const TabbedComboboxInput: React.FC<React.InputHTMLAttributes<HTMLInputElement>> = ({
  children,
  placeholder,
  style,
}) => {
  const tabbedContext = useTabbedComboboxContext();
  const comboboxContext = useComboboxContext();
  return (
    <Flex justify="between">
      <Combobox
        onChange={(e) => tabbedContext.onSearch(e.target.value)}
        placeholder={placeholder}
        style={style}
      >
        {children}
      </Combobox>
      <button onClick={() => setSelectedValues(comboboxContext, [])}>
        <HiX />
      </button>
    </Flex>
  );
};

const TabbedComboboxTabsTrigger: React.FC<React.PropsWithChildren<{ value: string }>> = ({
  value,
  children,
}) => {
  return <TabsTrigger value={value}>{children}</TabsTrigger>;
};

const handleEndReached = (
  currentTab: string,
  searchInput: string,
  onEndReached: (tab: string, searchInput: string) => void
) => {
  onEndReached(currentTab, searchInput as string);
};

// NOTE: For some reason including increaseViewportBy creates a buggy experience. As the user scrolls, this will cause some "shake"
// which ultimately doesn't really let scroll work properly. It will only let you scroll almost one item at a time.
const TabbedComboboxTabsContent: React.FC<
  React.PropsWithChildren<{
    value: string;
    items: (Tag | Group | Contact)[];
    totalItems: number;
  }>
> = ({ value, items }) => {
  const tabbedComboboxContext = useTabbedComboboxContext();
  const comboboxContext = useComboboxContext();
  return (
    <TabsContent value={value}>
      <Virtuoso
        style={{ height: 320 }}
        data={items}
        atBottomStateChange={(bottomState) => {
          if (bottomState) {
            handleEndReached(
              tabbedComboboxContext.currentTab as string,
              comboboxContext?.getState().value ?? '',
              tabbedComboboxContext.onEndReached
            );
          }
        }}
        itemContent={(index, item) => {
          return (
            <ComboboxItem
              key={index}
              value={item.name + '---' + item.id}
              focusOnHover
              style={{ height: '24px', margin: '5px' }}
            >
              <Flex align="center">
                <ComboboxItemCheck />
                {item.name}
              </Flex>
            </ComboboxItem>
          );
        }}
      />
    </TabsContent>
  );
};

const TabbedComboboxPopover: React.FC<React.PropsWithChildren> = ({ children }) => {
  return <ComboboxPopover>{children}</ComboboxPopover>;
};

const TabbedComboboxResultsList: React.FC<React.PropsWithChildren> = ({ children }) => {
  return <Flex css={{ gap: '8px', flexWrap: 'wrap', width: '1041px' }}>{children}</Flex>;
};

const TabbedComboboxResultItem: React.FC<{ value: string }> = ({ value }) => {
  const comboboxContext = useComboboxContext();

  return (
    <Flex
      css={{
        display: 'flex',
        height: '24px',
        padding: '0px 8px',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '8px',
        cursor: 'pointer',
        borderRadius: '3px',
        background: '#0144FF0F',
        color: '#00259ECB',
      }}
    >
      {value}
      <button
        onClick={() => {
          const selectedItems = getSelectedValues(comboboxContext);
          const selectedValues = selectedItems.map((item) => item.value);
          setSelectedValues(
            comboboxContext,
            selectedValues.filter((item) => item != value)
          );
        }}
      >
        <HiX
          style={{
            width: '16px',
            height: '16px',
          }}
        />
      </button>
    </Flex>
  );
};

function setSelectedValues(comboboxContext: any, selectedValues: string[]): void {
  comboboxContext?.setState('selectedValue', selectedValues);
}

function convertValueStringToObject(selectedItems: string[]) {
  return selectedItems.map((item) => {
    const value = item.split('---')[0];
    const id = item.split('---')[1];
    return {
      id,
      value,
    };
  });
}

function getSelectedValues(comboboxContext: any): { id: string; value: string }[] {
  const state = comboboxContext?.getState();
  const selectedItems = state?.selectedValue as string[];
  return convertValueStringToObject(selectedItems);
}

export {
  TabbedCombobox,
  TabbedComboboxTabs,
  TabbedComboboxTabsList,
  TabbedComboboxTabsTrigger,
};
TabbedCombobox.Tabs = TabbedComboboxTabs;
TabbedCombobox.TabsList = TabbedComboboxTabsList;
TabbedCombobox.TabsTrigger = TabbedComboboxTabsTrigger;
TabbedCombobox.TabsContent = TabbedComboboxTabsContent;
TabbedCombobox.ResultsList = TabbedComboboxResultsList;
TabbedCombobox.ResultItem = TabbedComboboxResultItem;
TabbedCombobox.Popover = TabbedComboboxPopover;
TabbedCombobox.Input = TabbedComboboxInput;
export default TabbedCombobox;
