import i18next from 'i18next';
import React, { useEffect, useRef } from 'react';
import { createContext, useContext, useReducer } from 'react';
import { toast } from 'sonner';

import * as API from '@/shared/api/contacts/v1';
import { searchContacts } from '@/shared/api/contacts/v2';
import { getCustomObjectRecords } from '@/shared/api/data';
import {
  Contact,
  DataActionTypes,
  DataStateType,
  SearchFilters,
} from '@/shared/types/contacts';

import { prepareFilters } from '../utils/prepareFilters';
import DataReducer from './DataReducer';

export const initialState: DataStateType = {
  contacts: [],
  customObjectRecords: [],
  totalCount: 0,
  error: null,
  loading: true,
  loadingCustomObjects: true,
  loadingMore: false,
  contactsFilters: {
    filter: [],
    searchFilter: [],
    sort: [],
    limit: 100,
    offset: 0,
  },
  customObjectsFilters: {
    filter: [],
    searchFilter: [],
    sort: [],
    limit: 100,
    offset: 0,
  },
};

export const DataContext = createContext<{
  dataState: DataStateType;
  updateContactFilters: (param: SearchFilters) => void;
  getContacts: (params: SearchFilters) => Promise<void>;
  createContact: (params: Partial<Contact>) => Promise<Contact>;
  blockContact: (id: string, isBlocked: boolean) => void;
  deleteContact: (id: string) => void;
  updateObjectRecordFilters: (param: SearchFilters) => void;
  getObjectRecords: (id: string, params: SearchFilters) => Promise<void>;
}>({
  dataState: initialState,
  updateContactFilters: () => Promise.resolve(),
  getContacts: () => Promise.resolve(),
  createContact: () => Promise.resolve({} as Contact),
  blockContact: () => Promise.resolve(),
  deleteContact: () => Promise.resolve(),
  updateObjectRecordFilters: () => Promise.resolve(),
  getObjectRecords: () => Promise.resolve(),
});

export const useData = () => useContext(DataContext);

const DataState = ({ children }: { children: React.ReactNode }) => {
  const [dataState, dispatch] = useReducer(DataReducer, initialState);

  const currentController = useRef<AbortController | null>(null);

  useEffect(() => {
    getContacts(dataState.contactsFilters);
  }, [dataState.contactsFilters]);

  const updateContactFilters = (params: SearchFilters) => {
    dispatch({
      type: DataActionTypes.UPDATE_CONTACT_FILTERS,
      payload: { filters: params, is_load_more: params.offset > 0 },
    });
  };

  const updateObjectRecordFilters = (params: SearchFilters) => {
    dispatch({
      type: DataActionTypes.UPDATE_OBJECT_RECORD_FILTERS,
      payload: { filters: params, is_load_more: params.offset > 0 },
    });
  };

  const getContacts = async (params: SearchFilters) => {
    try {
      if (currentController.current) {
        currentController.current.abort();
        currentController.current = null;
      }
      const controller = new AbortController();
      currentController.current = controller;

      const filters = prepareFilters(params);

      const data = await searchContacts(
        filters.filter,
        filters.sort,
        filters.limit,
        filters.offset,
        controller.signal
      );

      dispatch({
        type: DataActionTypes.GET_CONTACTS,
        payload: { ...data, isLoadMore: params.offset > 0 },
      });
    } catch (err) {
      console.error(err);
      toast.error(i18next.t('fetch_contacts_failure') as string, {
        description:
          err?.response?.data?.errors[0]?.description || 'Invalid filters applied',
      });
    }
  };

  const createContact = async (params: Partial<Contact>): Promise<Contact> => {
    try {
      const data = await API.createContact(params);

      dispatch({
        type: DataActionTypes.CREATE_CONTACT,
        payload: data,
      });

      toast.success(i18next.t('contact_added') as string);

      return data;
    } catch (err) {
      console.error(err);
      toast.error(i18next.t('contact_added_failure') as string);
      return Promise.reject(err);
    }
  };

  const deleteContact = async (id: string) => {
    try {
      await API.deleteContact(id);
      dispatch({
        type: DataActionTypes.DELETE_CONTACT,
        payload: { id },
      });

      toast.success(i18next.t('contact_deleted') as string);
    } catch (err) {
      console.error(err);
      toast.error(i18next.t('contact_deleted_failure') as string);
      return Promise.reject(err);
    }
  };

  const blockContact = async (id: string, isBlocked: boolean) => {
    try {
      const data = await API.blockContact(id, isBlocked);

      toast.success(i18next.t('contact_blocked_success') as string);
      dispatch({
        type: DataActionTypes.BLOCK_CONTACT,
        payload: data,
      });
    } catch (err) {
      console.error(err);
      toast.success(i18next.t('contact_blocked_failure') as string);
      return Promise.reject(err);
    }
  };

  const getObjectRecords = async (id: string, params: SearchFilters) => {
    try {
      const filters = [...params.filter, ...params.searchFilter];
      const data = await getCustomObjectRecords(
        id,
        params.limit,
        params.offset,
        filters,
        params.sort
      );

      dispatch({
        type: DataActionTypes.GET_CUSTOM_OBJECT_RECORDS,
        payload: { ...data, isLoadMore: params.offset > 0 },
      });
    } catch (err) {
      console.error(err);
      toast.error(i18next.t('fetch_contacts_failure') as string, {
        description:
          err?.response?.data?.errors[0]?.description || 'Invalid filters applied',
      });
    }
  };

  return (
    <DataContext.Provider
      value={{
        dataState,
        updateContactFilters,
        getContacts,
        createContact,
        blockContact,
        deleteContact,
        updateObjectRecordFilters,
        getObjectRecords,
      }}
    >
      {children}
    </DataContext.Provider>
  );
};

export default DataState;
