import { TeamMember } from '@/shared/types/team';
import { Box, Text } from '@/shared/ui';

import { TeamMemberPreview } from './TeamMemberPreview';

type TeamMembersAvatarsProps = {
  members: Array<TeamMember>;
  limit: number;
};

export const TeamMembersAvatars = ({ members, limit = 4 }: TeamMembersAvatarsProps) => {
  const count = members?.length > limit ? members?.length - limit : 0;

  return members?.length > 0 ? (
    <>
      {members
        ?.slice(0, limit)
        .map((item: TeamMember) => (
          <TeamMemberPreview key={item.id} user_id={item.user_id} />
        ))}
      {count > 0 && <Box css={{ marginLeft: 16 }}>+{count}</Box>}
    </>
  ) : (
    <Text>0</Text>
  );
};
