import dayjs from 'dayjs';
import { debounce, DebouncedFunc } from 'lodash';
import React, { useCallback, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { handleFilterChange, handleSortUpdate } from '@/pages/data/utils/filterActions';
import {
  CustomDropdownMenuItem,
  CustomDropdownMenuItemWarning,
} from '@/pages/settings/organization/users/UsersTable';
import { ConfirmationDialog } from '@/shared/components/ConfirmationDialog';
import { CombinedFilters } from '@/shared/components/filterBuilder/CombinedFilters';
import { domain_object } from '@/shared/components/filterBuilder/objects/domain';
import { SearchFilters } from '@/shared/types/contacts';
import { Domain } from '@/shared/types/domains';
import { DomainStatusType } from '@/shared/types/domains';
import { FilterType, Sort } from '@/shared/types/filter';
import { Badge, Box, Flex, Link } from '@/shared/ui';
import { MONTH_DAY_YEAR } from '@/shared/utils/timestamps';
import { Table, TableColumn } from '@/shared/v2/components/table/Table';
import { TableActionMenu } from '@/shared/v2/components/table/TableActionMenu';
import { styled } from '@/stitches.config';

import { ITEMS_COUNT, useDomainsContext } from '../context/DomainsContext';
import { getBadgeVariant } from '../utils/utils';
import { AddDomain } from './AddDomain';
import { CreatedByUser } from './CreatedByUser';
import { sortConfig } from './filterConfig';

export const DomainsTable = () => {
  const history = useHistory();
  const [quickSearchValue, setQuickSearchValue] = useState('');
  const [activeFilters, setActiveFilters] = useState<FilterType[]>([]);

  const {
    domainsState: { loading, domains, totalCount, filterParams },
    updateFilterParams,
    deleteDomain,
  } = useDomainsContext();

  const columns: Array<TableColumn<Domain>> = useMemo(
    () => [
      {
        Header: 'Domains',
        colWidth: '25%',
        accessor: 'name',
        Cell: (props: { row: { original: Domain } }) => (
          <Flex direction="column">
            {props.row.original?.status === DomainStatusType.CANCELLED ? (
              <Label>{props.row.original.name}</Label>
            ) : (
              <DomainLink href={`https://${props.row.original.name}`} target="_blank">
                {props.row.original.name}
              </DomainLink>
            )}
          </Flex>
        ),
      },
      {
        Header: 'Setup Type',
        colWidth: '10%',
        accessor: 'setupType',
        Cell: (props: { row: { original: Domain } }) => (
          <Flex direction="column">
            <CapitalizeText>{props.row.original.setup_type}</CapitalizeText>
          </Flex>
        ),
      },
      {
        Header: 'Expiration Date',
        colWidth: '15%',
        accessor: 'expires_at',
        Cell: (props: { row: { original: Domain } }) => (
          <Flex direction="column">
            <Label>
              {props.row.original.expires_at
                ? dayjs(props.row.original.expires_at).format(MONTH_DAY_YEAR)
                : '-'}
            </Label>
          </Flex>
        ),
      },
      {
        Header: 'Status',
        colWidth: '15%',
        accessor: 'status',
        Cell: (props: { row: { original: Domain } }) => (
          <Badge variant={getBadgeVariant(props.row.original.status)}>
            <CapitalizeText>{props.row.original.status}</CapitalizeText>
          </Badge>
        ),
      },
      {
        Header: 'Created by',
        accessor: 'created',
        Cell: (props: { row: { original: Domain } }) => (
          <CreatedByUser
            isTable
            userId={
              props.row.original?.created_by_id || props.row.original?.created_by?.id || 0
            }
          />
        ),
      },
      {
        Header: 'Created At',
        id: 'created_at',
        accessor: 'created_at',
        colWidth: '100%',
        Cell: (props: { row: { original: Domain } }) => (
          <Flex align="center" css={{ minWidth: 125 }}>
            <Box>{dayjs(props.row.original.inserted_at).format('MMM D YYYY')}</Box>
          </Flex>
        ),
      },
      {
        Header: '',
        colWidth: '5%',
        accessor: 'action',
        Cell: (props: { row: { original: Domain } }) => (
          <Flex direction="row" justify="end">
            <TableActionMenu>
              <>
                <CustomDropdownMenuItem
                  data-testid="view-domain-option"
                  onClick={() =>
                    history.push(`/campaigns/domains/${props.row.original.id}`)
                  }
                >
                  View Domain
                </CustomDropdownMenuItem>
                <ConfirmationDialog
                  width="432px"
                  title="Delete Domain?"
                  description="Are you sure want to delete this domain? This action cannot be undone."
                  onConfirm={() => deleteDomain(props.row.original.id)}
                  confirmButtonTitle="Confirm"
                  cancelButtonTitle="Cancel"
                  confirmButtonVariant="redBackground"
                  cancelButtonVariant="grayBackground"
                >
                  <CustomDropdownMenuItemWarning
                    data-testid="delete-domain-option"
                    onClick={(e) => e.preventDefault()}
                  >
                    Delete Domain
                  </CustomDropdownMenuItemWarning>
                </ConfirmationDialog>
              </>
            </TableActionMenu>
          </Flex>
        ),
      },
    ],
    [loading, domains]
  );

  const debouncedUpdate = useCallback(
    debounce((props: SearchFilters) => {
      updateFilterParams(props);
    }, 1000),
    []
  );

  const handleQuickSearch = (
    debouncedUpdate: DebouncedFunc<(props: SearchFilters) => void>,
    filters: SearchFilters,
    value: string
  ) => {
    const formattedValue = value.trim();
    const quickFilters = [
      {
        column: 'name',
        comparison: 'ilike',
        value: `%${formattedValue}%`,
        resource: 'domain',
      },
    ];
    debouncedUpdate({
      ...filters,
      searchFilter: quickFilters,
      offset: 0,
    });
  };

  return (
    <Flex direction="column" css={{ m: 24, flex: '1 1 auto' }}>
      <Box css={{ paddingBottom: 24 }}>
        <CombinedFilters
          quickSearchPlaceholder="Search Domains"
          quickSearchValue={quickSearchValue}
          setQuickSearchValue={(value: string) => {
            setQuickSearchValue(value);
            handleQuickSearch(debouncedUpdate, filterParams, value);
          }}
          customObjects={[]}
          defaultObjects={[domain_object]}
          activeFilters={activeFilters}
          setFilters={(value: Array<FilterType>) => {
            setActiveFilters(value);
            handleFilterChange(debouncedUpdate, filterParams, value);
          }}
          sortConfig={sortConfig}
          activeSort={filterParams.sort}
          onSortUpdate={(value: Array<Sort>) =>
            handleSortUpdate(debouncedUpdate, filterParams, value, true)
          }
        />
      </Box>
      <Table
        data={domains}
        columns={columns}
        caption="Domains Table"
        isLoading={loading}
        totalCount={totalCount}
        setOffset={(offset) => {
          updateFilterParams({
            ...filterParams,
            offset,
          });
        }}
        pageSize={ITEMS_COUNT}
        emptyTitle="No Domains Match Search"
        empty={
          <Box>
            <Box
              css={{
                width: 250,
                fontSize: 16,
                marginTop: 8,
                marginBottom: 16,
                fontWeight: 400,
              }}
            >
              <Box css={{ padding: '6.5px 0' }}>No Domains were found.</Box>
              <Box>
                <AddDomain />
              </Box>
            </Box>
          </Box>
        }
      />
    </Flex>
  );
};

const Label = styled(Box, {
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  maxWidth: 200,
  textOverflow: 'ellipsis',
  fontWeight: '500',
  color: '$slate12',
});

const CapitalizeText = styled(Box, {
  textTransform: 'capitalize',
  fontSize: 14,
});

const DomainLink = styled(Link, {
  fontSize: 14,
  color: '$slate12 !important',
  textDecoration: 'underline',
  textDecorationColor: '$slate12 !important',
  fontWeight: '500',
  '&:hover': {
    textDecoration: 'none',
  },
});
