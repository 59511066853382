/* eslint-disable react-hooks/exhaustive-deps */
import { Formik, useFormikContext } from 'formik';
import { useEffect, useState } from 'react';
import { HiKey } from 'react-icons/hi';
import { useParams } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import * as Yup from 'yup';

import { useLocations } from '@/pages/settings/organization/locations/context/LocationContext';
import { ConfirmationDialog } from '@/shared/components/ConfirmationDialog';
import {
  FormFieldWrapper,
  helpers,
  MultiSelectCombobox,
  RadioInput,
} from '@/shared/components/forms';
import { KeywordBuilder } from '@/shared/components/forms/KeywordBuilder';
import { ActionRenderer } from '@/shared/components/triggers/actions/ActionRenderer';
import { PageLayout } from '@/shared/layouts/PageLayout';
import { Keyword } from '@/shared/types/keywords';
import { Location } from '@/shared/types/locations';
import { Action } from '@/shared/types/triggers/actions';
import { Box, Button, Flex, HStack, VStack } from '@/shared/ui';
import { isValidUuid } from '@/shared/utils/validations/validations';

import {
  ActionWrapper,
  getActionColor,
  getActionIcon,
  getActionTitle,
} from '../../../shared/components/triggers/actions/ActionWrapper';
import { AddAction } from '../../../shared/components/triggers/actions/AddAction';
import { ActionDescription } from '../ActionDescription';
import { KeywordDescription } from '../KeywordDescription';
import { useKeywords } from './context/KeywordsContext';

type KeywordParams = {
  /* The id of the keyword to edit from the url */
  id: string;
};

export type KeywordFormValuesType = {
  /* The keywords to match */
  keywords?: Array<string>;
  /* The keywords to negate */
  negate_keywords?: Array<string | null> | null;
  /* Whether to match the exact keyword */
  exact_match: boolean | string | null;
  /* The locations to match */
  locations?: Array<string>;
  /* The actions to perform */
  actions: Array<Action>;
};

export const EditKeyword = () => {
  const { id } = useParams<KeywordParams>();

  const location = useLocations();
  const { locations } = location.locationsState;

  const keywordsContext = useKeywords();
  const { updateKeyword, getKeyword, keywordsState, setCurrentKeyword, deleteKeyword } =
    keywordsContext;
  const { current } = keywordsState;

  useEffect(() => {
    setCurrentKeyword(null);
    if (isValidUuid(id)) {
      getKeyword(id);
    }
  }, [id]);

  const [initialValues, setInitialValues] = useState<KeywordFormValuesType>({
    keywords: current?.keywords || [''],
    negate_keywords: current?.negate_keywords || [''],
    exact_match: current?.exact_match?.toString() as string,
    locations: current?.locations?.map((location) => location?.id) || [],
    actions: current?.actions || [],
  });

  useEffect(() => {
    if (current) {
      setInitialValues({
        keywords: current?.keywords || [],
        negate_keywords: current?.negate_keywords || [],
        exact_match: current?.exact_match?.toString() || 'false',
        locations: current?.locations?.map((location) => location?.id) || [],
        actions: current?.actions || [],
      });
    }

    return () => {
      setInitialValues({
        keywords: [''],
        negate_keywords: [''],
        exact_match: null,
        locations: current?.locations?.map((location) => location?.id) || [],
        actions: [],
      });
    };
  }, [id, current]);

  return (
    <PageLayout
      breadcrumbs={[
        { title: 'Automations', path: '/automations/keywords' },
        { title: 'Keywords', path: '/automations/keywords' },
        {
          title: `${
            current?.keywords && current?.keywords.length > 0 ? current.keywords[0] : ''
          }`,
          path: `/automations/keywords/${id}`,
        },
      ]}
    >
      {current && initialValues.actions !== null && (
        <Flex
          direction="column"
          css={{
            position: 'relative',
            height: '100%',
            flex: 1,
            overflow: 'auto',
            backgroundColor: '#fafafa',
            p: '20px 30px',
          }}
        >
          <VStack gap={3} css={{ width: '100%' }}>
            <Formik
              enableReinitialize
              initialValues={initialValues}
              validationSchema={Yup.object({
                keywords: Yup.array().test(
                  'len',
                  'Required',
                  (arr) => Array.isArray(arr) && arr.length > 0
                ),
                negate_keywords: Yup.array(),
                locations: Yup.array().test(
                  'len',
                  'Required',
                  (arr) => Array.isArray(arr) && arr.length > 0
                ),
                actions: Yup.array().test(
                  'len',
                  'Required',
                  (arr) => Array.isArray(arr) && arr.length > 0
                ),
              })}
              onSubmit={async (values: KeywordFormValuesType) => {
                const params: Keyword = {
                  keywords: values.keywords as string[],
                  negate_keywords: values.negate_keywords as string[],
                  location_ids: values.locations,
                  exact_match: (values?.exact_match as boolean) || false,
                  actions: values.actions as Array<Action>,
                };
                try {
                  updateKeyword(current?.id || '', params);
                } catch (e) {
                  console.log(e);
                }
              }}
            >
              {(formik) => (
                <form onSubmit={formik.handleSubmit}>
                  <FormFields
                    locations={locations}
                    setInitialValues={setInitialValues}
                    current={current}
                    deleteKeyword={deleteKeyword}
                  />
                </form>
              )}
            </Formik>
          </VStack>
        </Flex>
      )}
    </PageLayout>
  );
};

type FromFieldsProps = {
  /* The locations to match */
  locations: Array<Location>;
  /* The function to set the initial values */
  setInitialValues: (values: any) => void;
  /* The current keyword */
  current: Keyword;
  /* The function to delete a keyword */
  deleteKeyword: (id: string) => void;
};

const FormFields = (props: FromFieldsProps) => {
  const { locations, setInitialValues, current, deleteKeyword } = props;
  const formik = useFormikContext();

  const history = useHistory();

  const values = formik.values as KeywordFormValuesType;

  const addAction = (action: Action) => {
    // take the action and merge in a tempId key with a uuid v4 value
    const newAction = {
      ...action,
      tempId: uuidv4(),
    };

    if (current && values.actions) {
      const newActions = [...values.actions, newAction];
      setInitialValues({
        ...values,
        actions: newActions,
      });
    }
  };

  const removeAction = (action: Action) => {
    if (action.id) {
      const newActions = values.actions?.filter((a) => a.id !== action.id);
      setInitialValues({
        ...values,
        actions: newActions,
      });
    } else {
      const newActions = values.actions?.filter((a) => a.tempId !== action.tempId);
      setInitialValues({
        ...values,
        actions: newActions,
      });
    }
  };

  const handleDelete = () => {
    if (current && current.id) {
      deleteKeyword(current?.id);
    }
    history.push('/automations/keywords');
  };

  return (
    <VStack gap={2}>
      <ActionWrapper
        title={'Keyword Trigger'}
        description={<KeywordDescription keywords={values.keywords} />}
        actionColor={'#00A3FF'}
        actionIcon={<HiKey />}
        saveAction={formik.handleSubmit}
      >
        <VStack gap={2}>
          {current.keywords && (
            <FormFieldWrapper
              label="Keywords"
              tooltip="Keyword you would like to automate."
              name="keywords"
            >
              <KeywordBuilder
                name="keywords"
                options={current?.keywords.map((k) => ({ value: k, label: k })) || []}
              />
            </FormFieldWrapper>
          )}
          {current.negate_keywords && (
            <FormFieldWrapper
              label="Negate Keywords"
              tooltip="Keyword you would like to automate."
              name="negate_keywords"
            >
              <KeywordBuilder
                name="negate_keywords"
                options={
                  current?.negate_keywords?.map((k) => ({ value: k, label: k })) || []
                }
              />
            </FormFieldWrapper>
          )}
          {(values.exact_match === 'false' || values.exact_match === 'true') && (
            <FormFieldWrapper
              label="Keyword Match"
              tooltip="Configure when the keyword is going to trigger."
              name="exact_match"
            >
              <RadioInput
                options={[
                  { value: 'false', type: 'Message Contains Keyword' },
                  {
                    value: 'true',
                    type: 'Message Exactly Matches Keyword',
                  },
                ]}
              />
            </FormFieldWrapper>
          )}
          {locations.length > 1 ? (
            <FormFieldWrapper
              label="Keyword Channels"
              tooltip="Channels you would like to automate this keyword."
              name="locations"
            >
              <MultiSelectCombobox
                placeholder={helpers.displaySelectedItems}
                selectAll={true}
                isDropdown={true}
                options={locations.map((location) => ({
                  type: location.name as string,
                  value: location.id as string,
                }))}
              />
            </FormFieldWrapper>
          ) : null}
        </VStack>
      </ActionWrapper>
      {values.actions.map((action, index) => (
        <ActionWrapper
          key={index}
          title={`${getActionTitle(action)}` || ''}
          description={ActionDescription(action)}
          removeAction={removeAction as any}
          action={action}
          actionColor={`${getActionColor(action)}` || '#2C7675'}
          actionIcon={getActionIcon(action) || ''}
          saveAction={formik.handleSubmit}
        >
          <ActionRenderer
            key={index}
            index={index}
            action={action}
            removeAction={removeAction}
          />
        </ActionWrapper>
      ))}
      <Box>
        <AddAction addAction={addAction} />
      </Box>
      <Flex css={{ mt: 20 }}>
        <HStack>
          <Button type="submit">Update Keyword</Button>
          <ConfirmationDialog
            title="Delete Keyword"
            description="Are you sure you want to delete this keyword?"
            confirmButtonTitle="Yes, Delete Keyword"
            onConfirm={handleDelete}
          >
            <Button type="button" variant="red" ghost={true}>
              Delete Keyword
            </Button>
          </ConfirmationDialog>
        </HStack>
      </Flex>
    </VStack>
  );
};
