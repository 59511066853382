/* eslint-disable react-hooks/exhaustive-deps */
import dayjs from 'dayjs';
import i18next from 'i18next';
import React, { useCallback, useEffect, useState } from 'react';
import { HiX } from 'react-icons/hi';
import { useMedia } from 'react-use';
import { toast } from 'sonner';

import { PreSelectedAudience } from '@/pages/campaigns/quick';
import { useLocations } from '@/pages/settings/organization/locations/context/LocationContext';
import { getAudienceContacts } from '@/shared/api/campaigns';
import * as SequencesAPI from '@/shared/api/sequences';
import { SingleSelect } from '@/shared/components/SingleSelect';
import { useDisclosure } from '@/shared/hooks';
import { Location } from '@/shared/types/locations';
import { Sequence, SequenceStatus, SequenceStep } from '@/shared/types/sequences';
import {
  Box,
  Button,
  Dialog,
  DialogCloseIcon,
  DialogContent,
  DialogOverlay,
  DialogPortal,
  DialogTitle,
  DialogTrigger,
  Drawer,
  DrawerContent,
  DrawerOverlay,
  DrawerPortal,
  Fieldset,
  Flex,
  HStack,
  Label,
  Text,
  VStack,
} from '@/shared/ui';
import { isValidUuid } from '@/shared/utils/validations/validations';

import {
  AudienceSelection,
  AudienceState,
  reduceAudienceToParams,
} from '../../../campaigns/audience';
import { useSequences } from '../../context/SequenceContext';
import { SetSchedule } from './SetSchedule';

type SequenceAudienceProps = {
  /**
   * The sequence object. It is optional and can be null.
   */
  sequence?: Sequence | null;

  /**
   * The child components to be rendered within the SequenceAudience component. It is optional.
   */
  children?: React.ReactNode;

  /**
   * The pre-selected audience object.
   */
  preSelectedAudience: PreSelectedAudience;

  /**
   * The function to set the pre-selected audience.
   */
  setPreSelectedAudience: (preSelectedAudience: PreSelectedAudience) => void;
};

export const SequenceAudience = (props: SequenceAudienceProps) => {
  const { sequence, preSelectedAudience } = props;

  const sequenceContext = useSequences();
  const { sequencesState, getSequences, getSequenceSteps } = sequenceContext;
  const { allSequences, current } = sequencesState;

  useEffect(() => {
    // if there is no sequence lets get the sequences
    if (!sequence) {
      getSequences();
    }
  }, []);

  const [selectedSequenceId, setSelectedSequenceId] = useState<string>(
    sequence?.id || ''
  );

  useEffect(() => {
    // if the sequence is passed in lets set the selected sequence id
    setSelectedSequenceId(sequence?.id || '');
  }, [sequence]);

  const [currentSequenceSteps, setCurrentSequenceSteps] = useState<SequenceStep[]>([]);

  // This allows you to keep the function definition consistent across re-renders
  const getSteps = useCallback(async () => {
    const data = await getSequenceSteps(selectedSequenceId);
    data && setCurrentSequenceSteps(data);
  }, [selectedSequenceId, current?.steps]);

  // Now calling getSequenceSteps directly in the useEffect hook
  useEffect(() => {
    if (selectedSequenceId) {
      getSteps();
    }
  }, [selectedSequenceId, current?.steps]);

  const getSequenceById = (id: string) => {
    // make sure the sequence is not a null array or undefined
    if (allSequences && allSequences.length > 0) {
      const sequence = (allSequences as Sequence[]).find(
        (sequence) => sequence.id === id
      );
      return sequence;
    } else {
      return null;
    }
  };

  const locationContext = useLocations();
  const { locationsState } = locationContext;
  const locations =
    locationsState.allLocations.length < locationsState.locations.length
      ? locationsState.allLocations
      : locationsState.locations;

  // get the location name from the id
  const getLocationById = (id: string) =>
    locations.find((location: Location) => location.id === id);

  // location to set the sequence from
  const [selectedLocationId, setSelectedLocationId] = useState<string>('');

  // contacts to message audience
  const [includeAudience, setIncludeAudience] = useState<AudienceState>({
    contacts: [],
    uploads: [],
    groups: [],
    tags: [],
    manuallyAddedContacts: [],
    wholeCampaignTab: {},
  });

  // the number of messages that will be sent
  const [messageCount, setMessageCount] = useState<number>(0);

  // are we loading the contacts in the audience?
  const [loadingAudience, setLoadingAudience] = useState<boolean>(false);

  // get the list of contacts that will be included or excluded
  useEffect(() => {
    // if there is no location lets not calculate the audience
    if (selectedLocationId) {
      setLoadingAudience(true);

      // do this to invalidate the old contacts
      // while recalculating the new contacts
      setMessageCount(0);

      const audience = reduceAudienceToParams(
        includeAudience,
        preSelectedAudience,
        selectedLocationId
      );

      // this is probably not the best way to do this
      // but it works for now, we should probably add advanced more retry logic
      const getContacts = async () => {
        try {
          const res = await getAudienceContacts(audience, {});
          if (res) {
            setMessageCount(res.audience_count);
            setLoadingAudience(false);
            return res;
          }
        } catch (err) {
          console.error('campaign:getContacts', err);
        }
      };

      getContacts();
    }
  }, [includeAudience, selectedLocationId, preSelectedAudience]);

  // control the dialog state
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [date, setDate] = useState<string>(dayjs(new Date()).format('MM/DD/YYYY'));

  const [time, setTime] = useState('');

  const [timezone, setTimezone] = useState(
    Intl.DateTimeFormat().resolvedOptions().timeZone || ''
  );

  const [scheduleParams, setScheduleParams] = useState({
    day: '',
    month: '',
    year: '',
    hour: '',
    minute: '',
    timezone: '',
  });

  // set the schedule params in the correct format
  useEffect(() => {
    if (time) {
      const day = date.split('/')[1];
      const month = date.split('/')[0];
      const year = date.split('/')[2];
      let hour = time?.split(':')[0];
      const minute = time?.split(':')[1].split(' ')[0];
      const ampm = time?.split(' ')[1];

      if (ampm === 'PM') {
        if (hour === '12') {
          hour = '12';
        } else {
          hour = (Number(hour) + 12).toString();
        }
      } else {
        if (hour === '12') {
          hour = '00';
        }
      }

      setScheduleParams({
        day,
        month,
        year,
        hour,
        minute,
        timezone: timezone || getLocationById(selectedLocationId)?.timezone || '',
      });
    }
  }, [time, date, timezone]);

  const [dateOpen, setDateOpen] = useState(false);

  const [isScheduled, setIsScheduled] = useState(false);

  const onRadioClick = (e: string) => {
    if (e === 'scheduled') {
      setIsScheduled(true);
    } else {
      setIsScheduled(false);
    }
  };

  const dateSelect = (e: any) => {
    const date = dayjs(e.$d).format('MM/DD/YYYY');
    setDate(date);
  };

  const getInitialSequenceStepId = () => {
    const sequenceInitialStepId = (currentSequenceSteps as SequenceStep[]).find(
      (sequenceStep: SequenceStep) => sequenceStep.position === 0
    )?.id;

    return sequenceInitialStepId;
  };

  // add contact(s) to sequence step
  const handleAdd = async () => {
    // get the first step of the selected sequence
    const sequenceInitialStepId = getInitialSequenceStepId();

    const audience = reduceAudienceToParams(
      includeAudience,
      preSelectedAudience,
      selectedLocationId
    );

    if (sequenceInitialStepId && isValidUuid(sequenceInitialStepId)) {
      try {
        await SequencesAPI.addAudienceToSequence(
          audience,
          selectedLocationId,
          sequenceInitialStepId,
          selectedSequenceId,
          isScheduled ? scheduleParams : null
        );
        toast.success(i18next.t('bulk_add_contacts_to_sequence_success') as string);
      } catch (err) {
        toast.error(i18next.t('bulk_add_contacts_to_sequence_failure') as string);
      }

      onClose();
    }
  };

  return (
    <DialogOrDrawer
      isOpen={isOpen}
      onOpen={onOpen}
      onClose={onClose}
      button={props.children}
    >
      <VStack gap="2">
        <Fieldset>
          <Label>Select Sequence</Label>
          <SingleSelect
            selectItem={selectedSequenceId}
            setSelectItem={setSelectedSequenceId}
            closeOnClick={true}
            options={
              (allSequences as Sequence[])
                .filter((sequence: Sequence) => sequence.status === SequenceStatus.ACTIVE)
                .map((sequence: Sequence) => ({
                  type: sequence?.title || '',
                  value: sequence?.id || '',
                })) || []
            }
            defaultPlaceholder={
              getSequenceById(selectedSequenceId)?.title || 'Select a Sequence'
            }
            isDropdown={true}
          />
        </Fieldset>
        <Fieldset>
          <Label>Select From Channel</Label>
          <SingleSelect
            selectItem={selectedLocationId}
            setSelectItem={setSelectedLocationId}
            closeOnClick={true}
            options={locations.map((location) => ({
              type: location.name || '',
              value: location.id,
            }))}
            defaultPlaceholder={
              getLocationById(selectedLocationId)?.name || 'Select a Channel'
            }
            isDropdown={true}
          />
        </Fieldset>
        <Fieldset>
          <Label>Select Audience</Label>
          <Box css={{ p: 0, m: 0 }}>
            <AudienceSelection
              locationId={selectedLocationId}
              selectedAudience={includeAudience}
              setSelectedAudience={setIncludeAudience}
              isInbox={false}
              isContactsPage={false}
              isGroupsPage={false}
              isUploadsPage={false}
              isCampaignsEditPage={false}
              isCampaignsPage={true}
              clearStatesOnUnmount={false}
              preSelectedAudience={props.preSelectedAudience}
              setPreSelectedAudience={props.setPreSelectedAudience}
            />
          </Box>
        </Fieldset>
        <Fieldset>
          <Label>Schedule Add to Sequence</Label>
          <Box css={{ p: 0, m: 0 }}>
            <SetSchedule
              show={isScheduled}
              onRadioClick={onRadioClick}
              open={dateOpen}
              setOpen={setDateOpen}
              date={date}
              time={time}
              setTime={setTime}
              dateSelect={dateSelect}
              scheduleParams={scheduleParams}
              location={getLocationById(selectedLocationId) || ({} as Location)}
              timezone={timezone}
              setTimezone={setTimezone}
            />
          </Box>
        </Fieldset>
        <Flex css={{ width: '100%' }} justify="between" align="center">
          <Flex>
            <Text>
              {loadingAudience
                ? `Loading Audience's Contacts`
                : `Audience Size: ${messageCount}`}
            </Text>
          </Flex>
          <HStack>
            <Button variant="gray" onClick={onClose}>
              Cancel
            </Button>
            <Button onClick={() => handleAdd()}>Add to Sequence</Button>
          </HStack>
        </Flex>
      </VStack>
    </DialogOrDrawer>
  );
};

type DialogOrDrawerProps = {
  /**
   * The child components to be rendered within the DialogOrDrawer
   */
  children: React.ReactNode;
  /**
   * The button that triggers the DialogOrDrawer
   * If no button is provided, a default button will be rendered
   * with the text "Add Contacts"
   */
  button?: React.ReactNode;
  isOpen: boolean;
  onOpen: () => void;
  onClose: () => void;
};

const DialogOrDrawer = (props: DialogOrDrawerProps) => {
  const { isOpen, onOpen, onClose, children } = props;

  // Determine if the viewport is wide enough to display a dialog
  const isDialog = useMedia('(min-width: 768px)');

  // If the viewport is wide enough, render a Dialog
  return isDialog ? (
    <Dialog open={isOpen} modal={false}>
      <DialogTrigger asChild onClick={onOpen}>
        {props.button ? props.button : <Button>Add Contacts</Button>}
      </DialogTrigger>
      <DialogPortal>
        <DialogOverlay as="div">
          <DialogContent
            onEscapeKeyDown={onClose}
            onPointerDownOutside={onClose}
            css={{ width: 600 }}
          >
            <DialogTitle css={{ fontSize: 20, fontWeight: 700, marginBottom: 16 }}>
              Add Contacts to Sequence
            </DialogTitle>
            {children}
            <DialogCloseIcon onClick={onClose} size="2">
              <HiX size="15px" style={{ color: 'white' }} />
            </DialogCloseIcon>
          </DialogContent>
        </DialogOverlay>
      </DialogPortal>
    </Dialog>
  ) : (
    // If the viewport is not wide enough, render a Drawer
    <Drawer open={isOpen} modal={false}>
      <DialogTrigger asChild onClick={onOpen}>
        {props.button ? props.button : <Button>Add Contacts</Button>}
      </DialogTrigger>
      <DrawerPortal>
        <DrawerOverlay />
        <DrawerContent
          onEscapeKeyDown={onClose}
          onPointerDownOutside={onClose}
          side="bottom"
          css={{ height: '90%', overflowY: 'scroll' }}
        >
          <Box
            css={{
              px: 30,
              py: 40,
            }}
          >
            {children}
          </Box>
        </DrawerContent>
      </DrawerPortal>
    </Drawer>
  );
};
