import dayjs from 'dayjs';
import { Formik } from 'formik';
import React, { useEffect, useRef } from 'react';
import { HiX } from 'react-icons/hi';
import * as Yup from 'yup';

import {
  ConversationHeaderContact,
  ConversationHeaderContainer,
} from '@/pages/inbox/conversation/header';
import {
  StatusIcon,
  StyledMessage,
  StyledMessageFooter,
} from '@/pages/inbox/conversation/items';
import { useLocations } from '@/pages/settings/organization/locations/context/LocationContext';
import { FormFieldWrapper, SelectCombobox } from '@/shared/components/forms';
import { helpers } from '@/shared/components/forms/helpers';
import { renderText } from '@/shared/components/markdown/MarkdownRenderer';
import { useDisclosure } from '@/shared/hooks';
import { Location } from '@/shared/types/locations';
import {
  Box,
  Button,
  CustomScrollbar,
  Drawer,
  DrawerContent,
  Flex,
  IconButton,
  VStack,
} from '@/shared/ui';
import { TIME_STAMP } from '@/shared/utils/timestamps';
import { styled } from '@/stitches.config';

import { useQuestions } from './context/QuestionsContext';
import { SearchEditor } from './SearchEditor';

export const SearchQuestion = (): JSX.Element => {
  const questionsContext = useQuestions();
  const { questionsState } = questionsContext;
  const { testMessages, questions } = questionsState;
  const { isOpen, onClose, onOpen } = useDisclosure();

  const { searchQuestions } = questionsContext;
  const location = useLocations();
  const { locations } = location.locationsState;

  const elementRef = useRef<null | HTMLDivElement>(null);
  useEffect(() => elementRef.current?.scrollIntoView(), [testMessages]);

  return (
    <>
      {questions.length > 0 ? (
        <Button variant="gray" onClick={onOpen}>
          Test Questions
        </Button>
      ) : null}
      <Drawer open={isOpen}>
        <DrawerContent
          onEscapeKeyDown={onClose}
          onPointerDownOutside={onClose}
          css={{ width: '65%' }}
        >
          <Formik
            initialValues={{
              question: '',
              location: locations[0]?.id,
            }}
            validationSchema={Yup.object({
              question: Yup.string().required('Required').trim(),
              location: Yup.string()
                .required('Required')
                .oneOf(
                  locations.map((location) => location.id),
                  'Select from dropdown'
                ),
            })}
            onSubmit={async (values, { resetForm }) => {
              const params = {
                text: values.question,
                location_id: values.location,
              };

              if (params.text.length) {
                resetForm({
                  values: {
                    question: '',
                    location: values.location,
                  },
                });
                try {
                  searchQuestions(params);
                } catch (e) {
                  console.log(e);
                }
              }
            }}
          >
            {(formik) => (
              <form onSubmit={formik.handleSubmit} style={{ height: '100%' }}>
                <ConversationHeaderContainer justify="between" align="center">
                  <Flex>
                    <ConversationHeaderContact
                      conversation={{
                        contact: {
                          name: 'Test Automation',
                          phone: '1234567890',
                          email: 'test@automation.com',
                        },
                      }}
                      channel={
                        locations.find(
                          (location) => location.id === formik.values.location
                        ) as Location
                      }
                    />
                  </Flex>
                  <Flex>
                    <IconButton onClick={onClose} size={2}>
                      <HiX size={15} />
                    </IconButton>
                  </Flex>
                </ConversationHeaderContainer>
                <Flex
                  direction="column"
                  justify="between"
                  css={{
                    position: 'relative',
                    height: 'calc(100% - 100px)',
                    flex: 1,
                    overflow: 'auto',
                    p: '15px 15px 0',
                  }}
                >
                  <CustomScrollbar>
                    <Box>
                      {testMessages.map((message, index) => (
                        <Flex direction="column" key={index} css={{ p: 10 }}>
                          {message.direction === 'inbound' ? (
                            <Box>
                              <DemoMessage direction="inbound_sms" css={{ mr: 'auto' }}>
                                {renderText(message.text)}
                              </DemoMessage>
                              <StyledMessageFooter justify="start">
                                <Flex align="center">
                                  <Box css={{ mr: 5 }}>{dayjs().format(TIME_STAMP)}</Box>
                                  <Box css={{ fontWeight: 500 }}>Customer</Box>
                                </Flex>
                              </StyledMessageFooter>
                            </Box>
                          ) : (
                            <Flex direction="column">
                              {message.text ? (
                                <DemoMessage
                                  direction="outbound_sms"
                                  css={{
                                    ml: 'auto',
                                  }}
                                >
                                  {renderText(message.text)}
                                </DemoMessage>
                              ) : null}
                              <StyledMessageFooter justify="end">
                                {message.text && (
                                  <MessageSuccess status="delivered" error={null} />
                                )}
                                {!message.text && null}
                              </StyledMessageFooter>
                            </Flex>
                          )}
                        </Flex>
                      ))}
                      <div ref={elementRef} />
                    </Box>
                  </CustomScrollbar>
                  <VStack css={{ pt: 10 }}>
                    {locations.length > 1 ? (
                      <FormFieldWrapper
                        label="Channel"
                        tooltip="Choose the channel you would like to test an automation."
                        name="location"
                      >
                        <SelectCombobox
                          placeholder={helpers.displaySelectedItem}
                          isDropdown={true}
                          options={locations.map((location) => ({
                            type: location?.name || location?.address || '',
                            value: location.id,
                          }))}
                        />
                      </FormFieldWrapper>
                    ) : null}
                    <FormFieldWrapper
                      label="Question"
                      tooltip="Question you want to test for automation."
                      name="question"
                    >
                      <SearchEditor selectedLocation={formik.values.location} />
                    </FormFieldWrapper>
                  </VStack>
                </Flex>
              </form>
            )}
          </Formik>
        </DrawerContent>
      </Drawer>
    </>
  );
};

type MessageStatusProps = {
  status?: string | null;
  error?: string | null;
};

const MessageSuccess = ({ status, error }: MessageStatusProps) => {
  return (
    <Flex align="center">
      <Box css={{ mr: 5, fontWeight: 500 }}>{'Automated by Whippy'}</Box>
      <Box>{dayjs().format(TIME_STAMP)}</Box>
      <Box css={{ mr: 5 }}></Box>
      <StatusIcon status={status} error={error} />
    </Flex>
  );
};

const DemoMessage = styled(StyledMessage, {
  fontSize: 13,
  py: 15,
  px: 20,
  maxWidth: '65%',
  lineHeight: 1.5,
});
