import React, { useState } from 'react';
import { HiX } from 'react-icons/hi';

import { useDisclosure } from '@/shared/hooks';
import {
  Box,
  Button,
  Dialog,
  DialogClose,
  DialogCloseIcon,
  DialogContent,
  DialogFooter,
  DialogOverlay,
  DialogPortal,
  DialogTitle,
  DialogTrigger,
  Fieldset,
  Flex,
  Input,
  Label,
  VStack,
} from '@/shared/ui';

import { useCampaignsContext } from '../context/CampaignsContext';

type EditCampaignNameProps = {
  /** pass the component that is going to trigger the alert dialog */
  children: React.ReactNode;
  /** initial name of the current campaign */
  initialCampaignName: string;
};

export const EditCampaignName = (props: EditCampaignNameProps) => {
  const { children, initialCampaignName } = props;
  const { isOpen, onOpen, onClose } = useDisclosure();

  const campaignsContext = useCampaignsContext();
  const { updateOneCampaign, campaignsState } = campaignsContext;
  const { current } = campaignsState;

  const [campaignName, setCampaignName] = useState(initialCampaignName);

  const onCampaignNameSave = () => {
    // Pass trigger params for updating, currently just passing []
    if (current && current.id) {
      updateOneCampaign(current.id, { ...current, title: campaignName }, true);
    }
    onClose();
  };

  return (
    <Dialog open={isOpen}>
      <DialogTrigger asChild onClick={onOpen}>
        {children}
      </DialogTrigger>
      <DialogPortal>
        <DialogOverlay css={{ zIndex: 9999 }} />
        <DialogContent
          onEscapeKeyDown={onClose}
          onPointerDownOutside={onClose}
          style={{ overflow: 'auto' }}
        >
          <DialogTitle css={{ fontSize: 20, fontWeight: 700, marginBottom: 16 }}>
            Edit Campaign Name
          </DialogTitle>
          <VStack gap={2}>
            <Fieldset>
              <Label htmlFor="campaign-name">
                <Flex>
                  Campaign Name{' '}
                  <Box css={{ color: 'red', ml: 5 }}>
                    {campaignName.length < 1 ? '(min 1 characters required)' : null}
                  </Box>
                </Flex>
              </Label>
              <Input
                id="campaign-name"
                value={campaignName}
                onChange={(e) => setCampaignName(e.target.value)}
                required={true}
              />
            </Fieldset>
          </VStack>
          <DialogFooter justify="end">
            <DialogClose asChild>
              <Button variant="gray" css={{ mr: '$1' }} onClick={onClose}>
                Cancel
              </Button>
            </DialogClose>
            <DialogClose asChild>
              <Button onClick={onCampaignNameSave} disabled={campaignName.length < 1}>
                Save Name
              </Button>
            </DialogClose>
          </DialogFooter>
          <DialogCloseIcon onClick={onClose} size="2">
            <HiX size="15px" style={{ color: 'white' }} />
          </DialogCloseIcon>
        </DialogContent>
      </DialogPortal>
    </Dialog>
  );
};
