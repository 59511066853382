/* eslint-disable react-hooks/exhaustive-deps */
import { debounce, DebouncedFunc } from 'lodash';
import React, { useCallback, useState } from 'react';
import { useEffect } from 'react';

import { handleFilterChange, handleSortChange } from '@/pages/data/utils/filterActions';
import { CombinedFilters } from '@/shared/components/filterBuilder/CombinedFilters';
import { default_invites_object } from '@/shared/components/filterBuilder/objects/invites';
import { SearchFilters } from '@/shared/types/contacts';
import { FilterType, Sort } from '@/shared/types/filter';
import { Box, Flex } from '@/shared/ui';

import { useLocations } from '../locations/context/LocationContext';
import { initialUsersState, useUsers } from './context/UserContext';
import { invitesSortConfig } from './filterConfig';
import { InvitesTable } from './InvitesTable';

export const defaultSort: Array<Sort> = [
  {
    label: 'Invited At',
    column: 'inserted_at',
    order: 'desc',
    resource: 'invite',
    id: null,
  },
];

export const InvitesView = () => {
  const { updateInvitesFilterParams, userState } = useUsers();

  const location = useLocations();
  const { allLocations } = location.locationsState;
  // value of the search input field
  const [searchValue, setSearchValue] = useState('');
  const [activeFilters, setActiveFilters] = useState<FilterType[]>([]);

  useEffect(() => {
    resetFilters();
  }, []);

  const resetFilters = () => {
    setSearchValue('');
    setActiveFilters([]);
    updateInvitesFilterParams({
      ...initialUsersState.invitesFilterParams,
      sort: defaultSort,
    });
  };

  const debouncedUpdate = useCallback(
    debounce((props: SearchFilters) => {
      updateInvitesFilterParams(props);
    }, 1000),
    []
  );

  const handleQuickSearch = (
    debouncedUpdate: DebouncedFunc<(props: SearchFilters) => void>,
    filters: SearchFilters,
    value: string
  ) => {
    const formattedValue = value.trim();
    const quickFilters = [
      {
        column: 'email',
        comparison: 'ilike',
        value: `%${formattedValue}%`,
        resource: 'invite',
      },
    ];

    debouncedUpdate({
      ...filters,
      searchFilter: quickFilters,
      offset: 0,
    });
  };

  return (
    <Flex direction="column" css={{ flex: '1 1 auto' }}>
      <Box css={{ paddingBottom: 24 }}>
        <CombinedFilters
          quickSearchPlaceholder="Search by Email"
          quickSearchValue={searchValue}
          setQuickSearchValue={(value: string) => {
            setSearchValue(value);
            handleQuickSearch(debouncedUpdate, userState.invitesFilterParams, value);
          }}
          defaultObjects={[default_invites_object]}
          customObjects={[]}
          activeFilters={activeFilters}
          setFilters={(value: Array<FilterType>) => {
            setActiveFilters(value);
            handleFilterChange(debouncedUpdate, userState.invitesFilterParams, value);
          }}
          sortConfig={invitesSortConfig}
          activeSort={userState.invitesFilterParams.sort}
          onSortUpdate={(value: Array<Sort>) =>
            handleSortChange(
              updateInvitesFilterParams,
              userState.invitesFilterParams,
              value
            )
          }
        />
      </Box>
      {allLocations.length > 0 && <InvitesTable />}
    </Flex>
  );
};
