/* eslint-disable react-hooks/exhaustive-deps */
import { Formik, useFormikContext } from 'formik';
import { Dispatch, SetStateAction, useState } from 'react';
import { useHistory } from 'react-router-dom';
import * as Yup from 'yup';

import { useLocations } from '@/pages/settings/organization/locations/context/LocationContext';
import {
  FormFieldWrapper,
  helpers,
  MultiSelectCombobox,
  RadioInput,
} from '@/shared/components/forms';
import { KeywordBuilder } from '@/shared/components/forms/KeywordBuilder';
import {
  ActionRenderer,
  defaultActions,
  sendMessageAction,
} from '@/shared/components/triggers';
import { SelectActionType } from '@/shared/components/triggers/actions/AddAction';
import { Keyword } from '@/shared/types/keywords';
import { Location } from '@/shared/types/locations';
import { Action, ActionTypes } from '@/shared/types/triggers/actions';
import { Button, Fieldset, Flex, HStack, Label, VStack } from '@/shared/ui';

import { useKeywords } from './context/KeywordsContext';
import { KeywordFormValuesType } from './EditKeywordForm';

export const KeywordForm = () => {
  const location = useLocations();
  const { locations } = location.locationsState;

  const keywordsContext = useKeywords();
  const { createKeyword } = keywordsContext;

  const [initialvalues, setInitialValues] = useState<KeywordFormValuesType>({
    keywords: [],
    negate_keywords: [],
    exact_match: false,
    locations: locations.map((location) => location.id) || [],
    actions: [sendMessageAction as unknown as Action],
  });

  // a state variable to store step0's user input
  const [savedInput, setSavedInput] = useState<KeywordFormValuesType>({
    keywords: [],
    negate_keywords: [],
    exact_match: false,
    locations: locations.map((location) => location.id) || [],
    actions: [sendMessageAction as unknown as Action],
  });

  const [actionType, setActionType] = useState<ActionTypes>(ActionTypes.SEND_MESSAGE);

  const history = useHistory();

  const handleSubmit = async (values: KeywordFormValuesType) => {
    const params: Keyword = {
      keywords: values.keywords as string[],
      negate_keywords: values.negate_keywords as string[],
      enabled: true,
      exact_match: values.exact_match as boolean,
      location_ids: values?.locations || [],
      actions: values.actions as Action[],
    };
    try {
      const keyword = await createKeyword(params);
      console.log(keyword);
      if (keyword && keyword?.id) {
        history.push(`/automations/keywords/${keyword.id}`);
      }
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <VStack gap={3} css={{ width: '100%' }}>
      <Formik
        enableReinitialize
        initialValues={initialvalues}
        validationSchema={Yup.object({
          keywords: Yup.array().test(
            'len',
            'Required',
            (arr) => Array.isArray(arr) && arr.length > 0
          ),
          negate_keywords: Yup.array(),
          exact_match: Yup.boolean().required('Required'),
          locations: Yup.array(),
          actions: Yup.array(),
        })}
        onSubmit={async (values: KeywordFormValuesType) => {
          try {
            handleSubmit(values);
          } catch (e) {
            console.log(e);
          }
        }}
      >
        {(formik) => (
          <form onSubmit={formik.handleSubmit}>
            <VStack gap={4}>
              <KeywordFormFields
                locations={locations}
                setInitialValues={setInitialValues}
                actionType={actionType}
                setActionType={setActionType}
                savedInput={savedInput}
                setSavedInput={setSavedInput}
              />
            </VStack>
          </form>
        )}
      </Formik>
    </VStack>
  );
};

type FromFieldsProps = {
  /** array of location objects */
  locations: Array<Location>;
  /** function to set initial values */
  setInitialValues?: (values: any) => void;
  /** action type */
  actionType: ActionTypes;
  /** set action type */
  setActionType: Dispatch<SetStateAction<ActionTypes>>;
  /** savedInput on step 0 */
  savedInput?: KeywordFormValuesType;
  /** function to set savedInput */
  setSavedInput?: (values: any) => void;
};

export const KeywordFormFields = (props: FromFieldsProps) => {
  const { locations, actionType, setActionType, savedInput, setSavedInput } = props;
  const formik = useFormikContext();
  const [step, setStep] = useState(0);

  const values = formik.values as KeywordFormValuesType;

  const defaultAction = (type: ActionTypes) =>
    defaultActions?.find((action) => action.type === type)?.default;

  const handleChange = (a: ActionTypes) => {
    setActionType(a);
    formik.setFieldValue('actions', [defaultAction(a)]);
  };

  // if values.keywords is empty, then return true
  const isKeywordsEmpty = () => {
    if (!values.keywords) return true;
    if (values.keywords.length === 0) {
      return true;
    }
    return false;
  };

  // generate keywords options for the KeywordBuilder component based on the values.keywords
  const generateKeywordsOptions = () => {
    // return an array of objects with value and label
    return (values.keywords || []).map((each) => ({ value: each, label: each }));
  };

  // generate negate_keywords options for the KeywordBuilder component based on the values.negate_keywords
  const generateNegateKeywordsOptions = () => {
    // return an array of objects with value and label (we need each is string here for type guard)
    return (values.negate_keywords || [])
      .filter((each): each is string => each !== null)
      .map((each) => ({ value: each, label: each }));
  };

  // when user clicks 'Next - Add Action', we save the user input to savedInput and setStep(1)
  const handleNext = () => {
    setSavedInput && setSavedInput(values);
    setStep(1);
  };

  // when user clicks 'Back - Edit Keyword', we set the formik values to savedInput and setStep(0)
  const handleBack = () => {
    formik.setValues(savedInput);
    setStep(0);
  };

  return (
    <VStack gap={2} css={{ width: '100%' }}>
      {step === 0 && (
        <>
          <FormFieldWrapper
            label="Keywords"
            tooltip="Keyword you would like to automate."
            name="keywords"
          >
            <KeywordBuilder name="keywords" options={generateKeywordsOptions()} />
          </FormFieldWrapper>
          <FormFieldWrapper
            label="Negate Keywords"
            tooltip="Negate Keywords you don't want to automate."
            name="negate_keywords"
          >
            <KeywordBuilder
              name="negate_keywords"
              options={generateNegateKeywordsOptions()}
            />
          </FormFieldWrapper>
          <FormFieldWrapper
            label="Keyword Match"
            tooltip="Configure when the keyword is going to trigger."
            name="exact_match"
          >
            <RadioInput
              options={[
                { value: 'false', type: 'Message Contains Keyword' },
                { value: 'true', type: 'Message Exactly Matches Keyword' },
              ]}
            />
          </FormFieldWrapper>
          {locations.length > 1 ? (
            <FormFieldWrapper
              label="Keyword Channels"
              tooltip="Channels you would like to automate this keyword."
              name="locations"
            >
              <MultiSelectCombobox
                placeholder={helpers.displaySelectedItems}
                selectAll={true}
                isDropdown={true}
                options={locations.map((location) => ({
                  type: location.name as string,
                  value: location.id as string,
                }))}
              />
            </FormFieldWrapper>
          ) : null}
          <Flex css={{ width: '100%' }} justify="end">
            <HStack>
              <Button type="button" onClick={handleNext} disabled={isKeywordsEmpty()}>
                Next - Add Action
              </Button>
            </HStack>
          </Flex>
        </>
      )}
      {step === 1 && (
        <>
          <Fieldset>
            <Label>Select Action Type</Label>
            <SelectActionType action_type={actionType} setAction={handleChange} />
          </Fieldset>
          {values.actions.map((action, index) => (
            <ActionRenderer key={index} index={index} action={action} />
          ))}
          <Flex css={{ width: '100%' }} justify="end">
            <HStack>
              <Button variant="gray" type="button" onClick={handleBack}>
                Back - Edit Keyword
              </Button>
              <Button type="submit">Save Keyword Automation</Button>
            </HStack>
          </Flex>
        </>
      )}
    </VStack>
  );
};
