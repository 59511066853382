/* eslint-disable react-hooks/exhaustive-deps */
import { debounce } from 'lodash';
import React, { useCallback } from 'react';
import { useMedia } from 'react-use';

import { CurrentFilters } from '@/pages/campaigns/hooks/useFilterCampaigns';
import { useLocations } from '@/pages/settings/organization/locations/context/LocationContext';
import { useUsers } from '@/pages/settings/organization/users/context/UserContext';
import { MultiSelect } from '@/shared/components/MultiSelect';
import { Campaign } from '@/shared/types/campaigns';
import { Location } from '@/shared/types/locations';
import { User } from '@/shared/types/users';
import { Box, Flex, HStack, Input } from '@/shared/ui';

type CampaignsSearchInputProps = {
  campaigns: Array<Campaign>;
  filters: CurrentFilters;
  changeFilters: (newFilters: CurrentFilters) => void;
  setDidFiltersChange: (newDidFiltersChange: boolean) => void;
};

export const CampaignSearch = (props: CampaignsSearchInputProps): JSX.Element => {
  const isLargeScreen = useMedia('(min-width: 1600px)');

  const onSelect = (currentFilter: CurrentFilters) => {
    props.changeFilters(currentFilter);
  };

  const debounceFn = useCallback(debounce(onSelect, 10), []);

  // locations
  const locationsContext = useLocations();
  const { locationsState } = locationsContext;

  const locations =
    locationsState.allLocations.length < locationsState.locations.length
      ? locationsState.allLocations
      : locationsState.locations;

  // users
  const userContext = useUsers();
  const users = userContext.userState.users;

  return (
    <Flex css={{ px: 30, mb: 20 }}>
      <HStack gap={1} css={{ width: '100%' }}>
        <Flex align="center" css={{ width: isLargeScreen ? '100%' : '40%' }}>
          <Input
            value={props.filters.title}
            placeholder="Search Campaigns"
            onChange={(e) => {
              props.setDidFiltersChange(true);
              props.changeFilters({ ...props.filters, title: e.target.value });
            }}
            css={{ width: '409px' }}
          />
        </Flex>
        {locations.length > 0 && (
          <Box css={{ width: '30%' }}>
            <MultiSelect
              defaultPlaceholder="Channels"
              defaultSelectedItems={[]}
              isDropdown={true}
              options={locations.map((location: Location) => ({
                type: location?.name || '',
                value: location.id,
              }))}
              parentSelectedItems={props.filters}
              setParentSelectedItems={debounceFn}
              isCampaigns={true}
              setDidFiltersChange={props.setDidFiltersChange}
            />
          </Box>
        )}
        {users.length > 0 && (
          <Box css={{ width: '30%' }}>
            <MultiSelect
              defaultPlaceholder="Users"
              defaultSelectedItems={[]}
              isDropdown={true}
              options={users.map((user: User) => ({
                type: user.name || user.email || '',
                value: user.id,
              }))}
              parentSelectedItems={props.filters}
              setParentSelectedItems={debounceFn}
              isCampaigns={true}
              setDidFiltersChange={props.setDidFiltersChange}
            />
          </Box>
        )}
      </HStack>
    </Flex>
  );
};
