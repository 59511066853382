import { Contact } from '@/shared/types/contacts';
import { FilterItem, Sort } from '@/shared/types/filter';
import { TagBulkActionType } from '@/shared/types/tags';

import { api } from '../../api';
import { CampaignSourceTuple, SequenceSourceTuple } from '../../sequences';

export const searchContacts = async (
  filter: Array<FilterItem> = [],
  sort: Array<Sort> = [],
  limit = 100,
  offset = 0,
  signal?: AbortSignal
) => {
  const { data } = await api.post(
    'v2/contacts/search',
    {
      filter,
      sort,
      limit,
      offset,
    },
    { signal }
  );
  return data as { data: Array<Contact>; total: number };
};

export const bulkUpdateTags = async (
  action: TagBulkActionType,
  tagIds: string[],
  filter: FilterItem[],
  filterSource?: CampaignSourceTuple | SequenceSourceTuple
) => {
  let selection_args = {};
  if (filterSource && filterSource[0] == 'campaign') {
    selection_args = {
      args: {
        campaign_id: filterSource[1],
      },
      selection: {
        campaign_contact_selection: {
          filter,
        },
      },
    };
  } else if (filterSource && filterSource[0] == 'sequence') {
    selection_args = {
      args: {
        sequence_id: filterSource[1],
      },
      selection: {
        step_contact_selection: {
          filter,
        },
      },
    };
  } else {
    selection_args = {
      selection: {
        contact_selection: {
          filter,
        },
      },
    };
  }
  const body = {
    ...selection_args,
    bulk_action: 'tags.' + action,
    resource: {
      //tag ids
      ids: tagIds,
    },
  };
  try {
    await api.post<{ data: { message: string } }>('/v2/bulk_actions', body);
  } catch (err) {
    console.error(err);
  }
};
