import {
  HiChartPie,
  HiChat,
  HiDatabase,
  HiLightningBolt,
  HiLink,
  HiMailOpen,
  HiPaperAirplane,
  HiSparkles,
  HiSpeakerphone,
  HiStar,
  HiUserGroup,
} from 'react-icons/hi';

export type tabDataType = {
  icon?: React.ReactNode;
  textColor?: string;
  title: string;
  path: string;
  hidden: boolean;
  dropdown?: Array<tabDataType>;
};

export type navigationDataType = {
  showSearch: boolean;
  tabsData: Array<tabDataType>;
};

const tabsData: Array<tabDataType> = [
  {
    icon: <HiMailOpen />,
    title: 'Inbox',
    path: '/inbox',
    hidden: false,
  },
  {
    icon: <HiDatabase />,
    title: 'Data',
    path: '/data/contacts',
    hidden: false,
  },
  {
    icon: <HiUserGroup />,
    title: 'Contacts',
    path: '/contacts',
    hidden: false,
  },
  {
    icon: <HiSpeakerphone />,
    title: 'Campaigns',
    path: '/campaigns',
    hidden: false,
    dropdown: [
      {
        title: 'SMS',
        path: '/campaigns',
        hidden: false,
        icon: <HiChat />,
      },
      {
        title: 'Links',
        path: '/campaigns/links',
        icon: <HiLink />,
        hidden: false,
      },
      {
        title: 'Domains',
        path: '/campaigns/domains',
        hidden: true,
      },
    ],
  },
  {
    icon: <HiPaperAirplane style={{ rotate: '90deg' }} />,
    title: 'Sequences',
    path: '/sequences',
    hidden: false,
  },
  {
    icon: <HiSparkles />,
    // textColor: '#A157FF',
    title: 'Agents',
    path: '/ai/agents',
    hidden: false,
  },
  {
    icon: <HiLightningBolt />,
    title: 'Automations',
    path: '/automations/keywords',
    hidden: false,
  },
  {
    icon: <HiStar />,
    title: 'Reviews',
    path: '/reviews/all',
    hidden: false,
  },
  {
    icon: <HiChartPie />,
    title: 'Reports',
    path: '/reports/overview',
    hidden: false,
  },
];

export const navigationData: navigationDataType = {
  showSearch: true,
  tabsData,
};
