import React from 'react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { HiOfficeBuilding } from 'react-icons/hi';

import { fetchOrganizationsInfo } from '@/shared/api/auth';
import { updateWebchat } from '@/shared/api/webchat';
import { Table } from '@/shared/components/Table';
import { Location } from '@/shared/types/locations';
import { Box, Checkbox, EmptyState, Flex, Td, Tr } from '@/shared/ui';
import { formatPhoneNumber } from '@/shared/utils/validations/validations';

import { AddLocation } from '../locations/AddLocation';
import { useLocations } from '../locations/context/LocationContext';
import {
  EmptyStateDescription,
  LocationAddress,
  LocationName,
} from '../locations/LocationsTable';

const WebchatLocations = () => {
  const { locationsState, updateLocation } = useLocations();
  const { locations } = locationsState;
  const [webchatId, setWidgetId] = useState('');

  // GET webchat settings
  const fetchWidget = async () => {
    const { organization } = await fetchOrganizationsInfo();

    // If the webchat settings id exists set the id
    if (organization.widget_settings && organization.widget_settings.id) {
      setWidgetId(organization.widget_settings.id);
    } else {
      // If there is no id update the webchat, by adding the default color
      const setting = await updateWebchat({ color: '#294CCB' });
      setWidgetId(setting.id);
    }
  };

  // Add an organization location to a widget
  const toggleLocation = useCallback(
    (location: Location) => {
      if (location.widget_setting_id != null) {
        updateLocation({ widget_setting_id: null, id: location.id });
      } else {
        updateLocation({ widget_setting_id: webchatId, id: location.id });
      }
    },
    [updateLocation, webchatId]
  );

  useEffect(() => {
    fetchWidget();
  }, []);

  const formattedLocations = locations.map((location: Location) => {
    return {
      ...location,
      ...{
        phone: formatPhoneNumber(location.phone),
        action: (
          <Checkbox
            checked={location.widget_setting_id != null}
            onCheckedChange={() => toggleLocation(location)}
          />
        ),
      },
    };
  });

  const data = useMemo(() => formattedLocations, [formattedLocations]);

  const columns = useMemo(
    () => [
      {
        Header: '',
        accessor: 'action',
        Cell: (props: any) => (
          <Box css={{ position: 'relative' }}>
            <Checkbox
              checked={props.row.original.widget_setting_id != null}
              onCheckedChange={() => toggleLocation(props.row.original)}
            />
          </Box>
        ),
      },
      {
        Header: 'Channels',
        colWidth: '100%',
        accessor: 'location',
        Cell: (props: { row: { original: Location } }) => (
          <Flex direction="column">
            <LocationName>{props.row.original.name}</LocationName>
            <LocationAddress>{props.row.original.address}</LocationAddress>
          </Flex>
        ),
      },
      {
        Header: ' ',
        accessor: (d: { phone: string }) => `<p>${d.phone ? d.phone : ''}</p>`,
        Cell: (props: { value: any; row: any }) => (
          <Box
            dangerouslySetInnerHTML={{ __html: props.value }}
            css={{ whiteSpace: 'nowrap' }}
          />
        ), // accessor is the "key" in the data
      },
    ],
    [toggleLocation]
  );

  // 2. Empty state
  if (locations.length === 0) {
    return (
      <EmptyState
        icon={<HiOfficeBuilding size="30px" />}
        description={<EmptyStateDescription />}
        title={'Channels'}
        buttonComponent={<AddLocation />}
      />
    );
  }

  // 3. Channels Table View
  return (
    <Table
      data={data}
      columns={columns}
      caption="Webchat Channels Table"
      sortable={true}
      totalCount={data.length}
      empty={
        <Tr>
          <Td colSpan={columns.length} css={{ textAlign: 'center' }}>
            <Box css={{ p: '6.5px 0' }}>No locations were found.</Box>
          </Td>
        </Tr>
      }
    />
  );
};

export default WebchatLocations;
