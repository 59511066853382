import { useRef } from 'react';
import { FormEvent, useState } from 'react';

import { MessageEditorV2 } from '@/shared/components/editor/v2';
import { Location as LocationType } from '@/shared/types/locations';
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
  Box,
  Button,
  Divider,
  Fieldset,
  Flex,
  Label,
  RadioGroup,
  RadioGroupIndicator,
  RadioGroupRadio as Radio,
  StyledRadioIndicator,
  Text,
  VStack,
} from '@/shared/ui';

import { useLocations } from '../context/LocationContext';

export const BusinessHoursAutoResponses = () => {
  const { locationsState, updateLocation, setCurrent } = useLocations();
  const { current } = locationsState;

  // Reference to the textarea HTML element
  const textareaRef = useRef<HTMLTextAreaElement>(null);

  // Initialize state for the message during open hours
  const [openMessage, setOpenMessage] = useState<string>(
    current?.automatic_response_open || ''
  );
  const [closedMessage, setClosedMessage] = useState<string>(
    current?.automatic_response_closed || ''
  );

  const onRadioButtonChange = (value: string) => {
    const updatedParams = { ...current, send_automatic_response_when: value };
    if (!updatedParams.id) return;
    if (!current) return;
    updateLocation(updatedParams as LocationType).then(() => {
      setCurrent(updatedParams as LocationType);
    });
  };

  const onSubmit = (e: FormEvent<HTMLFormElement>, locationUpdates: any) => {
    e.preventDefault();
    updateLocation({ ...current, ...locationUpdates }).then(() => {
      setCurrent({ ...current, ...locationUpdates });
    });
  };

  return (
    <>
      <Accordion css={{ background: 'white' }} type="single" collapsible>
        <AccordionItem value="auto-responses" variant="neumorphic">
          <Flex justify="between" align="center">
            <VStack gap={2} css={{ lineHeight: 1.5 }}>
              <Text css={{ fontWeight: 600 }}>During Business Hours Auto Response</Text>
              <Text>
                Update the automated message customers receive during business hours
              </Text>
            </VStack>
            <AccordionTrigger />
          </Flex>
          <AccordionContent variant="neumorphic">
            <Divider css={{ mt: -20, mb: 20 }} />
            <form
              onSubmit={(e) =>
                onSubmit(e, {
                  automatic_response_open: openMessage,
                })
              }
            >
              <Box>
                <Fieldset>
                  <Label>Auto Response</Label>
                  <VStack gap="1">
                    <MessageEditorV2
                      message={openMessage}
                      setMessage={setOpenMessage}
                      textareaRef={textareaRef}
                      placeholder={
                        "Hey, thanks for contacting us. We'll be with you shortly"
                      }
                      isForm
                      showAddVariable={true}
                      showAddEmoji={true}
                      showAddTemplate={true}
                      showCharacterCount={true}
                    />
                    <Text
                      align="right"
                      css={{
                        color:
                          current?.automatic_response_open &&
                          current?.automatic_response_open?.length > 300
                            ? 'red'
                            : 'black',
                      }}
                    >
                      {current?.automatic_response_open?.length
                        ? current?.automatic_response_open?.length
                        : 0}
                      /300
                    </Text>
                  </VStack>
                </Fieldset>
                <Box>
                  <Button type="submit">Update Auto Response</Button>
                </Box>
              </Box>
            </form>
          </AccordionContent>
        </AccordionItem>
      </Accordion>
      <Accordion css={{ background: 'white' }} type="single" collapsible>
        <AccordionItem value="auto-responses" variant="neumorphic">
          <Flex justify="between" align="center">
            <VStack gap={2} css={{ lineHeight: 1.5 }}>
              <Text css={{ fontWeight: 600 }}>After Business Hours Auto Response</Text>
              <Text>
                Update the automated message customers receive after business hours
              </Text>
            </VStack>
            <AccordionTrigger />
          </Flex>
          <AccordionContent variant="neumorphic">
            <Divider css={{ mt: -20, mb: 20 }} />
            <form
              onSubmit={(e) =>
                onSubmit(e, {
                  automatic_response_closed: closedMessage,
                })
              }
            >
              <Box>
                <Fieldset>
                  <Label>Auto Response</Label>
                  <VStack gap="1">
                    <MessageEditorV2
                      message={closedMessage}
                      setMessage={setClosedMessage}
                      textareaRef={textareaRef}
                      placeholder={
                        "Hey, thanks for contacting us. We'll be with you shortly"
                      }
                      showAddVariable={true}
                      showAddEmoji={true}
                      showAddTemplate={true}
                      showCharacterCount={true}
                      isForm
                    />
                    <Text
                      align="right"
                      css={{
                        color:
                          current?.automatic_response_closed &&
                          current?.automatic_response_closed?.length > 300
                            ? 'red'
                            : 'black',
                      }}
                    >
                      {current?.automatic_response_closed?.length
                        ? current?.automatic_response_closed?.length
                        : 0}
                      /300
                    </Text>
                  </VStack>
                </Fieldset>
                <Box>
                  <Button type="submit">Update Auto Response</Button>
                </Box>
              </Box>
            </form>
          </AccordionContent>
        </AccordionItem>
      </Accordion>
      <Accordion css={{ background: 'white' }} type="single" collapsible>
        <AccordionItem value="auto-responses-time" variant="neumorphic">
          <Flex justify="between" align="center">
            <VStack gap={2} css={{ lineHeight: 1.5 }}>
              <Text css={{ fontWeight: 600 }}>Send Auto Responses</Text>
              <Text>Set at which times should business hours auto responses be sent</Text>
            </VStack>
            <AccordionTrigger />
          </Flex>
          <AccordionContent variant="neumorphic">
            <Divider css={{ mt: -20, mb: 20 }} />
            <RadioGroup
              value={current?.send_automatic_response_when || 'always'}
              onValueChange={onRadioButtonChange}
            >
              <VStack gap={2}>
                <StyledRadioIndicator align="center">
                  <Radio value="always">
                    <RadioGroupIndicator />
                  </Radio>
                  <Label htmlFor="r1" css={{ mb: 0, ml: '10px' }}>
                    Always send automatic responses
                  </Label>
                </StyledRadioIndicator>
                <StyledRadioIndicator align="center">
                  <Radio value="open">
                    <RadioGroupIndicator />
                  </Radio>
                  <Label htmlFor="r1" css={{ mb: 0, ml: '10px' }}>
                    Only send when channel is open
                  </Label>
                </StyledRadioIndicator>
                <StyledRadioIndicator align="center">
                  <Radio value="closed">
                    <RadioGroupIndicator />
                  </Radio>
                  <Label htmlFor="r1" css={{ mb: 0, ml: '10px' }}>
                    Only send when channel is closed
                  </Label>
                </StyledRadioIndicator>
                <StyledRadioIndicator align="center">
                  <Radio value="never">
                    <RadioGroupIndicator />
                  </Radio>
                  <Label htmlFor="r1" css={{ mb: 0, ml: '10px' }}>
                    Never send automatic responses
                  </Label>
                </StyledRadioIndicator>
              </VStack>
            </RadioGroup>
          </AccordionContent>
        </AccordionItem>
      </Accordion>
    </>
  );
};
