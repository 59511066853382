/* eslint-disable react-hooks/exhaustive-deps */
import {
  createContext,
  Dispatch,
  useContext,
  useEffect,
  useReducer,
  useRef,
} from 'react';
import { toast } from 'sonner';

import { prepareFilters } from '@/pages/data/utils/prepareFilters';
import * as SequencesAPI from '@/shared/api/sequences';
import * as SequenceTemplateAPI from '@/shared/api/sequences/templates';
import { SearchFilters } from '@/shared/types/contacts';
import {
  AddContactsToSequenceResponse,
  GetSequencesParams,
  RemoveContactsFromSequenceResponse,
  Sequence,
  SequenceActions,
  SequenceActionTypes,
  SequenceContacts,
  SequenceContactTabsType,
  SequenceResponse,
  SequenceResponseContact,
  SequenceStep,
  SequenceStepContact,
} from '@/shared/types/sequences';
import i18next from '@/shared/utils/translation';

import { SequenceReducer } from './SequenceReducer';

export const ITEMS_COUNT = 50;

export type SequencesState = {
  /* all sequences in state */
  allSequences: Array<Sequence> | Array<null>;
  /* sequences in state */
  sequences: Array<Sequence> | Array<null>;
  /* all sequence templates */
  allSequenceTemplates: Array<Sequence | null>;
  /* sequence templates */
  sequenceTemplates: Array<Sequence | null>;
  /* total amount of sequences */
  totalCount: number;
  /* loading state */
  loading: boolean;
  /* loading contacts state */
  loadingContacts: boolean;
  /* loading sequences templates */
  loadingTemplates: boolean;
  /* current sequence */
  current: Sequence | null;
  /* current sequence template */
  currentTemplate: Sequence | null;
  /* current sequence steps */
  currentSequenceSteps: Array<SequenceStep> | Array<null>;
  /* current sequence contacts */
  sequenceContacts: Array<SequenceStepContact> | Array<null>;
  /* total amount of current sequence contacts */
  totalSequenceContacts: number;
  /* sequence contacts params */
  sequenceContactsParams: SearchFilters;
  /* current sequence contacts tab */
  sequenceContactsTab: SequenceContactTabsType;
  /* current sequence responses */
  sequenceResponses: SequenceResponse[] | Array<null>;
  /* loading responses state */
  loadingResponses: boolean;
  /* total amount of current sequence responses */
  totalSequenceResponses: number;
  /* sequence responses params */
  sequenceResponsesParams: SearchFilters;
  /* total amount of current sequences templates */
  totalSequencesTemplates: number;
};

export const initialSequencesState: SequencesState = {
  allSequences: [],
  sequences: [],
  allSequenceTemplates: [],
  sequenceTemplates: [],
  totalCount: 0,
  loading: true,
  loadingContacts: true,
  loadingTemplates: true,
  current: null,
  currentTemplate: null,
  currentSequenceSteps: [],
  sequenceContacts: [],
  totalSequenceContacts: 0,
  sequenceContactsParams: {
    offset: 0,
    limit: ITEMS_COUNT,
    sort: [],
    filter: [],
    searchFilter: [],
  },
  sequenceContactsTab: SequenceContactTabsType.TOTAL,
  sequenceResponses: [],
  loadingResponses: true,
  totalSequenceResponses: 0,
  sequenceResponsesParams: {
    offset: 0,
    limit: ITEMS_COUNT,
    sort: [],
    filter: [],
    searchFilter: [],
  },
  totalSequencesTemplates: 0,
};

export const SequenceContext = createContext<{
  sequencesState: SequencesState;
  getSequence: (id: string) => void;
  setCurrentSequence: (sequence: Sequence | null) => void;
  getSequences: (params?: GetSequencesParams) => void;
  getSequenceTemplates: (
    params?: GetSequencesParams
  ) => Promise<Array<Sequence> | Array<null>>;
  setCurrentSequenceSteps: (steps: Array<SequenceStep>) => void;
  setCurrentSequenceTemplate: (sequence: Sequence | null) => void;
  createSequence: (params: Sequence) => Promise<Sequence>;
  createSequenceTemplate: (params: Sequence) => Promise<Sequence>;
  updateSequence: (params: Sequence) => Promise<Sequence>;
  updateSequenceTemplate: (params: Sequence) => Promise<Sequence>;
  deleteSequence: (params: Sequence) => void;
  deleteSequenceTemplate: (params: Sequence) => void;
  duplicateSequence: (sequenceId: string) => Promise<Sequence>;
  deleteSequenceStep: (sequenceId: string, stepId: string) => void;
  createSequenceStep: (params: SequenceStep) => void;
  getSequenceSteps: (sequenceId: string) => Promise<Array<SequenceStep>>;
  getAndSetSequenceSteps: (sequenceId: string) => Promise<Array<SequenceStep>>;
  updateSequenceStep: (params: SequenceStep) => void;
  addContactsToSequence: (
    contacts: Array<string> | string,
    locationId: string,
    stepId: string,
    sequenceId: string
  ) => void;
  removeContactsFromSequence: (
    contacts: Array<string> | string,
    sequenceId: string
  ) => void;
  getSequenceContacts: (
    sequenceId: string,
    params: any,
    isNewSearch?: boolean,
    signal?: any
  ) => Promise<SequenceContacts>;
  getSequenceResponses: (
    sequenceId: string,
    params: any,
    isNewSearch?: boolean,
    signal?: any
  ) => any;
  setSequenceContactsTab: (tab: SequenceContactTabsType) => void;
  resetSequenceContacts: () => void;
  setLoadingContacts: (loading: boolean) => void;
  getSequenceTitle: (id: string) => string;
  findSequenceStep: (sequence_id: string, step_id: string) => SequenceStep | null;
  setLoadingTemplates: (loading: boolean) => void;
  updateSequenceContactsParams: (filters: SearchFilters) => void;
  updateSequenceResponsesParams: (filters: SearchFilters) => void;
}>({
  sequencesState: initialSequencesState,
  setCurrentSequenceSteps: () => Promise.resolve(),
  getSequence: () => Promise.resolve({} as Sequence),
  getSequences: () => Promise.resolve([] as Array<Sequence>),
  getSequenceTemplates: () => Promise.resolve([] as Array<Sequence>),
  createSequence: () => Promise.resolve({} as Sequence),
  createSequenceTemplate: () => Promise.resolve({} as Sequence),
  updateSequence: () => Promise.resolve({} as Sequence),
  updateSequenceTemplate: () => Promise.resolve({} as Sequence),
  deleteSequence: () => Promise.resolve({} as Sequence),
  deleteSequenceTemplate: () => Promise.resolve({} as Sequence),
  duplicateSequence: () => Promise.resolve({} as Sequence),
  deleteSequenceStep: () => Promise.resolve(),
  setCurrentSequence: () => Promise.resolve(),
  setCurrentSequenceTemplate: () => Promise.resolve(),
  getSequenceSteps: () => Promise.resolve([] as Array<SequenceStep>),
  getAndSetSequenceSteps: () => Promise.resolve([] as Array<SequenceStep>),
  createSequenceStep: () => Promise.resolve({} as SequenceStep),
  updateSequenceStep: () => Promise.resolve({} as SequenceStep),
  addContactsToSequence: () => Promise.resolve(),
  removeContactsFromSequence: () => Promise.resolve(),
  getSequenceContacts: () => Promise.resolve({} as SequenceContacts),
  getSequenceResponses: () => Promise.resolve([] as Array<SequenceResponseContact>),
  setSequenceContactsTab: () => Promise.resolve(),
  resetSequenceContacts: () => Promise.resolve(),
  setLoadingContacts: () => Promise.resolve(),
  getSequenceTitle: () => '',
  findSequenceStep: () => null,
  setLoadingTemplates: () => Promise.resolve(),
  updateSequenceContactsParams: () => Promise.resolve(),
  updateSequenceResponsesParams: () => Promise.resolve(),
});

export const useSequences = () => useContext(SequenceContext);

const SequenceState = ({ children }: { children: React.ReactNode }) => {
  const [sequencesState, dispatch]: [SequencesState, Dispatch<SequenceActions>] =
    useReducer(SequenceReducer, initialSequencesState);

  // Use the useRef hook to keep track of the current AbortController instance
  const currentController = useRef<AbortController | null>(null);

  // on mount get all sequences
  useEffect(() => {
    // if we have all sequences in state, don't fetch them again
    if (sequencesState.allSequences.length > 0) {
      return;
    }
    getSequences();
  }, []);

  // on mount get all sequence templates
  useEffect(() => {
    // if we have sequence templates in state, don't fetch them again
    if (sequencesState.allSequenceTemplates.length > 0) {
      return;
    }
    getSequenceTemplates();
  }, []);

  useEffect(() => {
    if (sequencesState.current && sequencesState.current.id) {
      getSequenceContacts(
        sequencesState.current.id,
        sequencesState.sequenceContactsParams,
        sequencesState.sequenceContactsParams.offset === 0 ? true : false
      );
    }
  }, [sequencesState.sequenceContactsParams]);

  useEffect(() => {
    if (sequencesState.current && sequencesState.current.id) {
      getSequenceResponses(
        sequencesState.current.id,
        sequencesState.sequenceResponsesParams,
        sequencesState.sequenceResponsesParams.offset === 0 ? true : false
      );
    }
  }, [sequencesState.sequenceResponsesParams]);

  const getSequence = async (id: string): Promise<Sequence | null> => {
    try {
      const data = await SequencesAPI.getSequence(id);

      dispatch({
        type: SequenceActionTypes.GET_SEQUENCE,
        payload: data,
      });

      return data;
    } catch (err) {
      console.error(err);

      return Promise.reject(err);
    }
  };

  const getSequences = async (
    params?: GetSequencesParams
  ): Promise<Array<Sequence> | Array<null>> => {
    try {
      const data: { data: Array<Sequence> | Array<null>; total: number } =
        await SequencesAPI.getSequences(params);

      if (params?.limit) {
        dispatch({
          type: SequenceActionTypes.GET_SEQUENCES,
          payload: {
            sequences: data.data,
            offset: params?.offset || 0,
            total: data.total,
          },
        });
      } else {
        dispatch({
          type: SequenceActionTypes.GET_ALL_SEQUENCES,
          payload: data.data,
        });
      }

      return data.data;
    } catch (err) {
      console.error(err);

      return Promise.reject(err);
    }
  };

  const createSequence = async (params: Sequence): Promise<Sequence> => {
    try {
      const data = await SequencesAPI.createSequence(params);

      dispatch({
        type: SequenceActionTypes.ADD_SEQUENCE,
        payload: data,
      });

      toast.success(i18next.t('sequence_created_success') as string);

      return data;
    } catch (err) {
      toast.error(i18next.t('sequence_created_failure') as string);

      console.error(err);

      return Promise.reject(err);
    }
  };

  const updateSequence = async (params: Sequence): Promise<Sequence> => {
    try {
      const data = await SequencesAPI.updateSequence(params);

      dispatch({
        type: SequenceActionTypes.UPDATE_SEQUENCE,
        payload: data,
      });

      toast.success(i18next.t('sequence_updated_success') as string);

      return data;
    } catch (err) {
      toast.error(i18next.t('sequence_updated_failure') as string);

      console.error(err);

      return Promise.reject(err);
    }
  };

  const deleteSequence = async (params: Sequence): Promise<Sequence> => {
    try {
      const data = await SequencesAPI.updateSequence(params);

      if (params && params.id) {
        dispatch({
          type: SequenceActionTypes.DELETE_SEQUENCE,
          payload: params?.id,
        });

        toast.success(i18next.t('sequence_deleted_success') as string);
      }

      return data;
    } catch (err) {
      toast.error(i18next.t('sequence_deleted_failure') as string);

      console.error(err);

      return Promise.reject(err);
    }
  };

  const duplicateSequence = async (id: string): Promise<Sequence> => {
    try {
      const data = await SequencesAPI.duplicateSequence(id);

      dispatch({
        type: SequenceActionTypes.ADD_SEQUENCE,
        payload: data,
      });

      toast.success(i18next.t('sequence_duplicated_success') as string);

      return data;
    } catch (err) {
      toast.error(i18next.t('sequence_duplicated_failure') as string);

      console.error(err);

      return Promise.reject(err);
    }
  };

  const setCurrentSequence = (sequence: Sequence | null) => {
    try {
      dispatch({
        type: SequenceActionTypes.SET_CURRENT,
        payload: sequence,
      });
    } catch (err) {
      console.error(err);
    }
  };

  const getSequenceSteps = async (sequenceId: string): Promise<Array<SequenceStep>> => {
    try {
      const data = await SequencesAPI.getSequenceSteps(sequenceId);

      return data;
    } catch (err) {
      console.error(err);

      return Promise.reject(err);
    }
  };

  const getAndSetSequenceSteps = async (
    sequenceId: string
  ): Promise<Array<SequenceStep>> => {
    try {
      const data = await SequencesAPI.getSequenceSteps(sequenceId);

      dispatch({
        type: SequenceActionTypes.GET_SEQUENCE_STEPS,
        payload: data,
      });

      return data;
    } catch (err) {
      console.error(err);

      return Promise.reject(err);
    }
  };

  const setCurrentSequenceSteps = (steps: Array<SequenceStep>) => {
    try {
      dispatch({
        type: SequenceActionTypes.GET_SEQUENCE_STEPS,
        payload: steps,
      });
    } catch (err) {
      console.error(err);
    }
  };

  const createSequenceStep = async (params: SequenceStep): Promise<SequenceStep> => {
    try {
      const data = await SequencesAPI.createSequenceStep(params);

      dispatch({
        type: SequenceActionTypes.ADD_SEQUENCE_STEP,
        payload: data,
      });

      toast.success(i18next.t('sequence_step_created_success') as string);

      return data as SequenceStep;
    } catch (err) {
      toast.error(i18next.t('sequence_step_created_failure') as string);

      console.error(err);

      return Promise.reject(err);
    }
  };

  const updateSequenceStep = async (params: SequenceStep): Promise<SequenceStep> => {
    try {
      const data = await SequencesAPI.updateSequenceStep(params);

      dispatch({
        type: SequenceActionTypes.UPDATE_SEQUENCE_STEP,
        payload: data,
      });

      toast.success(i18next.t('sequence_step_updated_success') as string);

      return data as SequenceStep;
    } catch (err) {
      toast.error(i18next.t('sequence_step_updated_failure') as string);

      console.error(err);

      return Promise.reject(err);
    }
  };

  const deleteSequenceStep = async (sequenceId: string, stepId: string) => {
    try {
      await SequencesAPI.deleteSequenceStep(sequenceId, stepId);

      if (
        sequencesState.currentSequenceSteps &&
        sequencesState.currentSequenceSteps.length > 0
      ) {
        dispatch({
          type: SequenceActionTypes.DELETE_STEP,
          payload: stepId,
        });
      }

      toast.success(i18next.t('sequence_step_deleted_success') as string);
    } catch (err) {
      toast.error(i18next.t('sequence_step_deleted_failure') as string);

      console.error(err);
    }
  };

  const addContactsToSequence = async (
    contacts: Array<string> | string,
    locationId: string,
    stepId: string,
    sequenceId: string
  ): Promise<AddContactsToSequenceResponse> => {
    try {
      const data = await SequencesAPI.addContactsToSequence(
        contacts,
        locationId,
        stepId,
        sequenceId
      );

      dispatch({
        type: SequenceActionTypes.ADD_CONTACT_TO_SEQUENCE,
      });

      toast.success(i18next.t('contact_added_to_sequence_success') as string);

      return data as AddContactsToSequenceResponse;
    } catch (err) {
      toast.error(i18next.t('contact_added_to_sequence_failure') as string);

      console.error(err);

      return Promise.reject(err);
    }
  };

  const removeContactsFromSequence = async (
    contacts: Array<string> | string,
    sequenceId: string
  ): Promise<RemoveContactsFromSequenceResponse> => {
    try {
      const data = await SequencesAPI.removeContactsFromSequence(contacts, sequenceId);

      dispatch({
        type: SequenceActionTypes.REMOVE_CONTACTS_FROM_SEQUENCE,
        payload: contacts,
      });

      toast.success(i18next.t('contacts_removed_from_sequence_success') as string);

      return data as RemoveContactsFromSequenceResponse;
    } catch (err) {
      toast.error(i18next.t('contacts_removed_from_sequence_failure') as string);

      console.error(err);

      return Promise.reject(err);
    }
  };

  const setLoadingContacts = (loading: boolean) => {
    dispatch({
      type: SequenceActionTypes.SET_LOADING_CONTACTS,
      payload: loading,
    });
  };

  const getSequenceContacts = async (
    sequenceId: string,
    params: SearchFilters,
    isNewSearch = false
  ): Promise<SequenceContacts> => {
    try {
      if (currentController.current) {
        currentController.current.abort();
        currentController.current = null;
      }
      const controller = new AbortController();
      currentController.current = controller;

      if (params.offset === 0) {
        setLoadingContacts(true);
      }

      const data = await SequencesAPI.getSequenceContacts(
        sequenceId,
        prepareFilters(params),
        controller.signal
      ).finally(() => {
        setLoadingContacts(false);
      });

      const dispatchType = isNewSearch
        ? SequenceActionTypes.GET_SEQUENCE_CONTACTS_NEW_SEARCH
        : SequenceActionTypes.GET_SEQUENCE_CONTACTS;

      dispatch({
        type: dispatchType,
        payload: data,
      });

      return data;
    } catch (err) {
      console.error(err);

      return Promise.reject(err);
    }
  };

  const setLoadingResponses = (loading: boolean) => {
    dispatch({
      type: SequenceActionTypes.SET_SEQUENCE_RESPONSES_LOADING,
      payload: loading,
    });
  };

  const getSequenceResponses = async (
    sequenceId: string,
    params: any,
    isNewSearch: any,
    signal?: any
  ): Promise<Array<SequenceResponseContact>> => {
    try {
      if (params.offset === 0) {
        setLoadingResponses(true);
      }

      const data = await SequencesAPI.getSequenceResponses(
        sequenceId,
        prepareFilters(params),
        signal
      ).finally(() => {
        setLoadingResponses(false);
      });

      const dispatchType = isNewSearch
        ? SequenceActionTypes.GET_SEQUENCE_RESPONSES_NEW_SEARCH
        : SequenceActionTypes.GET_SEQUENCE_RESPONSES;

      dispatch({
        type: dispatchType,
        payload: data,
      });

      return data;
    } catch (err) {
      console.error(err);

      return Promise.reject(err);
    }
  };

  const setSequenceContactsTab = async (tab: SequenceContactTabsType) => {
    try {
      dispatch({
        type: SequenceActionTypes.SET_SEQUENCE_CONTACTS_TAB,
        payload: tab,
      });
    } catch (err) {
      console.error(err);
    }
  };

  const resetSequenceContacts = async () => {
    try {
      dispatch({
        type: SequenceActionTypes.RESET_SEQUENCE_CONTACTS,
        payload: [],
      });
    } catch (err) {
      console.error(err);
    }
  };

  const getSequenceTitle = (id: string): string => {
    const sequences = [...(sequencesState.sequences as Array<Sequence>)];
    const templates = [...(sequencesState.sequenceTemplates as Array<Sequence>)];

    // merge templates and sequences
    if (templates && sequences) {
      sequences.push(...templates);
    }

    if (!sequences) {
      return '';
    }

    const sequence = sequences.find((seq: Sequence) => seq.id === id);

    return sequence && sequence.title ? sequence?.title : '';
  };

  const findSequenceStep = (sequence_id: string, step_id: string) => {
    const sequences = [...(sequencesState.sequences as Array<Sequence>)];
    const templates = [...(sequencesState.sequenceTemplates as Array<Sequence>)];

    // merge templates and sequences
    if (templates && sequences) {
      sequences.push(...templates);
    }

    if (!sequences) {
      return null;
    }

    const sequence = sequences.find((seq: Sequence) => seq.id === sequence_id);

    if (!sequence?.steps) {
      return null;
    }

    const step = sequence?.steps.find((step: SequenceStep) => step.id === step_id);

    if (!step) {
      return null;
    }

    return step;
  };

  const getSequenceTemplates = async (
    params?: GetSequencesParams
  ): Promise<Array<Sequence> | Array<null>> => {
    try {
      const data = await SequenceTemplateAPI.getSequencesTemplates(params);

      if (params?.limit) {
        dispatch({
          type: SequenceActionTypes.GET_SEQUENCE_TEMPLATES,
          payload: { data: data.data, offset: params?.offset || 0, total: data.total },
        });
      } else {
        dispatch({
          type: SequenceActionTypes.GET_ALL_SEQUENCE_TEMPLATES,
          payload: data.data,
        });
      }

      return data.data;
    } catch (err) {
      console.error(err);

      return Promise.reject(err);
    }
  };

  const createSequenceTemplate = async (sequence: Sequence): Promise<Sequence> => {
    try {
      const data = await SequenceTemplateAPI.createSequenceTemplate(sequence);

      dispatch({
        type: SequenceActionTypes.CREATE_SEQUENCE_TEMPLATE,
        payload: data,
      });

      toast.success(i18next.t('sequence_template_created_success') as string);

      return data;
    } catch (err) {
      toast.error(i18next.t('sequence_template_created_failure') as string);

      console.error(err);

      return Promise.reject(err);
    }
  };

  const updateSequenceTemplate = async (sequence: Sequence): Promise<Sequence> => {
    try {
      const data = await SequenceTemplateAPI.updateSequenceTemplate(sequence);

      dispatch({
        type: SequenceActionTypes.UPDATE_SEQUENCE_TEMPLATE,
        payload: data,
      });

      toast.success(i18next.t('sequence_template_updated_success') as string);

      return data;
    } catch (err) {
      toast.error(i18next.t('sequence_template_updated_failure') as string);

      console.error(err);

      return Promise.reject(err);
    }
  };

  const deleteSequenceTemplate = async (sequence: Sequence): Promise<Sequence> => {
    try {
      const data = await SequenceTemplateAPI.deleteSequenceTemplate(
        sequence?.id as string
      );

      dispatch({
        type: SequenceActionTypes.DELETE_SEQUENCE_TEMPLATE,
        payload: sequence?.id as string,
      });

      toast.success(i18next.t('sequence_template_deleted_success') as string);

      return data;
    } catch (err) {
      toast.error(i18next.t('sequence_template_deleted_failure') as string);

      console.error(err);

      return Promise.reject(err);
    }
  };

  const setCurrentSequenceTemplate = (sequence: Sequence | null) => {
    try {
      dispatch({
        type: SequenceActionTypes.SET_CURRENT_TEMPLATE,
        payload: sequence,
      });
    } catch (err) {
      console.error(err);
    }
  };

  const setLoadingTemplates = (loading: boolean) => {
    dispatch({
      type: SequenceActionTypes.SET_LOADING_TEMPLATES,
      payload: loading,
    });
  };

  const updateSequenceContactsParams = (filters: SearchFilters) => {
    dispatch({
      type: SequenceActionTypes.SET_SEQUENCE_CONTACTS_PARAMS,
      payload: filters,
    });
  };

  const updateSequenceResponsesParams = (filters: SearchFilters) => {
    dispatch({
      type: SequenceActionTypes.SET_SEQUENCE_RESPONSES_PARAMS,
      payload: filters,
    });
  };

  return (
    <SequenceContext.Provider
      value={{
        sequencesState,
        getSequence,
        getSequences,
        getSequenceTemplates,
        createSequence,
        createSequenceTemplate,
        updateSequence,
        deleteSequence,
        setCurrentSequence,
        createSequenceStep,
        getSequenceSteps,
        findSequenceStep,
        getAndSetSequenceSteps,
        setCurrentSequenceSteps,
        deleteSequenceStep,
        updateSequenceStep,
        addContactsToSequence,
        getSequenceContacts,
        getSequenceResponses,
        setSequenceContactsTab,
        resetSequenceContacts,
        setLoadingContacts,
        getSequenceTitle,
        removeContactsFromSequence,
        duplicateSequence,
        updateSequenceTemplate,
        deleteSequenceTemplate,
        setCurrentSequenceTemplate,
        setLoadingTemplates,
        updateSequenceContactsParams,
        updateSequenceResponsesParams,
      }}
    >
      {children}
    </SequenceContext.Provider>
  );
};

export default SequenceState;
