/* eslint-disable react-hooks/exhaustive-deps */
import { captureException } from '@sentry/react';
import { debounce } from 'lodash';
import { useEffect, useRef, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { HiX } from 'react-icons/hi';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'sonner';

import { useContacts } from '@/contacts/context/ContactContext';
import { useAuth } from '@/pages/auth/context/AuthProvider';
import { useLocations } from '@/pages/settings/organization/locations/context/LocationContext';
import { useUserPreferences } from '@/pages/settings/user/preferences/context/PreferencesContext';
import { MessageEditorWrapper } from '@/shared/components/editor/Styles';
import { ScheduleParams } from '@/shared/components/editor/v2';
import { MessageEditorV2 } from '@/shared/components/editor/v2';
import { Attachments } from '@/shared/components/editor/v2/constants';
import { useFocusedContext } from '@/shared/components/editor/v2/context/FocusedContext';
import { usePhoneNumberInputState } from '@/shared/hooks';
import { Contact, Signature } from '@/shared/types';
import { Box, Flex, HStack, IconButton, Text } from '@/shared/ui';
import {
  isValidUuid,
  phoneFormatting,
  phoneRegex,
  toE164,
} from '@/shared/utils/validations/validations';
import { styled } from '@/stitches.config';

import { useConversation } from '../context/ConversationContext';
import { ConversationHeaderContainer } from '../conversation/header';
import { ContactItem } from './ContactItem';
import { CreateConversationForm } from './CreateConversationForm';
import { LocationMenu } from './LocationMenu';

export const CreateConversation = (): JSX.Element => {
  const { locationsState } = useLocations();
  const { locations } = locationsState;
  const [locationId, setLocationId] = useState<string>(locations[0]?.id);

  // contact context
  const { contactState, searchForContacts } = useContacts();
  const { searchedContacts } = contactState;

  const [inputValuePhoneError, setInputValuePhoneError] = useState<boolean>(false);

  const [generalInput, setGeneralInput] = usePhoneNumberInputState('');
  const [nameInput, setNameInput] = useState<string>('');
  const [phoneInput, setPhoneInput] = usePhoneNumberInputState('');

  // email conversation inputs
  const [emailInput, setEmailInput] = useState<string>('');
  const [subjectInput, setSubjectInput] = useState<string>('');

  const [showNameInput, setShowNameInput] = useState<boolean>(false);
  const [showPhoneInput, setShowPhoneInput] = useState<boolean>(false);

  const [signature, setSignature] = useState<Signature | null>(null);

  // conversation context
  const {
    getOrCreateConversation,
    getConversationByLocationAndContact,
    scheduleNewConversationMessage,
    conversationState,
    toggleForwardState,
  } = useConversation();
  const { isForwarded, forwardingParams } = conversationState;

  // /inbox/all/open/new?phone=+15555555555
  const phone = new URLSearchParams(window.location.search).get('phone');

  // if the phone number is in the url, then set the phone input
  useEffect(() => {
    if (phone && generalInput === '') {
      setGeneralInput(phone);
      setShowPhoneInput(false);
      setShowNameInput(true);
      setNameInput('');
      setPhoneInput(phone);
    }
  }, [phone]);

  const user_preferences = useUserPreferences();
  const { preferences } = user_preferences;

  const textareaRef = useRef<HTMLTextAreaElement>(null);

  const [message, setMessage] = useState<string>(
    isForwarded ? forwardingParams.message : ''
  );

  const [scheduleParams, setScheduleParams] = useState<ScheduleParams>({
    day: '',
    month: '',
    year: '',
    hour: '',
    minute: '',
    timezone: '',
  });

  const [attachments, setAttachments] = useState<Attachments>({
    attachment_urls: isForwarded ? forwardingParams?.attachment_urls || [] : [],
  });
  const [attachmentLoading, setAttachmentLoading] = useState<boolean>(false);

  // This code uses the debounce technique to limit how often a searchForContacts
  // function is called when the nameInput or phoneInput state changes
  const contactSearch = useRef(
    debounce((name, phone) => {
      searchForContacts(name, phone.replace(/\D/g, ''));
    }, 500)
  );

  useEffect(() => {
    const phoneFormatted = phoneFormatting(phoneInput);
    contactSearch.current(nameInput, phoneFormatted);

    return () => {
      contactSearch.current.cancel();
    };
  }, [nameInput, phoneInput]);

  // if the id a uuid and one of the location ids?
  const isLocationIdValid = (id: string) => {
    return isValidUuid(id) && locations?.some((location) => location?.id === id);
  };

  type ParamsType = {
    filter: string;
  };

  // get the params from the url
  const params = useParams<ParamsType>();

  // This useEffect will manage setting locationId to defaults where applicable
  useEffect(() => {
    // if the location id is in the url and valid then set the location default
    if (params.filter && isLocationIdValid(params?.filter)) {
      const local = locations.find((l) => l.id === params?.filter);
      if (local) {
        setLocationId(local.id);
      }
      // if the location id is not in the url, then see if there is a default location set in the user preferences
      // only if that location is valid, a uuid and one of the users locations for the organization they are in
    } else if (
      preferences &&
      preferences?.inbox &&
      isLocationIdValid(preferences?.inbox?.preferred_location_id as string)
    ) {
      setLocationId(preferences.inbox.preferred_location_id as string);
      // as a fallback, set the location to the first location in the list
    } else {
      setLocationId(locations[0]?.id);
    }
  }, [locations, params.filter]);

  const sanitizedInputValue = generalInput.toLowerCase().replace(/\s/g, '');
  const isPhoneNumber =
    phoneRegex.onlyNumbers.test(sanitizedInputValue) ||
    phoneRegex.containsNumber.test(sanitizedInputValue);

  const isContactName =
    phoneRegex.onlyLetters.test(sanitizedInputValue) ||
    phoneRegex.containsAlphabet.test(sanitizedInputValue);

  // set the contact details if a user selects one of the contacts
  // sets the contact in the same order the user searcher e.g. if
  // the search by name then the inputs are set name then input
  const setContactDetailsOnClick = (contact: Contact) => {
    try {
      const { id: contactId, phone, name } = contact;
      setInputValuePhoneError(false);

      if (!phone) return console.error('No phone');
      if (!name) return console.error('No name');

      if (name && phone && isContactName) {
        setGeneralInput(name);
        setShowPhoneInput(true);
        setPhoneInput(phone);
        setNameInput(name);
      }

      if (name && phone && isPhoneNumber) {
        setGeneralInput(phone);
        setShowPhoneInput(false);
        setShowNameInput(true);
        setNameInput(name);
      }

      toggleForwardState(true, {
        message: message || '',
        attachment_urls: attachments.attachment_urls,
        signature,
      });
      // If locationId is not selected, then the request will be rejected. We present an error toast
      // as if it was sent to the API it would fail. The UI should prevent this not being set and this is a backup.
      if (!locationId)
        return toast.error(
          'No location selected.\nChoose a location first to get conversation for this contact.'
        );
      const conversation: Promise<void> = getConversationByLocationAndContact(
        contactId,
        locationId
      );
      const conversationComplete: Promise<void> = Promise.any([conversation]);

      // check if conversation is a promise
      if (!(conversationComplete instanceof Promise))
        throw new Error('Conversation is not a promise');

      toast.promise(conversationComplete, {
        loading: 'Loading Contact',
        success: 'Contact Loaded',
        error: 'Failed to Load Contact',
      });
    } catch (error) {
      console.error(error);
      captureException(error, {
        extra: {
          contact,
        },
      });
      toast.error('Could not load contact');
    }
  };

  const { isNote } = useFocusedContext();

  const startConversation = async () => {
    try {
      if (!locationId || locationId == null)
        return toast('Select a channel to send from');
      if (locationType === 'phone' && !phoneInput)
        return toast.error('Phone number required');
      if (locationType === 'email' && !emailInput) return toast.error('Email required');
      if (locationType === 'email' && !subjectInput)
        return toast.error('Subject required');
      if (!message && attachments.attachment_urls.length === 0)
        return toast.error('Message or Attachment required');

      // Reset message when new conversation is created
      toggleForwardState(false, {
        message: '',
        attachment_urls: [],
        signature: null,
      });

      const newSmsConversationParams = {
        location_id: locationId,
        contact: {
          name: nameInput,
          phone: toE164(phoneInput),
        },
        message: {
          body: signature ? `${message}\n\n${signature.body}` : message,
          attachment_urls: attachments.attachment_urls,
        },
      };

      const newEmailConversationParams = {
        location_id: locationId,
        contact: {
          name: nameInput,
          email: emailInput,
        },
        message: {
          body: signature ? `${message}\n\n${signature.body}` : message,
          attachment_urls: attachments.attachment_urls,
          email_metadata: {
            subject: subjectInput,
          },
        },
      };

      const newEmailNoteConversationParams = {
        location_id: locationId,
        contact: {
          name: nameInput,
          email: emailInput,
        },
        note: {
          body: signature ? `${message}\n\n${signature.body}` : message,
          attachment_urls: attachments.attachment_urls,
        },
      };

      const newSmsNoteConversationParams = {
        location_id: locationId,
        contact: {
          name: nameInput,
          phone: toE164(phoneInput),
        },
        note: {
          body: signature ? `${message}\n\n${signature.body}` : message,
          attachment_urls: attachments.attachment_urls,
        },
      };

      // use isNote and locationType to determine which conversation to create
      const newConversationParams = isNote
        ? locationType === 'email'
          ? newEmailNoteConversationParams
          : newSmsNoteConversationParams
        : locationType === 'email'
          ? newEmailConversationParams
          : newSmsConversationParams;

      const newConversation: Promise<void> =
        getOrCreateConversation(newConversationParams);

      const conversationComplete: Promise<void> = Promise.any([newConversation]);

      if (!(conversationComplete instanceof Promise))
        throw new Error('Conversation is not a promise');

      // Reset message before sending to prevent user from sending multiple times
      const messageBackup = message;
      setMessage('');

      toast.promise(conversationComplete, {
        loading: `Creating Conversation...`,
        success: () => {
          // Set request in progress flag to false when request is complete
          return `Conversation Created.`;
        },
        error: () => {
          // Restore message if sending fails
          setMessage(messageBackup);
          return `Failed to Create Conversation`;
        },
      });
    } catch (error) {
      console.error(error);
      captureException(error, {
        extra: {
          locationId,
          nameInput,
          phoneInput,
          message,
          attachments: attachments?.attachment_urls,
          signature,
        },
      });
      toast.error('Could not create conversation');
    }
  };

  const clearState = () => {
    setGeneralInput('');
    setPhoneInput('');
    setNameInput('');
    setShowNameInput(false);
    setShowPhoneInput(false);
  };

  // on Esc navigate to /inbox
  const history = useHistory();
  const handleEscape = () => {
    history.push('/inbox/all/open');
    clearState();
  };

  const auth = useAuth();

  const scheduleSendMessage = ({
    day,
    hour,
    minute,
    month,
    timezone,
    year,
  }: ScheduleParams) => {
    try {
      if (locationType === 'phone' && !phoneInput)
        return toast.error('Please enter a phone number');
      if (locationType === 'email' && !emailInput)
        return toast.error('Please enter an email');
      if (locationType === 'email' && !subjectInput)
        return toast.error('Please enter a subject');
      if (message == undefined && attachments.attachment_urls.length === 0) return;

      const emailContact = {
        name: nameInput,
        email: emailInput,
      };

      const smsContact = {
        name: nameInput,
        phone: toE164(phoneInput),
      };

      const emailMessage = {
        body: message,
        attachment_urls: attachments.attachment_urls,
        email_metadata: {
          subject: subjectInput,
        },
      };

      const smsMessage = {
        body: message,
        attachment_urls: attachments.attachment_urls,
      };

      const scheduleMessage: Promise<void> = scheduleNewConversationMessage({
        contact: locationType === 'email' ? emailContact : smsContact,
        location_id: locationId,
        scheduled_message: {
          params: {
            message: locationType === 'email' ? emailMessage : smsMessage,
            user_id: Number(auth?.tokens?.user_id),
            organization_id: String(auth?.tokens?.organization_id),
          },
          schedule_options: {
            day,
            hour,
            minute,
            month,
            timezone,
            year,
          },
          target: 'message',
        },
      });
      const scheduleMessageComplete: Promise<void> = Promise.any([scheduleMessage]);

      if (!(scheduleMessageComplete instanceof Promise))
        throw new Error('Conversation is not a promise');

      toast.promise(scheduleMessageComplete, {
        loading: 'Scheduling message',
        success: 'Message scheduled',
        error: 'Failed to schedule message',
      });

      // Reset state
      setNameInput('');
      setPhoneInput('');
      setGeneralInput('');
      setAttachments({ attachment_urls: [] });
    } catch (error) {
      console.error(error);
      captureException(error, {
        extra: {
          nameInput,
          phoneInput,
          locationId,
          message,
          attachments,
          scheduleParams,
        },
      });
      toast.error('Could not schedule message');
    }
  };

  const translateMessageAction = (
    originalMessage: string,
    translatedMessage: string,
    attachmentUrls: string[]
  ) => {
    try {
      if (translatedMessage == undefined) throw new Error('Missing translated body');

      // change the param for the location type
      const emailConversationParams = {
        location_id: locationId,
        contact: {
          name: nameInput,
          email: emailInput,
        },
        message: {
          body: translatedMessage,
          attachment_urls: attachmentUrls || [],
          email_metadata: {
            subject: subjectInput,
          },
        },
      };

      const smsConversationParams = {
        location_id: locationId,
        contact: {
          name: nameInput,
          phone: toE164(phoneInput),
        },
        message: {
          body: translatedMessage,
          attachment_urls: attachmentUrls || [],
        },
      };

      const conversationParams =
        locationType === 'email' ? emailConversationParams : smsConversationParams;

      const conversation = getOrCreateConversation(conversationParams);
      const conversationComplete: Promise<void> = Promise.any([conversation]);

      if (!(conversationComplete instanceof Promise))
        throw new Error('Conversation is not a promise');

      toast.promise(conversationComplete, {
        loading: 'Sending translated message',
        success: 'Translated message sent',
        error: 'Failed to send translated message',
      });

      // Reset state
      clearState();
    } catch (error) {
      console.error(error);
      captureException(error, {
        extra: {
          originalMessage,
          translatedMessage,
          attachmentUrls,
          locationId,
        },
      });
      toast.error('Could not send translated message');
    }
  };

  const getLocation = (id: string) => locations.find((location) => location.id === id);

  const locationType = getLocation(locationId)?.type;

  return (
    <>
      <Helmet>
        <title>New Conversation</title>
      </Helmet>
      <Flex direction="column" css={{ position: 'relative', flexGrow: 1 }}>
        <ConversationHeaderContainer justify="between" align="center">
          <LocationMenu
            locations={locations}
            setLocationId={setLocationId}
            locationId={locationId}
          />
          <HStack gap={4}>
            <DiscardNewConversationButton
              onClick={() => handleEscape()}
              aria-label="close new conversation"
            />
          </HStack>
        </ConversationHeaderContainer>
        <Flex
          direction="column"
          css={{
            flexGrow: 1,
            overflow: 'auto',
          }}
        >
          <Flex direction="column">
            <CreateConversationForm
              selectedLocationType={locationType}
              nameInput={nameInput}
              phoneInput={phoneInput}
              inputValuePhoneError={inputValuePhoneError}
              setInputValuePhoneError={setInputValuePhoneError}
              setNameInput={setNameInput}
              setPhoneInput={setPhoneInput}
              setShowNameInput={setShowNameInput}
              setShowPhoneInput={setShowPhoneInput}
              showNameInput={showNameInput}
              showPhoneInput={showPhoneInput}
              generalInput={generalInput}
              setGeneralInput={setGeneralInput}
              emailInput={emailInput}
              setEmailInput={setEmailInput}
              subjectInput={subjectInput}
              setSubjectInput={setSubjectInput}
            />
          </Flex>
          <Flex direction="column">
            <Flex css={{ overflow: 'hidden' }} direction="column">
              {searchedContacts.length > 0 && (
                <CurrentContactsLabel>Current Contacts:</CurrentContactsLabel>
              )}
              <Box
                css={{
                  overflowY: 'auto',
                  height: '100%',
                }}
              >
                {searchedContacts.map((contact: Contact) => (
                  <ContactItem
                    key={contact.id}
                    name={contact.name}
                    phone={contact.phone}
                    id={contact.id}
                    loadConversationOnClick={() => setContactDetailsOnClick(contact)}
                  />
                ))}
              </Box>
            </Flex>
          </Flex>
        </Flex>
        <MessageEditorWrapper>
          <MessageEditorV2
            source="mainEditor"
            attachments={attachments}
            setAttachments={setAttachments}
            attachmentLoading={attachmentLoading}
            setAttachmentLoading={setAttachmentLoading}
            message={message}
            setMessage={setMessage}
            textareaRef={textareaRef}
            enableAttachments={true}
            showAddEmoji={true}
            showAddSchedule={true}
            showAddTranslate={true}
            showAddVariable={true}
            showAddReviewLink={true}
            showAddAttachment={true}
            showAddSignature={true}
            showAddTemplate={true}
            showCharacterCount={true}
            showKeyboardSendShortcut={true}
            showAddNote={true}
            showShortcuts={true}
            scheduleParams={scheduleParams}
            setScheduleParams={setScheduleParams}
            showSendButton={true}
            setSignature={setSignature}
            signature={signature}
            sendMessageAction={startConversation}
            scheduleSendAction={scheduleSendMessage}
            translateMessageAction={translateMessageAction}
            showAutoComplete={true}
            contact={{
              name: nameInput || '{{first_name}}',
              phone: toE164(phoneInput) || '',
              id: '',
            }}
            enableEnterToSend={true}
            location={getLocation(locationId)}
            channel_type={getLocation(locationId)?.type || 'phone'}
          />
          <Flex justify="between" align="center" css={{ height: 40 }}>
            <Box css={{ marginLeft: 'auto' }}>
              {preferences &&
              preferences.inbox &&
              preferences.inbox.enter_to_send === false ? (
                <Text css={{ fontWeight: 500, color: '$gray11' }}>
                  {'Shift + Enter to Send'}
                </Text>
              ) : (
                <Text css={{ fontWeight: 500, color: '$gray11' }}>
                  {'Shift + Enter to Skip a Line'}
                </Text>
              )}
            </Box>
          </Flex>
        </MessageEditorWrapper>
      </Flex>
    </>
  );
};

export function DiscardNewConversationButton(props: any) {
  return (
    <IconButton size={2} {...props}>
      <HiX size={18} />
    </IconButton>
  );
}

export const NewConversationInputLabel = styled('label', {
  fontSize: 12,
  textTransform: 'uppercase',
  fontWeight: 700,
});

export const CurrentContactsLabel = styled(NewConversationInputLabel, {
  pt: 25,
  pb: 10,
  px: 15,
  '@lg': {
    px: 30,
  },
});
