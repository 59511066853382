/* eslint-disable @typescript-eslint/no-explicit-any */
import dayjs from 'dayjs';
import customParseFormt from 'dayjs/plugin/customParseFormat';
import utc from 'dayjs/plugin/utc';
import DromoUploader, { IColumnHook } from 'dromo-uploader-react';
import { isValidPhoneNumber } from 'libphonenumber-js';
import React from 'react';

import { useAuth } from '@/auth/context/AuthProvider';
import { useConversation } from '@/inbox/context/ConversationContext';
import { useLocations } from '@/pages/settings/organization/locations/context/LocationContext';
import { Location } from '@/shared/types/locations';
import { DROMO_API_KEY } from '@/shared/utils/config';
import { toE164 } from '@/shared/utils/validations/validations';

export const BulkImportConversation = ({ children }: { children: React.ReactNode }) => {
  const auth = useAuth();

  const locations = useLocations();
  const { locationsState } = locations;
  const { allLocations } = locationsState;
  const locationPhoneNumbers = allLocations.map((location: Location) => location.phone);

  const conversations = useConversation();

  const fields: Array<any> = [
    {
      label: 'Message Timestamp',
      key: 'publish_date',
      validators: [{ validate: 'required' }],
    },
    {
      label: 'Message Direction',
      key: 'source_type',
      validators: [{ validate: 'required' }],
    },
    {
      label: 'Contact Phone Number',
      key: 'contact_phone',
      validators: [{ validate: 'required' }],
    },
    {
      label: 'Location Phone Number',
      key: 'location_phone',
      validators: [{ validate: 'required' }],
    },
    {
      label: 'Message Body',
      key: 'body',
      validators: [{ validate: 'required' }],
    },
    {
      label: 'Contact Name',
      key: 'contact_name',
    },
  ];

  const settings = {
    version: 'v2',
    importIdentifier: 'Conversations',
    displayEncoding: false,
    allowInvalidSubmit: false,
    backendSync: true,
    manualInputDisabled: false,
    manualInputOnly: false,
    allowCustomFields: false,
    maxRecords: 50000,
    developmentMode: false,
    styleOverrides: {
      primaryButton: {
        backgroundColor: '#3E54CF',
        textColor: '#FFFFFF',
      },
    },
  };

  const user = {
    id: `${auth?.tokens?.user_id}`,
    name: auth?.tokens?.name || '',
    email: auth?.tokens?.email || '',
    companyId: auth.organizationId || '',
    companyName: auth.organizationName || '',
  };

  const columnHooks: IColumnHook | any = [
    {
      fieldName: 'location_phone',
      callback: (values: any) => {
        const newValues: Array<any> = [];

        values.forEach((row: { index: number; value: string }) => {
          const value = toE164(row.value);

          if (locationPhoneNumbers.includes(value)) {
            const newRow = {
              index: row.index,
              value: toE164(row.value),
              info: [
                {
                  message: 'Valid Location Phone Number',
                  level: 'info',
                },
              ],
            };

            newValues.push(newRow);
          } else {
            const newRow = {
              index: row.index,
              value: toE164(row.value),
              info: [
                {
                  message: 'Invalid Location Phone Number',
                  level: 'error',
                },
              ],
            };

            newValues.push(newRow);
          }
        });

        return newValues;
      },
    },
    {
      fieldName: 'contact_phone',
      callback: (values: any) => {
        const newValues: Array<any> = [];

        values.forEach((row: { index: number; value: string }) => {
          const value = toE164(row.value);
          const validPhone = isValidPhoneNumber(value);

          if (validPhone) {
            const newRow = {
              index: row.index,
              value: value,
              info: [
                {
                  message: 'Valid Contact Phone Number',
                  level: 'info',
                },
              ],
            };

            newValues.push(newRow);
          } else {
            const newRow = {
              index: row.index,
              value: value,
              info: [
                {
                  message: 'Invalid Contact Phone Number',
                  level: 'error',
                },
              ],
            };

            newValues.push(newRow);
          }
        });

        return newValues;
      },
    },
    {
      fieldName: 'source_type',
      callback: (values: any) => {
        const newValues: Array<any> = [];

        values.forEach((row: { index: number; value: string }) => {
          const value = row.value.toUpperCase();

          if (value === 'INBOUND' || value === 'OUTBOUND') {
            const newRow = {
              index: row.index,
              value: value,
              info: [
                {
                  message: 'Valid Message Direction',
                  level: 'info',
                },
              ],
            };

            newValues.push(newRow);
          } else {
            const newRow = {
              index: row.index,
              info: [
                {
                  message: 'Invalid Message Direction',
                  level: 'error',
                },
              ],
            };
            newValues.push(newRow);
          }
        });

        return newValues;
      },
    },
    {
      fieldName: 'publish_date',
      callback: (values: any) => {
        const newValues: Array<any> = [];

        values.forEach((row: { index: number; value: string }) => {
          // extended day js with custom parse format
          dayjs.extend(customParseFormt);
          // extend day js with utc plugin
          dayjs.extend(utc);
          // parse the date
          const formattedvalueDay = dayjs(row.value, 'D/MM/YYYY HH:mm');
          const utcValueDay = formattedvalueDay.utc().format();

          // check if the date is valid and it is in the past
          if (
            dayjs(formattedvalueDay).isValid() &&
            dayjs(utcValueDay).isBefore(dayjs())
          ) {
            const newRow = {
              index: row.index,
              value: utcValueDay,
              info: [
                {
                  message: 'Valid Message Timestamp',
                  level: 'info',
                },
              ],
            };

            newValues.push(newRow);
          } else {
            const newRow = {
              index: row.index,
              info: [
                {
                  message: 'Invalid Message Timestamp',
                  level: 'error',
                },
              ],
            };

            newValues.push(newRow);
          }
        });

        return newValues;
      },
    },
  ];

  return (
    <DromoUploader
      licenseKey={DROMO_API_KEY}
      settings={settings}
      fields={fields}
      user={user}
      columnHooks={columnHooks}
      onResults={(data) => conversations.bulkAddConversations(data)}
    >
      {children}
    </DromoUploader>
  );
};
