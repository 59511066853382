import React, { Dispatch } from 'react';

import { CampaignPermissionTypes } from '@/shared/types/campaigns';
import { AccordionValue } from '@/shared/types/campaigns';
import { Box, Button, Fieldset, HStack, Input, Label, VStack } from '@/shared/ui';
import { isValidUuid } from '@/shared/utils/validations/validations';

import { CampaignAccordion } from './CampaignAccordion';
import { SelectLocation } from './SelectLocation';
import { SelectPermission } from './SelectPermission';
import { SelectPermissionLocations } from './SelectPermissionLocations';

type CampaignBasicInfoProps = {
  /* accordion value */
  accordion: AccordionValue;
  /* accordion setter */
  setAccordion: Dispatch<React.SetStateAction<AccordionValue>>;
  /* campaign title */
  campaignTitle: string;
  /* campaign title setter */
  setCampaignTitle: Dispatch<React.SetStateAction<string>>;
  /* location */
  location: string;
  /* location setter */
  setLocation: Dispatch<React.SetStateAction<string>>;
  /* handle save */
  handleSave: (accordion: AccordionValue) => void;
  /* permission */
  permission?: CampaignPermissionTypes;
  /* permission setter*/
  setPermission?: Dispatch<React.SetStateAction<CampaignPermissionTypes>>;
  /* permission location setter*/
  setPermissionLocation?: Dispatch<React.SetStateAction<string[]>>;
  /* show save button or not */
  showSaveButton?: boolean;
  /* show select location */
  showSelectLocation?: boolean;
  /* show select permission */
  showSelectPermission?: boolean;
  /* show select phone number */
  showSelectPhoneNumber?: boolean;
  /* disable edit */
  disableEdit?: boolean;
  /* hide index and green box */
  hideIndex?: boolean;
};

export const CampaignBasicInfo = (props: CampaignBasicInfoProps): JSX.Element => {
  const {
    accordion,
    setAccordion,
    campaignTitle,
    setCampaignTitle,
    location,
    setLocation,
    handleSave,
    permission,
    setPermission,
    setPermissionLocation,
    showSaveButton = true,
    showSelectLocation = true,
    showSelectPermission = true,
    showSelectPhoneNumber = true,
    disableEdit,
    hideIndex,
  } = props;

  return (
    <CampaignAccordion
      index={1}
      hideIndex={hideIndex}
      title="Basic Info"
      description="Campaign Name & Channel"
      currentAccordionValue={accordion}
      itemValue={AccordionValue.SELECT_LOCATION}
      setItemValue={setAccordion}
      isValid={campaignTitle.length > 1 && isValidUuid(location)}
      buttonCopy={!showSaveButton ? 'Expand' : 'Edit'}
    >
      <VStack gap="2">
        <Fieldset>
          <Label>Name Campaign</Label>
          <Box>
            {disableEdit ? (
              <Input placeholder="Name Campaign" value={campaignTitle} disabled={true} />
            ) : (
              <Input
                placeholder="Name Campaign"
                value={campaignTitle}
                onChange={(e) => setCampaignTitle(e.target.value)}
              />
            )}
          </Box>
        </Fieldset>
        {showSelectPermission && setPermission && (
          <Fieldset>
            <Label>Select Permission</Label>
            <SelectPermission permission={permission} setPermission={setPermission} />
          </Fieldset>
        )}
        {showSelectLocation &&
          permission &&
          permission === 'location' &&
          setPermissionLocation && (
            <Fieldset>
              <Label>Select Channels</Label>
              <SelectPermissionLocations setPermissionLocations={setPermissionLocation} />
            </Fieldset>
          )}
        {showSelectPhoneNumber && (
          <Fieldset>
            <Label>Send Campaign From</Label>
            <SelectLocation location={location} setLocation={setLocation} />
          </Fieldset>
        )}
        <HStack>
          {showSaveButton && (
            <Button
              onClick={() => handleSave(AccordionValue.SELECT_AUDIENCE)}
              disabled={!location || campaignTitle.length < 2}
            >
              Save
            </Button>
          )}
          {!disableEdit && (
            <Button
              variant="gray"
              ghost={true}
              onClick={() => setAccordion(AccordionValue.DEFAULT_VALUE)}
            >
              Cancel
            </Button>
          )}
        </HStack>
      </VStack>
    </CampaignAccordion>
  );
};
