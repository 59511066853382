/* eslint-disable react-hooks/exhaustive-deps */
import dayjs from 'dayjs';
import React, { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';
import { HiX } from 'react-icons/hi';
import { toast } from 'sonner';

import { useContacts } from '@/contacts/context/ContactContext';
import { useGroups } from '@/pages/contacts/groups/context/GroupContext';
import { useUploads } from '@/pages/contacts/uploads/context/UploadContext';
import { useLocations } from '@/pages/settings/organization/locations/context/LocationContext';
import { MessageEditorV2 } from '@/shared/components/editor/v2';
import { Attachments } from '@/shared/components/editor/v2/constants';
import { SingleSelect } from '@/shared/components/SingleSelect';
import { TimezonePicker } from '@/shared/components/timezonepicker/TimezonePicker';
import { Signature } from '@/shared/types';
import { Audience } from '@/shared/types/audience';
import {
  Campaign,
  CampaignStatus,
  Contact,
  ScheduleOptions,
} from '@/shared/types/campaigns';
import { Location } from '@/shared/types/locations';
import {
  Box,
  Dialog,
  DialogClose,
  DialogCloseIcon,
  DialogContent,
  DialogHeader,
  DialogOverlay,
  DialogPortal,
  DialogTrigger,
  Fieldset,
  Label,
  VStack,
} from '@/shared/ui';
import i18next from '@/shared/utils/translation';

import {
  AudienceSelection,
  AudienceState,
  formatPreselectedAudience,
  getCampaignIdFromAudience,
  removeEmptyFields,
} from '../audience';
import { useCampaignsContext } from '../context/CampaignsContext';
import { SetSchedule } from './SetSchedule';

export type PreSelectedAudience = {
  contacts?: Array<string>;
  dynamic_group_ids?: Array<string>;
  tag_ids?: Array<string>;
  upload_tag_ids?: Array<string>;
  campaign_delivered?: Array<string>;
  campaign_not_delivered?: Array<string>;
  campaign_responded?: Array<string>;
  campaign_not_responded?: Array<string>;
  campaign_link_clicked?: Array<string>;
  campaign_link_not_clicked?: Array<string>;
  campaign_unfulfilled?: Array<string>;
};

export const pre_selected_audience_initial_state = {
  contacts: [],
  dynamic_group_ids: [],
  tag_ids: [],
  upload_tag_ids: [],
};

type QuickCampaignDialogProps = {
  /** the QuickCampaignDialogState of the parent */
  quickCampaignDialogState: boolean;
  /** set the QuickCampaignDialogState of the parent */
  setQuickCampaignDialogState: Dispatch<SetStateAction<boolean>>;
  /** changes the QuickCampaignDialogState of the parent to the opposite of what it was */
  quickCampaignDialogStateChange: () => void;
  /** object we get only when a whole campaign tab is selected */
  audience?: { campaign_id?: string; campaign_contacts_type?: string };
  /** true if we want to show schedule */
  showSchedule: boolean;
  /** changes the show schedule state of the parent to the opposite of what it was */
  toggleSchedule: () => void;
  /** checked contacts state of the parent */
  checkedContacts: Array<Contact>;
  /** change checked contacts state of the parent */
  setCheckedContacts: (contacts: Array<Contact>) => void;
  /** current campaign */
  currentCampaign?: Campaign | null;
  /** true if we are on the campaigns page */
  isCampaignsPage?: boolean;
  /** true if we are on the campaigns edit page */
  isCampaignsEditPage?: boolean;
  /** true if we are on the contacts page */
  isContactsPage: boolean;
  /** true if we are on the groups page */
  isGroupsPage: boolean;
  /** true if we are on the uploads page */
  isUploadsPage: boolean;
  /** true if we are on the inbox page */
  isInbox?: boolean;
  /** children */
  children?: React.ReactNode;
  /** current location */
  location?: Location;
  /** preselected audience e.g. tags, uploads, contacts */
  preSelectedAudience?: PreSelectedAudience;
  /** preselected schedule params */
  scheduleParams?: ScheduleOptions;
  /** if this is passed, we use it as initial value */
  messageBody?: string;
  /** uses this for initial attachments */
  preSelectedAttachments?: Array<string>;
  /* all contacts check */
  allContactsChecked?: boolean;
};

export const QuickCampaignDialog = (props: QuickCampaignDialogProps): JSX.Element => {
  const { children } = props;
  const { locationsState, updateLocation } = useLocations();
  const { locations } = locationsState;

  const { contactState, setAllContactsChecked } = useContacts();
  const { allContactsChecked, contacts } = contactState;

  const {
    setAllSelected,
    createOneCampaign,
    getOneCampaignMessageCount,
    campaignsState: { currentMessageCount },
  } = useCampaignsContext();

  const everyContactSelectedOnContactsPage = props.isContactsPage && allContactsChecked;

  const uploadsContext = useUploads();
  const currentUpload = uploadsContext.uploadsState.current;

  const groupsContext = useGroups();
  const currentGroup = groupsContext.groupsState.current;

  const singleLocation = locations.filter(
    (loc: Location) => loc.id === props.currentCampaign?.audience?.location
  )[0];

  const [timezone, setTimezone] = useState<string>(
    Intl.DateTimeFormat().resolvedOptions().timeZone || ''
  );

  const getLocation = (id: string) => locations.find((location) => location.id === id);

  const [location, setLocation] = useState(singleLocation || locations[0]);

  useEffect(() => {
    if (props.location) {
      setLocation(props.location);
    }
  }, [props.location]);

  const [date, setDate] = useState<string>(
    props.scheduleParams
      ? `${props.scheduleParams.month}/${props.scheduleParams.day}/${props.scheduleParams.year}`
      : dayjs(new Date()).format('MM/DD/YYYY')
  );

  const [time, setTime] = useState(
    props?.scheduleParams?.hour && props?.scheduleParams?.minute
      ? `${props?.scheduleParams?.hour}:${props?.scheduleParams?.minute}`
      : ''
  );

  const [scheduleParams, setScheduleParams] = useState(
    props.scheduleParams || {
      day: '',
      month: '',
      year: '',
      hour: '',
      minute: '',
      timezone: '',
    }
  );

  // message text
  const [message, setMessage] = useState<string>(props.messageBody || '');

  // message attachments
  const [attachments, setAttachments] = useState<Attachments>({
    attachment_urls: props.preSelectedAttachments || [],
  });

  // attachment loading
  const [attachmentLoading, setAttachmentLoading] = useState<boolean>(false);

  const [audienceFromProps, setAudienceFromProps] = useState(
    props.preSelectedAudience ? formatPreselectedAudience(props.preSelectedAudience) : {}
  );

  // Reference to the textarea HTML element
  const textareaRef = useRef<HTMLTextAreaElement>(null);

  // State for the signature
  const [signature, setSignature] = useState<Signature | null>(null);

  // Used to add signature to message when selected
  // As long as the signature has not already been added
  useEffect(() => {
    if (signature && !message.includes(signature.body)) {
      setMessage(`${message}\n${signature.body}`);
      setSignature(null);
    }
  }, [signature]);

  const [selectedAudience, setSelectedAudience] = useState<AudienceState>({
    contacts: [],
    uploads: [],
    groups: [],
    tags: [],
    manuallyAddedContacts: [],
    wholeCampaignTab: {},
  });

  useEffect(() => {
    if (props.checkedContacts.length > 0) {
      // prevents infinite loop
      if (!(allContactsChecked && props.isContactsPage)) {
        setSelectedAudience({
          ...selectedAudience,
          contacts: props.checkedContacts.map((contact: Contact) => {
            return contact.id;
          }),
        });
      }
    }
  }, [props.checkedContacts]);

  useEffect(() => {
    // handle the case if all contacts are selected on contacts/groups/uploads page
    if (props.isUploadsPage && allContactsChecked) {
      setSelectedAudience({
        ...selectedAudience,
        contacts: [],
        uploads: [{ id: currentUpload?.id || '', name: currentUpload?.name }],
      });
      getOneCampaignMessageCount(
        {
          location: location?.id || '',
          upload_tag_ids: [currentUpload?.id || ''],
        },
        {
          time_since_last_campaign: 0,
          exclude_contacts_with_open_conversations: false,
        }
      );
    }
    if (props.isGroupsPage && allContactsChecked) {
      setSelectedAudience({
        ...selectedAudience,
        contacts: [],
        groups: [{ id: currentGroup?.id || '', name: currentGroup?.name }],
      });
      getOneCampaignMessageCount(
        {
          location: location?.id || '',
          dynamic_group_ids: [currentGroup?.id || ''],
        },
        {
          time_since_last_campaign: 0,
          exclude_contacts_with_open_conversations: false,
        }
      );
    }
    if (everyContactSelectedOnContactsPage) {
      const contactsIds = (contacts as Contact[]).map((contact: Contact) => contact.id);
      setSelectedAudience({
        ...selectedAudience,
        contacts: contactsIds,
      });
      getOneCampaignMessageCount(
        {
          location: '',
          location_ids: [location?.id || ''],
        },
        {
          time_since_last_campaign: 0,
          exclude_contacts_with_open_conversations: false,
        }
      );
    }
  }, [allContactsChecked]);

  useEffect(() => {
    // get message count by using a specific audience on selected audience/location change
    if (everyContactSelectedOnContactsPage) {
      if (
        selectedAudience.contacts.length > 0 ||
        (audienceFromProps?.contacts && audienceFromProps?.contacts?.length > 0)
      ) {
        const audience = {
          location: '',
          tag_ids: [
            ...selectedAudience.tags.map((element) => element.id),
            ...(audienceFromProps?.tag_ids || []),
          ],
          dynamic_group_ids: [
            ...selectedAudience.groups.map((element) => element.id),
            ...(audienceFromProps?.dynamic_group_ids || []),
          ],
          upload_tag_ids: [
            ...selectedAudience.uploads.map((element) => element.id),
            ...(audienceFromProps?.upload_tag_ids || []),
          ],
          location_ids: [location?.id || ''],
        };

        getOneCampaignMessageCount(removeEmptyFields(audience), {
          time_since_last_campaign: 0,
          exclude_contacts_with_open_conversations: false,
        });
      } else {
        const audience = {
          location: location?.id || '',
          tag_ids: [
            ...selectedAudience.tags.map((element) => element.id),
            ...(audienceFromProps?.tag_ids || []),
          ],
          dynamic_group_ids: [
            ...selectedAudience.groups.map((element) => element.id),
            ...(audienceFromProps?.dynamic_group_ids || []),
          ],
          upload_tag_ids: [
            ...selectedAudience.uploads.map((element) => element.id),
            ...(audienceFromProps?.upload_tag_ids || []),
          ],
          contacts: [
            ...selectedAudience.contacts,
            ...selectedAudience.manuallyAddedContacts.map((element) => element.id),
            ...(audienceFromProps?.contacts || []),
          ],
        };

        getOneCampaignMessageCount(removeEmptyFields(audience), {
          time_since_last_campaign: 0,
          exclude_contacts_with_open_conversations: false,
        });
      }
    } else {
      // get the campaign id for when the entire tab is selected
      const campaign_id =
        selectedAudience?.wholeCampaignTab?.campaign_id ||
        (audienceFromProps && getCampaignIdFromAudience(audienceFromProps));

      // get the type from the tab
      const campaign_contacts_type =
        selectedAudience?.wholeCampaignTab?.campaign_contacts_type;

      const audience = {
        location: location?.id || '',
        tag_ids: [
          ...selectedAudience.tags.map((element) => element.id),
          ...(audienceFromProps?.tag_ids || []),
        ],
        dynamic_group_ids: [
          ...selectedAudience.groups.map((element) => element.id),
          ...(audienceFromProps?.dynamic_group_ids || []),
        ],
        upload_tag_ids: [
          ...selectedAudience.uploads.map((element) => element.id),
          ...(audienceFromProps?.upload_tag_ids || []),
        ],
        contacts: [
          ...selectedAudience.contacts,
          ...selectedAudience.manuallyAddedContacts.map((element) => element.id),
          ...(audienceFromProps?.contacts || []),
        ],
        campaign_delivered:
          campaign_contacts_type === 'delivered' ||
          (audienceFromProps && audienceFromProps.campaign_delivered)
            ? [campaign_id]
            : [],
        campaign_not_delivered:
          campaign_contacts_type === 'not_delivered' ||
          (audienceFromProps && audienceFromProps.campaign_not_delivered)
            ? [campaign_id]
            : [],
        campaign_responded:
          campaign_contacts_type === 'responded' ||
          (audienceFromProps && audienceFromProps.campaign_responded)
            ? [campaign_id]
            : [],
        campaign_not_responded:
          campaign_contacts_type === 'unresponded' ||
          (audienceFromProps && audienceFromProps.campaign_not_responded)
            ? [campaign_id]
            : [],
        campaign_link_clicked:
          campaign_contacts_type === 'clicks' ||
          (audienceFromProps && audienceFromProps.campaign_link_clicked)
            ? [campaign_id]
            : [],
        campaign_link_not_clicked:
          campaign_contacts_type === 'not_clicked' ||
          (audienceFromProps && audienceFromProps.campaign_link_not_clicked)
            ? [campaign_id]
            : [],
        campaign_unfulfilled:
          campaign_contacts_type === 'unfulfilled' ||
          (audienceFromProps && audienceFromProps.unfulfilled)
            ? [campaign_id]
            : [],
      };

      if (props.quickCampaignDialogState) {
        getOneCampaignMessageCount(removeEmptyFields(audience), {
          time_since_last_campaign: 0,
          exclude_contacts_with_open_conversations: false,
        });
      }
    }
  }, [selectedAudience, location?.id, audienceFromProps, props.quickCampaignDialogState]);

  // reset state and close dialog
  const quickCampaignDialogReset = () => {
    props.quickCampaignDialogStateChange();
    setTime('');
    setMessage('');
    setAttachments({
      attachment_urls: [],
    });
  };

  // set the schedule params in the correct format
  useEffect(() => {
    if (time) {
      const day = date.split('/')[1];
      const month = date.split('/')[0];
      const year = date.split('/')[2];
      let hour = time?.split(':')[0];
      const minute = time?.split(':')[1].split(' ')[0];
      const ampm = time?.split(' ')[1];

      if (ampm === 'PM') {
        if (hour === '12') {
          hour = '12';
        } else {
          hour = (Number(hour) + 12).toString();
        }
      } else {
        if (hour === '12') {
          hour = '00';
        }
      }

      setScheduleParams({
        day,
        month,
        year,
        hour,
        minute,
        timezone: location?.timezone ? location?.timezone : timezone,
      });
    }
  }, [time, date, timezone]);

  // send quick campaign with no schedule
  const sendQuickCampaign = async (
    audience: Audience,
    message: string,
    attachment_urls: Array<string>
  ) => {
    try {
      createOneCampaign({
        title: `quick-campaign-${dayjs(new Date()).format('YYYY-MM-DD')}T${dayjs(
          new Date()
        ).format('H:mm:ss')}`,
        body: message,
        audience,
        schedule_options: null,
        attachment_urls,
        status: CampaignStatus.IN_PROGRESS,
        settings: null,
      });
      // clear attachments state on send
      setAttachments({
        attachment_urls: [],
      });
      setMessage('');
    } catch (err) {
      console.error(err);
    }
  };

  // send quick campaign with schedule
  const sendScheduledQuickCampaign = async (
    audience: Audience,
    message: string,
    attachment_urls?: Array<string>,
    scheduleParams?: ScheduleOptions
  ) => {
    try {
      await createOneCampaign({
        title: `quick-campaign-${dayjs(new Date()).format('YYYY-MM-DD')}T${dayjs(
          new Date()
        ).format('H:mm:ss')}`,
        body: message,
        audience: audience || null,
        schedule_options: scheduleParams || null,
        attachment_urls: attachment_urls || [],
        status: CampaignStatus.SCHEDULED,
        settings: null,
      });
      // clear attachments state on send
      setAttachments({
        attachment_urls: [],
      });
      setMessage('');
    } catch (err) {
      toast.error(i18next.t('quick_campaign_scheduled_error') as string);
      console.error(err);
    }
  };

  // send quick campaign message
  const handleSendMessage = () => {
    // get the campaign id for when the ensure tab is selected
    const campaign_id =
      selectedAudience?.wholeCampaignTab?.campaign_id ||
      getCampaignIdFromAudience(props.preSelectedAudience || {});

    // get the type from the tab
    const campaign_contacts_type =
      selectedAudience?.wholeCampaignTab?.campaign_contacts_type;

    const audience =
      allContactsChecked && props.isContactsPage && selectedAudience.contacts.length > 0
        ? {
            location: '',
            location_ids: location?.id ? [location?.id] : [],
            tag_ids: [],
            dynamic_group_ids: [],
            upload_tag_ids: [],
            contacts: [],
          }
        : {
            location: location?.id ?? '',
            tag_ids: [
              ...selectedAudience.tags.map((element) => element.id),
              ...(audienceFromProps?.tag_ids || []),
            ],
            dynamic_group_ids: [
              ...selectedAudience.groups.map((element) => element.id),
              ...(audienceFromProps?.dynamic_group_ids || []),
            ],
            upload_tag_ids: [
              ...selectedAudience.uploads.map((element) => element.id),
              ...(audienceFromProps?.upload_tag_ids || []),
            ],
            contacts: [
              ...selectedAudience.contacts,
              ...selectedAudience.manuallyAddedContacts.map((c) => c.id),
              ...(audienceFromProps?.contacts || []),
            ],
            campaign_delivered:
              campaign_contacts_type === 'delivered' ||
              props.preSelectedAudience?.campaign_delivered
                ? [campaign_id]
                : [],
            campaign_not_delivered:
              campaign_contacts_type === 'not_delivered' ||
              props.preSelectedAudience?.campaign_not_delivered
                ? [campaign_id]
                : [],
            campaign_responded:
              campaign_contacts_type === 'responded' ||
              props.preSelectedAudience?.campaign_responded
                ? [campaign_id]
                : [],
            campaign_not_responded:
              campaign_contacts_type === 'unresponded' ||
              props.preSelectedAudience?.campaign_not_responded
                ? [campaign_id]
                : [],
            campaign_link_clicked:
              campaign_contacts_type === 'clicks' ||
              props.preSelectedAudience?.campaign_link_clicked
                ? [campaign_id]
                : [],
            campaign_link_not_clicked:
              campaign_contacts_type === 'not_clicked' ||
              props.preSelectedAudience?.campaign_link_not_clicked
                ? [campaign_id]
                : [],
            campaign_unfulfilled:
              campaign_contacts_type === 'unfulfilled' ||
              props.preSelectedAudience?.campaign_unfulfilled
                ? [campaign_id]
                : [],
          };

    if (
      audience.tag_ids.length < 1 &&
      audience.dynamic_group_ids.length < 1 &&
      audience.upload_tag_ids.length < 1 &&
      audience.contacts.length < 1 &&
      !(allContactsChecked && props.isContactsPage) &&
      !campaign_contacts_type
    ) {
      toast.error(i18next.t('audience_not_selected') as string);
      return null;
    }

    if (allContactsChecked && props.isContactsPage) {
      setAllContactsChecked(false);
    }

    const formattedAudience = removeEmptyFields(audience as Audience);

    if (props.showSchedule) {
      sendScheduledQuickCampaign(
        formattedAudience,
        message,
        attachments?.attachment_urls,
        scheduleParams
      );
      if (location?.timezone !== scheduleParams?.timezone) {
        updateLocation({
          id: location?.id ?? '',
          timezone: scheduleParams?.timezone,
        });
      }
    } else {
      sendQuickCampaign(formattedAudience, message, attachments?.attachment_urls || []);
    }
    props.setCheckedContacts ? props.setCheckedContacts([]) : null;
    props.isCampaignsPage ? setAllSelected(false) : null;
    quickCampaignDialogReset();
    props.setCheckedContacts([]);
    handleResetState();
  };

  const [open, setOpen] = useState(false);

  const onRadioClick = (e: string) => {
    if (e === 'scheduled') {
      props.toggleSchedule();
    } else {
      props.toggleSchedule();
    }
  };

  const dateSelect = (e: {
    $d: string | number | Date | dayjs.Dayjs | null | undefined;
  }) => {
    const date = dayjs(e.$d).format('MM/DD/YYYY');
    setDate(date);
  };

  const handleChangeLocation = (locationId: string) => {
    const location = getLocation(locationId);
    if (location) {
      setLocation(location);
    }
  };

  // reset the schedule params
  // this is to set the set the default values for the schedule params
  // when creating a new quick campaign back to back
  const handleResetState = () => {
    props.toggleSchedule();
    setScheduleParams({
      day: '',
      month: '',
      year: '',
      hour: '',
      minute: '',
      timezone: '',
    });
  };

  const getLocationName = (id: string) =>
    locations.find((location) => location.id === id)?.name;

  const getLocationById = (id?: string) =>
    locations.find((location) => location.id === id);

  return (
    <Dialog open={props.quickCampaignDialogState} modal={false}>
      <DialogTrigger asChild onClick={quickCampaignDialogReset}>
        {children}
      </DialogTrigger>
      <DialogPortal>
        <DialogOverlay as="div">
          <DialogContent
            css={{ width: 700, pb: 0 }}
            onPointerDownOutside={() => !quickCampaignDialogReset}
            onEscapeKeyDown={quickCampaignDialogReset}
          >
            <DialogHeader title="Quick Campaign" />
            <VStack gap={1}>
              {locations.length > 0 && (
                <Fieldset>
                  <Label>From:</Label>
                  <SingleSelect
                    defaultPlaceholder={
                      getLocationName(location?.id || '') || 'Select a channel'
                    }
                    isDropdown={true}
                    selectItem={location?.id || ''}
                    setSelectItem={handleChangeLocation}
                    options={locations.map((location) => ({
                      type: location.name || location.address || '',
                      value: location?.id,
                    }))}
                  />
                </Fieldset>
              )}
              {!location?.timezone && props.showSchedule && (
                <Fieldset>
                  <Label>Select Timezone:</Label>
                  <TimezonePicker timezone={timezone} setTimezone={setTimezone} />
                </Fieldset>
              )}
              <Fieldset>
                <Label>Audience:</Label>
                <Box css={{ mb: 1 }}>
                  <AudienceSelection
                    locationId={location?.id || ''}
                    audienceFromCampaign={
                      props.audience as Partial<{
                        campaign_id: string;
                        campaign_contacts_type: string;
                      }>
                    }
                    selectedAudience={selectedAudience}
                    setSelectedAudience={setSelectedAudience}
                    isCampaignsPage={!!props.isCampaignsPage}
                    isContactsPage={!!props.isContactsPage}
                    isGroupsPage={!!props.isGroupsPage}
                    isUploadsPage={!!props.isUploadsPage}
                    isCampaignsEditPage={!!props.isCampaignsEditPage}
                    isInbox={!!props.isInbox}
                    setQuickCampaignDialogState={props.setQuickCampaignDialogState}
                    preSelectedAudience={audienceFromProps}
                    setPreSelectedAudience={setAudienceFromProps}
                    clearStatesOnUnmount={true}
                  />
                </Box>
              </Fieldset>
              <Fieldset>
                <Label>Schedule:</Label>
                <SetSchedule
                  show={props.showSchedule}
                  onRadioClick={onRadioClick}
                  open={open}
                  setOpen={setOpen}
                  date={date}
                  time={time}
                  setTime={setTime}
                  dateSelect={dateSelect}
                  scheduleParams={scheduleParams}
                  location={location}
                />
              </Fieldset>
              <Fieldset>
                <Label>Message:</Label>
                <MessageEditorV2
                  message={message}
                  setMessage={setMessage}
                  attachments={attachments}
                  setAttachments={setAttachments}
                  enableAttachments={true}
                  attachmentLoading={attachmentLoading}
                  setAttachmentLoading={setAttachmentLoading}
                  textareaRef={textareaRef}
                  sendMessageAction={handleSendMessage}
                  signature={signature}
                  setSignature={setSignature}
                  sendMessageFromTranslationDialog={false}
                  showAddAttachment={true}
                  showAddTemplate={true}
                  showAddVariable={true}
                  showAddEmoji={true}
                  showCharacterCount={true}
                  showAddTranslate={true}
                  showAddSignature={true}
                  showSendButton={true}
                  isForm={true}
                  sendButtonCopy={
                    props.isCampaignsEditPage
                      ? props.showSchedule
                        ? 'Save and Schedule'
                        : 'Save and Send'
                      : props.showSchedule
                        ? 'Schedule Campaign'
                        : 'Send'
                  }
                  channel_type={getLocationById(location?.id || '')?.type || 'phone'}
                />
              </Fieldset>
              <Box />
              <Label css={{ mb: 0 }}>
                {currentMessageCount > 0 ? `Total Contacts: ${currentMessageCount}` : ''}
              </Label>
            </VStack>
            <DialogClose>
              <DialogCloseIcon onClick={quickCampaignDialogReset} size="2">
                <Box css={{ minWidth: 16 }} onClick={quickCampaignDialogReset}>
                  <HiX
                    size="15px"
                    style={{
                      color: '#ffffff',
                    }}
                  />
                </Box>
              </DialogCloseIcon>
            </DialogClose>
          </DialogContent>
        </DialogOverlay>
      </DialogPortal>
    </Dialog>
  );
};
